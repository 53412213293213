import { GetBuyPriceAfterRewardsResponse, WalletAddress } from '@storyverseco/svs-types';
import { UnsignedTransactionRequest } from '@privy-io/react-auth';
import { consts } from 'config/consts';
import { tokenReplace } from 'lib/textFormats';
import { env } from 'config/env';

const formatTx = (txFromAPI: any): UnsignedTransactionRequest => {
  const tx = JSON.parse(JSON.stringify(txFromAPI));
  tx.gasLimit = tx.gas;
  tx.value = BigInt(tx.value);
  delete tx.gas;
  delete tx.from;
  delete tx.hash;
  delete tx.gasFeeCap;
  delete tx.gasTipCap;
  return tx;
};

enum MultibaasL1Endpoints {
  DepositETH = 'api/v0/chains/ethereum/addresses/optimism_portal/contracts/optimism_portal/methods/depositTransaction',
}

enum MultibaasL2Endpoints {
  CreateGame = 'api/v0/chains/ethereum/addresses/{{contractName}}/contracts/gemz/methods/createGame',
  GetBuyPriceAfterRewards = 'api/v0/chains/ethereum/addresses/{{contractName}}/contracts/gemz/methods/getBuyPriceAfterRewards',
  BuyGemz = 'api/v0/chains/ethereum/addresses/{{contractName}}/contracts/gemz/methods/buyGemz',
  SellGemz = 'api/v0/chains/ethereum/addresses/{{contractName}}/contracts/gemz/methods/sellGemz',
  GetSellPriceAfterRewards = 'api/v0/chains/ethereum/addresses/{{contractName}}/contracts/gemz/methods/getSellPriceAfterRewards',
}

interface MBRequestBody {
  args: any[];
  signer?: WalletAddress;
  [key: string]: any;
}

interface MBRequestOpts {
  lx: keyof typeof consts.multibaas;
  endpoint: MultibaasL1Endpoints | MultibaasL2Endpoints;
  body: MBRequestBody;
}

const requestMultibaas = async <T>({ lx, endpoint, body }: MBRequestOpts): Promise<T> => {
  const { baseUrl, token } = consts.multibaas[lx];
  const interpolatedEndpoint = tokenReplace({
    text: endpoint,
    tokenMap: { contractName: env.contractName },
  });
  const url = `${baseUrl}/${interpolatedEndpoint}`;

  // log.api.info('requestMultibaas', [{ lx, url, body }]);

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });

  const responseText = await response.text();

  if (!response.ok) {
    throw new Error(responseText);
  }

  const responseJson = JSON.parse(responseText);

  return responseJson;
};

const getTxFromMultibaas = async (opts: MBRequestOpts): Promise<UnsignedTransactionRequest> => {
  // log.api.info('getTxFromMultibaas 1', [{ opts }]);

  // @TODO: Type this
  const preparedMint: any = await requestMultibaas(opts);

  // log.api.info('getTxFromMultibaas 2', [{ preparedMint }]);

  const tx = preparedMint?.result?.tx;
  if (!tx) {
    throw new Error('No transaction returned');
  }

  // format tx to something web3 libs can consume
  const formattedTx = formatTx(tx);

  // log.api.info('getTxFromMultibaas 3', [{ formattedTx }]);

  return formattedTx;
};

const getResponseFromMultibaas = <T>(opts: MBRequestOpts): Promise<T> => {
  return requestMultibaas(opts);
};

interface DepositOpts {
  fromAddress: WalletAddress;
  toAddress: WalletAddress;
  amount: bigint | number;
}

const defaultGasFee = '100000';
const getDepositETHTransaction = ({ fromAddress, toAddress, amount }: DepositOpts) => {
  // log.api.info('getDepositETHTransaction');
  return getTxFromMultibaas({
    lx: 'l1',
    endpoint: MultibaasL1Endpoints.DepositETH,
    body: {
      args: [toAddress, amount.toString(), defaultGasFee, false, '0x'],
      from: fromAddress,
      signer: fromAddress,
      value: amount.toString(),
    },
  });
};

interface CreateGameOpts {
  message: any[];
  signature: string;
  fromAddress: WalletAddress;
}
const getCreateGameTransaction = ({ message, signature, fromAddress }: CreateGameOpts) => {
  // log.api.info('getCreateGameTransaction', [{ fromAddress }]);
  return getTxFromMultibaas({
    lx: 'l2',
    endpoint: MultibaasL2Endpoints.CreateGame,
    body: {
      args: [message, signature],
      signer: fromAddress,
      from: fromAddress,
    },
  });
};

interface GetPriceOpts {
  onchainGameId: number;
}
const DEFAULT_AMOUNT = 1;
const getBuyPrice = async ({ onchainGameId }: GetPriceOpts) => {
  // log.api.info('getBuyPrice', [{ onchainGameId }]);
  const response = await getResponseFromMultibaas<GetBuyPriceAfterRewardsResponse>({
    lx: 'l2',
    endpoint: MultibaasL2Endpoints.GetBuyPriceAfterRewards,
    body: {
      args: [onchainGameId, DEFAULT_AMOUNT],
    },
  });
  return response.result.output; // Do we want to give it as number or keep string?
};

interface BuyGemOpts {
  // @TODO: Type should come from svs-types
  gemRequest: any[]; // tuple
  gemSignature: string;
  // @TODO: Type should come from svs-types
  gameRequest: any[]; // tuple
  gameSignature: string;
  fromAddress: WalletAddress;
  amount?: string;
}

const getBuyGemTransaction = ({ gemRequest, gemSignature, gameRequest, gameSignature, fromAddress, amount }: BuyGemOpts) => {
  // log.api.info('getBuyGemTransaction', [{ gemRequest, gemSignature, gameRequest, gameSignature, fromAddress, amount }], false);
  return getTxFromMultibaas({
    lx: 'l2',
    endpoint: MultibaasL2Endpoints.BuyGemz,
    body: {
      args: [gemRequest, gemSignature, gameRequest, gameSignature],
      signer: fromAddress,
      from: fromAddress,
      value: amount,
    },
  });
};

interface SellGemOpts {
  gameId: number;
  holderAddress: WalletAddress;
}
const getSellGemTransaction = ({ gameId, holderAddress }: SellGemOpts) => {
  // log.api.info('getSellGemTransaction', [{ gameId, holderAddress }]);
  return getTxFromMultibaas({
    lx: 'l2',
    endpoint: MultibaasL2Endpoints.SellGemz,
    body: {
      args: [gameId, DEFAULT_AMOUNT],
      signer: holderAddress,
      from: holderAddress,
    },
  });
};

const getSellPrice = async ({ onchainGameId }: GetPriceOpts) => {
  // log.api.info('multibaas:getSellPrice', [{ onchainGameId }]);
  const response = await getResponseFromMultibaas<GetBuyPriceAfterRewardsResponse>({
    lx: 'l2',
    endpoint: MultibaasL2Endpoints.GetSellPriceAfterRewards,
    body: {
      args: [onchainGameId, DEFAULT_AMOUNT],
    },
  });
  // log.api.info('multibaas:getSellPrice', [{ response }]);
  return response.result.output; // Do we want to give it as number or keep string?
};

export const multibaas = {
  getDepositETHTransaction,
  getCreateGameTransaction,
  getBuyPrice,
  getBuyGemTransaction,
  getSellGemTransaction,
  getSellPrice,
};
