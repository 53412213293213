import { ChatMessageData, ChatRoomData, ChatRoomStatsData, ChatRoomUserData, InsertChatMessageData } from '@storyverseco/svs-types';
import { Endpoints } from './endpoints';
import { getEndpoint, pipelineApi } from './base';
import { waitForMs } from '../wait';
import { weeksToMs } from '../time';

export interface RoomProps {
  roomId?: number;
  creatorUsername?: string;
  creatorId?: number;
}

export interface RoomOptions {
  limit?: number;
  offset?: number;
  before?: string;
  after?: string;
}

export type SendMessageProps = Pick<InsertChatMessageData, 'type' | 'text' | 'inReplyTo'>;

const optionsToSearchParams = (options: RoomOptions | undefined): URLSearchParams => {
  const searchParams = new URLSearchParams();
  if (!options) {
    return searchParams;
  }
  for (const [key, value] of Object.entries(options)) {
    if (value !== undefined) {
      searchParams.append(key, value.toString());
    }
  }
  return searchParams;
};

const getEndpointWithOptions = (endpoint: Endpoints, params?: Record<string, string | number>, options?: RoomOptions): Endpoints => {
  if (params) {
    endpoint = getEndpoint(endpoint, params);
  }
  const searchParams = optionsToSearchParams(options);
  if (searchParams.size === 0) {
    return endpoint;
  }
  return `${endpoint}?${searchParams.toString()}` as Endpoints;
};

const getAllRooms = async (options?: RoomOptions): Promise<ChatRoomData[]> => {
  const endpoint = getEndpointWithOptions(Endpoints.CHAT_ROOMS, undefined, options);
  return pipelineApi.get(endpoint, true);
};

const getSingleRoom = async (props: RoomProps): Promise<ChatRoomData> => {
  let endpoint: Endpoints;
  if (props.roomId) {
    endpoint = getEndpoint(Endpoints.CHAT_ROOM_BY_ID, { roomId: props.roomId });
  } else if (props.creatorId) {
    endpoint = getEndpoint(Endpoints.CHAT_ROOM_BY_CREATOR_ID, { creatorId: props.creatorId });
  } else if (props.creatorUsername) {
    endpoint = getEndpoint(Endpoints.CHAT_ROOM_BY_CREATOR_USERNAME, { creatorUsername: props.creatorUsername });
  } else {
    throw new Error('getSingleRoom: Missing roomId, creatorId, or creatorUsername');
  }

  return pipelineApi.get(endpoint, true);
};

const getRoomStats = async (roomId: number): Promise<ChatRoomStatsData> => {
  return pipelineApi.get(getEndpoint(Endpoints.CHAT_ROOM_STATS, { roomId }), true);
};

const getRoomMessages = async (roomId: number, options?: RoomOptions): Promise<ChatMessageData[]> => {
  const endpoint = getEndpointWithOptions(Endpoints.CHAT_ROOM_MESSAGE, { roomId }, options);
  return pipelineApi.get(endpoint, true);
};

const getAllRoomMessages = async (options?: RoomOptions): Promise<ChatMessageData[]> => {
  const endpoint = getEndpointWithOptions(Endpoints.CHAT_ALL_ROOM_MESSAGES, undefined, options);
  return pipelineApi.get(endpoint, true);
};

const addMessage = async (roomId: number, data: SendMessageProps): Promise<ChatMessageData> => {
  const endpoint = getEndpoint(Endpoints.CHAT_ROOM_MESSAGE, { roomId });
  return pipelineApi.post(endpoint, { data }, true);
};

const getMessage = async (messageId: number): Promise<ChatMessageData> => {
  const endpoint = getEndpoint(Endpoints.CHAT_MESSAGES, { messageId });
  return pipelineApi.get(endpoint, true);
};

export const chat = {
  get: {
    allRooms: getAllRooms,
    room: getSingleRoom,
    roomMessages: getRoomMessages,
    message: getMessage,
    stats: getRoomStats,
    allMessages: getAllRoomMessages,
  },
  post: {
    message: addMessage,
  },
};

// const mockGetAllRooms = async (options?: RoomOptions): Promise<ChatRoomData[]> => {
//   // mock chat api
//   await waitForMs(250);
//   const opts = {
//     limit: 50,
//     offset: 0,
//     ...options,
//   };
//   if (opts.offset >= mockData.rooms.length) {
//     return [];
//   }
//   return mockData.rooms.slice(opts.offset, Math.min(mockData.rooms.length, opts.offset + opts.limit));
// };

// const mockGetSingleRoom = async (props: RoomProps): Promise<ChatRoomData> => {
//   // mock chat api
//   await waitForMs(250);
//   if (!props.roomId && !props.creatorUsername) {
//     throw new Error('mockGetSingleRoom: Missing id or username');
//   }
//   return mockData.rooms.find((r) => r.id === props.roomId || r.creator.username.toLowerCase() === props.creatorUsername?.toLowerCase());
// };

// const mockGetRoomStats = async (roomId: number): Promise<ChatRoomStatsData> => {
//   // mock chat api
//   await waitForMs(250);
//   return {
//     gameItemHoldingCount: 2,
//     gameItemHoldingValue: '18750000000000000',
//     gameItemTotalCount: 20,
//     memberCount: 12,
//   };
// };

// const mockGetRoomMessages = async (roomId: number, options?: RoomOptions): Promise<ChatMessageData[]> => {
//   // mock chat api
//   await waitForMs(250);
//   const room = await mockGetSingleRoom({ roomId });
//   if (!room) {
//     throw new Error('mockGetRoomMessages: Room not found');
//   }
//   const opts = {
//     limit: 50,
//     offset: 0,
//     ...options,
//   };
//   const filters: ((m: ChatMessageData) => boolean)[] = [(m) => m.roomId === room.id];
//   if (opts.before) {
//     const beforeTime = new Date(opts.before).getTime();
//     filters.push((m) => new Date(m.createdAt).getTime() < beforeTime);
//   }
//   if (opts.after) {
//     const afterTime = new Date(opts.after).getTime();
//     filters.push((m) => new Date(m.createdAt).getTime() > afterTime);
//   }
//   let messages = mockData.messages.filter((m) => filters.every((filter) => filter(m)));

//   if (opts.offset >= messages.length) {
//     return [];
//   }

//   // "before only" will base the results' paging with the dates descending order, but still return in ascending order
//   const beforeOnly = Boolean(opts.before && !opts.after);
//   if (beforeOnly) {
//     messages.reverse();
//   }

//   messages = messages.slice(opts.offset, Math.min(messages.length, opts.offset + opts.limit));

//   if (beforeOnly) {
//     messages.reverse();
//   }
//   return messages;
// };

// const mockAddMessage = async (roomId: number, data: SendMessageProps): Promise<ChatMessageData> => {
//   // mock chat api
//   await waitForMs(250);
//   const room = await mockGetSingleRoom({ roomId });
//   if (!room) {
//     throw new Error('getRoomMessages: Room not found');
//   }
//   const user = mockData.users.find((u) => u.id === 1075);

//   const message: ChatMessageData = {
//     id: mockMsgIdPool++,
//     type: 'text',
//     createdAt: new Date().toISOString(),
//     updatedAt: new Date().toISOString(),
//     roomId: room.id,
//     senderId: user.id,
//     sender: {
//       id: user.id,
//       username: user.username,
//       walletAddress: user.walletAddress,
//       twitter: user.twitter,
//     },
//     text: data.text.substring(0, 140),
//   };

//   mockData.messages.push(message);
//   return message;
// };

// const mockGetMessage = async (messageId: number): Promise<ChatMessageData> => {
//   // mock chat api
//   await waitForMs(250);
//   return mockData.messages.find((m) => m.id === messageId);
// };

// const mockData: {
//   rooms: ChatRoomData[];
//   messages: ChatMessageData[];
//   users: ChatRoomUserData[];
// } = {
//   rooms: [],
//   messages: [],
//   users: [
//     {
//       id: 1075,
//       username: 'jaytherpc',
//       walletAddress: '0x0',
//       twitter: {
//         displayName: 'JB P!',
//         handle: 'jaytherpc',
//         picture: 'https://pbs.twimg.com/profile_images/1487059264124325891/seGYqvnR_normal.jpg',
//       },
//     },
//     {
//       id: 1076,
//       username: 'DamonP67603y',
//       walletAddress: '0x0',
//       twitter: {
//         handle: 'DamonP67603y',
//         picture: 'https://pbs.twimg.com/profile_images/1729976019866820608/2Vxv_Jsu_normal.jpg',
//         displayName: 'Damon Pidhajecky',
//       },
//     },
//     {
//       id: 1085,
//       username: 'EdgeCaser',
//       walletAddress: '0x0',
//       twitter: {
//         handle: 'EdgeCaser',
//         picture: 'https://pbs.twimg.com/profile_images/1493722410721312769/h2QlO9Dv_normal.jpg',
//         displayName: 'Edgecaser.eth',
//       },
//     },
//     {
//       id: 1080,
//       username: 'TBJ21159',
//       walletAddress: '0x0',
//       twitter: {
//         handle: 'TBJ21159',
//         picture: 'https://pbs.twimg.com/profile_images/1719794066194567169/M_A2_jab_normal.png',
//         displayName: 'Tammy Chang',
//       },
//     },
//     {
//       id: 1077,
//       username: 'ssantosh84',
//       walletAddress: '0x0',
//       twitter: {
//         handle: 'ssantosh84',
//         picture: 'https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png',
//         displayName: 'santosh shirahatti',
//       },
//     },
//   ],
// };

// let mockRoomIdPool = 1;
// let mockMsgIdPool = 1;

// const makeRoom = (id: number) => {
//   const user = mockData.users.find((u) => u.id === id);
//   const room: ChatRoomData = {
//     id: mockRoomIdPool++,
//     type: 'gem_creator',
//     creatorId: user.id,
//     createdAt: new Date().toISOString(),
//     creator: user,
//     updatedAt: new Date().toISOString(),
//   };
//   mockData.rooms.push(room);
// };

// const makeRandMsg = (roomId?: number, time?: number, notCreator = false) => {
//   time ??= Date.now() - Math.floor(Math.random() * weeksToMs(3));
//   roomId ??= mockData.rooms[Math.floor(Math.random() * mockData.rooms.length)].id;

//   let userPool: ChatRoomUserData[];
//   if (notCreator) {
//     const room = mockData.rooms.find((r) => r.id === roomId);
//     userPool = mockData.users.filter((u) => room.creator.id !== u.id);
//   } else {
//     userPool = mockData.users;
//   }
//   const sender = userPool[Math.floor(Math.random() * userPool.length)];
//   const date = new Date(time);
//   const dateStr = date.toISOString();

//   mockData.messages.push({
//     id: mockMsgIdPool++,
//     type: 'text',
//     createdAt: dateStr,
//     updatedAt: dateStr,
//     roomId,
//     senderId: sender.id,
//     sender,
//     text: createRandSentence(),
//   });
// };

// const orderMsgs = () => {
//   mockData.messages.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
// };

// const wordPool = `
// Our business deep-dives siloes to ethically and conservatively calibrate our senior core competency. Next-generation digital nomads are becoming long-term proposition experts. In the capability space, industry is globally incentivizing its cloud native platforms. We use our world-class action points to iteratively manage our user experience expectations.
// In the cloud space, industry is strategically reusing its unparalleled executive searches. Going forward, our value-added ballpark figure will deliver value to alignments. Going forward, our corporate driver will deliver value to visibilities. It's critical that we give 110% when effectively monetizing diversities.
// Our team player development lifecycle enables best-of-breed, mission critical creators. We aim to proactively invest our imagineering by intelligently offshoring our end-to-end self-driving blockchains. Our Seamless Smart Contract solution offers best practices a suite of best-in-class offerings. Is your market focus prepared for actionable innovation growth?
// `
//   .replace(/\r?\n/g, ' ')
//   .split(' ');

// const createRandSentence = () => {
//   const randNumWords = 1 + Math.floor(Math.random() * 20);
//   const randPos = Math.floor(Math.random() * (wordPool.length - randNumWords));
//   return wordPool.slice(randPos, randPos + randNumWords).join(' ');
// };

// makeRoom(1075);
// makeRoom(1076);
// makeRoom(1085);

// for (let i = 0; i < 120; i++) {
//   makeRandMsg();
// }

// orderMsgs();

// setInterval(() => {
//   makeRandMsg(1, Date.now(), true);
// }, 10000);

// export const chat = {
//   get: {
//     allRooms: mockGetAllRooms,
//     room: mockGetSingleRoom,
//     allMessages: mockGetRoomMessages,
//     message: mockGetMessage,
//     stats: mockGetRoomStats,
//   },
//   post: {
//     message: mockAddMessage,
//   },
// };
