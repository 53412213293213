import { ExtraInfo, GameFeedItem } from '@storyverseco/svs-types';
import { AppController } from 'lib/Controller';
import { api } from 'lib/apis';
import { ComponentController } from 'lib/controllers/ComponentController';
import { debounce } from 'lib/Debounce';

import type { Carousel } from 'react-responsive-carousel';
import { FEGameEvent, Game } from 'lib/Game';

export enum ControllerState {
  Idle = 'ControllerState/Idle',
  Initialising = 'Controller/Initialising',
  Ready = 'Controller/Ready',
}

export enum GameFeedEvent {
  CurrentItemChange = 'GameFeedEvent/CurrentItemChange',
  GameLoad = 'GameFeedEvent/GameLoad',
  GameChange = 'GameFeedEvent/GameChange',
}

class CarouselController {
  private _scrollEnabled = true;

  private _scrollDirection = 0;

  public index = 0;

  private _component?: Carousel;

  private readonly scrollDeltaThreshold = 5;

  public feedSize = 0;

  scrollDirection = this._scrollDirection;

  constructor(private onUpdate: () => void, private onScroll: (direction: -1 | 1) => void) {}
  /**
   *
   * @param e
   * @returns boolean - true if we are srolling
   */
  public doScroll = (e: WheelEvent) => {
    // console.log('CarouselController:doScroll', { enabled: this._scrollEnabled });
    if (!this._scrollEnabled) {
      return false;
    }
    if (!this._component) {
      return false;
    }

    const { deltaY, webkitDirectionInvertedFromDevice } = e as any;

    const shouldScroll = Math.abs(deltaY) > this.scrollDeltaThreshold;

    // console.log('CarouselController:doScroll', { deltaY, webkitDirectionInvertedFromDevice, shouldScroll });

    if (!shouldScroll) {
      return false;
    }

    // // Remove the free game from feed before doing any navigation
    // if (currentItem?.freeGame || focusGame?.temp) {
    //   await dispatch(clearTempFeedItem());
    // }

    const y = webkitDirectionInvertedFromDevice ? -deltaY : deltaY;

    const scrollNext = y < -this.scrollDeltaThreshold;
    const scrollBack = y > this.scrollDeltaThreshold;
    const hasNext = this.index < this.feedSize - 1;
    const hasPrev = this.index > 0;

    this._scrollDirection = scrollNext ? 1 : -1;

    // console.log('CarouselController:doScroll', { y, scrollNext, scrollBack, hasNext, hasPrev });

    if (scrollNext && hasNext) {
      if (hasNext) {
        this.onScroll(1);
        this.next();
      } else {
        // updateFeed();
      }
      return true;
    }

    if (scrollBack && hasPrev) {
      // we want the slides to be infinite and seamless
      this.onScroll(-1);
      this.previous();
      return true;
    }

    return false;
  };

  public setComponentController = (carousel: Carousel) => {
    this._component = carousel;
  };

  public setIndexFromComponent = (index: number) => {
    this.index = index;
    this.onUpdate();
  };

  public disableScroll = () => {
    this._scrollEnabled = false;
  };

  public enableScroll = () => {
    this._scrollEnabled = true;
  };

  public goToNext = () => {
    this.next();
  };

  private next = debounce(
    () => {
      if (!this._component || this.index > this.feedSize - 1) {
        return;
      }
      this._component.increment();
    },
    700,
    true,
  );

  private previous = debounce(
    () => {
      if (!this._component || this.index <= 0) {
        return;
      }
      this._component.decrement();
    },
    700,
    true,
  );

  public attachScrollEvent = () => {
    window.addEventListener('wheel', this.doScroll, {
      capture: false,
      passive: true,
    });

    return () => {
      window.removeEventListener('wheel', this.doScroll, {
        capture: false,
      });
    };
  };
}

export class GameFeedController extends ComponentController {
  events = {
    onChange: [],
  };
  // @TODO: Use feature cache here
  private _items: Record<string, Game> = {};

  // mem-cache
  private _tempItems: Record<string, Game> = {};

  private _nextPage?: string;

  private _carousel: CarouselController;

  private isLoadingNextPage = false;

  private itemList: Game[] = [];

  private lastFeedUserId;

  constructor(private app: AppController) {
    super();
    this._carousel = new CarouselController(this.onCurrentItemChange, this.onScroll);
  }

  get items() {
    return this.itemList;
  }

  get currentItem() {
    return this.items[this._carousel.index];
  }

  get carousel() {
    // omit index, should not be public outside feed controller
    const { index, ...rest } = this._carousel;

    return rest;
  }

  // @TODO: investigate: Something is calling this with 'undefined' gameId (I think it's from AI)
  getGameById = (gameId: number) => {
    // Should not happen, trying to prevent crashes?
    if (!gameId) {
      return undefined;
    }
    return this._items[gameId.toString()] || this._tempItems[gameId.toString()];
  };

  loadTempItem = async (gameId: number | string, navigateAfterLoad = true) => {
    const itemFeedIndex = this.items.findIndex((i) => i.id === Number(gameId));

    if (itemFeedIndex >= 0) {
      this._carousel.setIndexFromComponent(itemFeedIndex);
      return;
    }

    let tempGame = this._tempItems[gameId.toString()];

    // if we dont have cache
    if (!tempGame) {
      const newTempGame = await api.game.get.pubGame(gameId.toString());
      if (!newTempGame) {
        return;
      }
      await this.app.assets.cacheImages([newTempGame.multimediaUrls?.mobilePortraitThumbnail]);
      // start fetching async
      const game = new Game(this.app, newTempGame);
      game.addEventListener(FEGameEvent.Change, () => {
        this.sendEvents([
          { name: GameFeedEvent.GameChange, params: { game } },
          { name: `${GameFeedEvent.GameChange}/${game.id}`, params: { game } },
        ]);
      });
      this.sendEvents([
        { name: GameFeedEvent.GameLoad, params: { game } },
        { name: `${GameFeedEvent.GameLoad}/${game.id}`, params: { game } },
      ]);
      this._tempItems[gameId.toString()] = game;
      tempGame = game;

      if (tempGame.gem.type === 'craftable') {
        // recipe data etc
        await this.app.craft.loadMissionData(+gameId);
      }
    }
    if (navigateAfterLoad) {
      this.itemList = [tempGame, ...this.itemList];
      this._carousel.setIndexFromComponent(0);
      // Update the url
      const paths = location.pathname.split('/');
      if (paths[1] === 'feed') {
        const newUrl = `${location.origin}/feed/${tempGame.id}`;
        history.pushState({}, '', newUrl);
      }
    }
  };

  loadAndGetItemById = async (gameId: number) => {
    let item = this.getGameById(gameId);
    if (!item) {
      await this.loadTempItem(gameId, false);
      item = this.getGameById(gameId);
    }
    return item;
  };

  attachGameIdListener = (eventName: string, offChainGameId: number | string) => this.attachEventListener(`${eventName}/${offChainGameId}`);

  private onCurrentItemChange = () => {
    this.app.viewer.playStory(this.currentItem.storyUrl);
    if (this.items.length - this._carousel.index <= 10) {
      this.loadNextPage();
    }
    this.sendEvents([GameFeedEvent.CurrentItemChange]);
  };

  private onScroll = (_direction: -1 | 1) => {
    // note:
    // carousel is hidden from GameFeed.tsx/onCarouselChange
    // no need to hide it from here
  };

  private updateFeedItems = (items: GameFeedItem[]) => {
    const newList = items.map((item) => new Game(this.app, item));
    this._items = {
      ...this._items,
      ...newList.reduce(
        (res, cur) => ({
          ...res,
          [cur.id]: cur,
        }),
        {},
      ),
    };
    this.itemList = this.itemList.concat(newList);
    this._carousel.feedSize = this.itemList.length;

    // game specific events
    for (const game of newList) {
      game.addEventListener(FEGameEvent.Change, () => {
        this.sendEvents([
          { name: GameFeedEvent.GameChange, params: { game } },
          { name: `${GameFeedEvent.GameChange}/${game.id}`, params: { game } },
        ]);
      });
      this.sendEvents([
        { name: GameFeedEvent.GameLoad, params: { game } },
        { name: `${GameFeedEvent.GameLoad}/${game.id}`, params: { game } },
      ]);
    }
    this.updateComponent();

    // (async) Fetch the images for the feed as soon as we set the data
    this.app.assets
      .cacheImages(items.map((item) => item.multimediaUrls.mobilePortraitThumbnail))
      // Once we are done, then fetch the story datas
      .then(async () => {
        console.log('FeedController:setFeedItems', 'all feed images cached!');
      });

    this.itemList.forEach((game) => {
      // purposely not awaiting
      this.app.follow.loadSingleFollow(game.twitter.handle).catch((e) => {
        console.error('GameFeedController:loadSingleFollow', e);
      });
    });
  };
  // Separate method because we only await on this one
  loadFirstPage = async (firstGameId?: number) => {
    const loadThePage = async () => {
      const userId = this.app.user.me?.id || this.app.fingerprint.id;
      this.lastFeedUserId = userId;
      const { items, paginationId } = await api.feed.get(userId);
      // Only fetch the first game image if we are not starting the game with a first game id
      if (firstGameId === undefined) {
        const [firstGame] = items;
        // Make sure we are ready to display the first item before we move on
        await this.app.assets.cacheImages([firstGame.multimediaUrls.mobilePortraitThumbnail]);
      }
      this._nextPage = paginationId;
      // Make sure we don't duplicate firstGame
      const feedItems = firstGameId ? items.filter((i) => i.id !== firstGameId) : items;
      this.updateFeedItems(feedItems);
    };

    if (firstGameId) {
      const firstGame = await api.game.get.pubGame(firstGameId.toString());
      await this.app.assets.cacheImages([firstGame.multimediaUrls.mobilePortraitThumbnail]);
      this.updateFeedItems([firstGame]);
      // load the rest of the page async
      loadThePage();
    } else {
      await loadThePage();
    }
  };

  loadNextPage = async () => {
    if (!this._nextPage) {
      this.error(`Trying to load next feed page without 'recombeeId'.`);
      return;
    }
    const userId = this.app.user.me?.id || this.app.fingerprint.id;
    if (this.lastFeedUserId !== userId) {
      // if we fetched with fingerprint id and now we are fetching with userId we need to merge the user
      await api.user.mergeUserWithFingerprint(this.lastFeedUserId, userId);
    }
    this.lastFeedUserId = userId;
    const { items, paginationId } = await api.feed.get(userId, this._nextPage);
    this._nextPage = paginationId;
    this.updateFeedItems(items);
  };

  // happens after buy/sell
  updateCraftableGamesIngredientList = async () => {
    this.items.forEach((game) => {
      if (game.gem.type === 'craftable') {
        game.refresh();
      }
    });
  };

  public get recombeeId() {
    return this._nextPage;
  }
}
