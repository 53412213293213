import { Optional } from '@storyverseco/svs-types';
import { mediaServiceApi } from '../apis/base';
import { MediaServiceEndpoints } from '../apis/endpoints';
import { FeatureCache } from '../cache/FeatureCache';

export type ShareURLCache = Record<string, string>;

export class ShareController {
  shareUrls = new FeatureCache('shareUrlCache', () => ({}));

  /**
   * Create a gemz shortened URL.
   *
   * Note: Only specific domains allowed.
   * @param urlOrPath
   * @returns
   */
  private createShortenedUrl = async (urlOrPath: string) => {
    const body = {
      path: urlOrPath,
    };
    try {
      const response = await mediaServiceApi.post(MediaServiceEndpoints.GEMZ_SHORTENER, body);
      const { result } = await response.json();
      return result;
    } catch (e) {
      console.error('Shortener error:', e);
      return undefined;
    }
  };

  /**
   * Construct a URL from path and query params, using current origin.
   * @param param0
   * @returns
   */
  private baseCreateShareUrl = ({ path, queryParams = {} }: { path: string; queryParams?: Record<string, any> }) => {
    const url = new URL(window.location.origin);
    url.pathname = path;
    for (const key of Object.keys(queryParams)) {
      if (typeof queryParams[key] !== 'undefined') {
        url.searchParams.append(key, queryParams[key]);
      }
    }
    return url.toString();
  };

  /**
   * Create a shortened URL from path and query params, using current origin. If exact URL has already been shortened
   * before, it will return a cached shortened URL.
   * @param param0
   * @returns
   */
  private createShareUrl = async ({ path, queryParams }: { path: string; queryParams?: Record<string, any> }) => {
    const urlStr = this.baseCreateShareUrl({ path, queryParams });
    const urlStrKey = urlStr.toLowerCase();
    const cachedShortened = this.shareUrls.get(urlStrKey);
    if (cachedShortened) {
      return cachedShortened;
    }

    const shortened = await this.createShortenedUrl(urlStr);
    this.shareUrls.set(urlStrKey, shortened);
    return shortened;
  };

  /**
   * Create a shortened game URL from username, gameId, and query params. If this URL has been shortened before,
   * a cached shortened URL will be returned.
   * @param param0
   * @returns
   */
  private createShareGameUrl = async ({ username, gameId, queryParams }: { username: string; gameId: string | number; queryParams?: Record<string, any> }) => {
    return await this.createShareUrl({ path: `/@${username}/game/${gameId}`, queryParams });
  };

  /**
   * Get an existing cached shortened URL. If it does not exist, `undefined` is returned.
   * @param param0
   * @returns
   */
  private getCachedShortenedUrl = ({ path, queryParams }: { path: string; queryParams?: Record<string, any> }) => {
    const urlStr = this.baseCreateShareUrl({ path, queryParams });
    const urlStrKey = urlStr.toLowerCase();
    return this.shareUrls.get(urlStrKey);
  };

  /**
   * Get an existing cached shortened URL from username, gameId, and query params. If it does not exist, `undefined`
   * is returned.
   * @param param0
   * @returns
   */
  private getCachedShareGameUrl = ({ username, gameId, queryParams }: { username: string; gameId: string | number; queryParams?: Record<string, any> }) => {
    return this.getCachedShortenedUrl({ path: `/@${username}/game/${gameId}`, queryParams });
  };

  public getShareGameUrl = (username?: string, gameId?: string | number, enabled = true, sharePayload?: string): Optional<string> => {
    if (!username || gameId === undefined || !enabled) {
      return undefined;
    }
    const props = { username, gameId, queryParams: { sharePayload } };
    const cache = this.getCachedShareGameUrl(props);
    if (!cache) {
      this.createShareGameUrl(props);
    }
    return cache as Optional<string>;
  };

  /**
   * Create a shortened profile URL from username and query params. If this URL has been shortened before,
   * a cached shortened URL will be returned.
   * @param param0
   * @returns
   */
  private createShareProfileUrl = async ({ username, queryParams }: { username: string; queryParams?: Record<string, any> }) => {
    return await this.createShareUrl({ path: `/@${username}`, queryParams });
  };

  /**
   * Get an existing cached shortened URL from username and query params. If it does not exist, `undefined`
   * is returned.
   * @param param0
   * @returns
   */
  private getCachedShareProfileUrl = ({ username, queryParams }: { username: string; queryParams?: Record<string, any> }) => {
    return this.getCachedShortenedUrl({ path: `/@${username}`, queryParams });
  };

  /**
   * Share game with device's Open Share API Tray with clipboard backup.
   *
   * If returned true, share tray was used.
   * If returned false, text and URL is put into clipboard.
   * @param username
   * @param gameId
   * @param url
   * @returns
   */
  public openShareOwnGame = async ({ username, gameId, url }: { username: string; gameId: string | number; displayName?: string; url?: string }) => {
    const pubGameId = gameId;

    url ??= `${window.location.origin}/@${username}/game/${pubGameId}`;

    const shareData = {
      title: 'Gemz',
      text: 'Look at my game!',
      url,
    };

    return await this.openShare(shareData);
  };

  /**
   * Share game with device's Open Share API Tray with clipboard backup.
   *
   * If returned true, share tray was used.
   * If returned false, text and URL is put into clipboard.
   * @param username
   * @param gameId
   * @param url
   * @returns
   */
  public openShareGame = async ({ username, gameId, displayName, url }: { username: string; gameId: string | number; displayName: string; url?: string }) => {
    const pubGameId = gameId;

    url ??= `${window.location.origin}/@${username}/game/${pubGameId}`;

    const shareData = {
      title: 'Gemz',
      text: `Look at ${displayName}'s game!`,
      url,
    };

    return await this.openShare(shareData);
  };

  public openShareHelpOffer = async ({
    username,
    gameId,
    displayName,
    gemName,
    url,
  }: {
    username: string;
    gameId: string | number;
    displayName: string;
    gemName: string;
    url?: string;
  }) => {
    const pubGameId = gameId;

    url ??= `${window.location.origin}/@${username}/game/${pubGameId}`;

    const shareData = {
      title: 'Gemz',
      text: `${gemName} unlocked, look at ${displayName}'s game!`,
      url,
    };

    return await this.openShare(shareData);
  };

  public openShareOwnHelpOffer = async ({ username, gameId, gemName, url }: { username: string; gameId: string | number; gemName: string; url?: string }) => {
    const pubGameId = gameId;

    url ??= `${window.location.origin}/@${username}/game/${pubGameId}`;

    const shareData = {
      title: 'Gemz',
      text: `${gemName} unlocked, look at my game!`,
      url,
    };

    return await this.openShare(shareData);
  };

  public openShareHelpWanted = async (gemName: string, helpWantedUrl: string) => {
    const shareData = {
      title: 'Gemz',
      text: `Help me unlock the ${gemName} game!`,
      url: helpWantedUrl,
    };

    return await this.openShare(shareData);
  };

  /**
   * Share profile with device's Open Share API tray with clipboard backup.
   *
   * If returned true, share tray was used.
   * If returned false, text and URL is put into clipboard.
   * @param username
   * @param url
   * @returns
   */
  private openShareOwnProfile = async ({ username, url }: { username: string; url?: string }) => {
    url ??= `${window.location.origin}/@${username}`;

    const shareData = {
      title: 'Gemz',
      text: 'Come and play my games on Gemz!',
      url,
    };

    return await this.openShare(shareData);
  };

  /**
   * Share profile with device's Open Share API tray with clipboard backup.
   *
   * If returned true, share tray was used.
   * If returned false, text and URL is put into clipboard.
   * @param username
   * @param url
   * @returns
   */
  private openShareProfile = async ({ username, displayName, url }: { username: string; displayName: string; url?: string }) => {
    url ??= `${window.location.origin}/@${username}`;

    const shareData = {
      title: 'Gemz',
      text: `Come and play ${displayName}'s games on Gemz!`,
      url,
    };

    return await this.openShare(shareData);
  };

  /**
   * Share via open share API tray, with clipboard copy fallback.
   *
   * If true, open share API tray was used.
   * If false, the text and URL is put into clipboard.
   * @param shareData
   * @returns
   */
  private openShare = async (shareData: ShareData) => {
    console.log('>>> website - sharing through openShare api with shareData:', shareData);

    try {
      await navigator.share(shareData);
      console.log('Gemz shared successfully');
      return true;
    } catch (err) {
      console.warn(`Gemz share Error: ${err}`);
    }

    // clipboard as backup
    try {
      await navigator.clipboard.writeText(`${shareData.text}\n${shareData.url}`);
      console.log(`Gemz clipboard shared successfully`);
    } catch (err) {
      console.warn(`Gemz clipboard share Error: ${err}`);
    }
    return false;
  };
}
