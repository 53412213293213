import { pipelineApi } from './base';
import { Endpoints } from './endpoints';
import { UserOperationStruct } from '@alchemy/aa-core';

export const serializeBigIntObj = (obj: Record<string, BigInt | any>) => {
  return Object.keys(obj).reduce(
    (res, cur) => ({
      ...res,
      [cur]: typeof obj[cur] === 'bigint' ? obj[cur].toString() : obj[cur],
    }),
    {},
  );
};

const populateWithPaymaster = async (userOp: UserOperationStruct): Promise<UserOperationStruct> => {
  const serializedUserOp = serializeBigIntObj(userOp);

  return pipelineApi.post(Endpoints.PAYMASTER_USER_OP_GET, { data: serializedUserOp });
};

const getAlchemyJWT = async () => {
  return pipelineApi.get(Endpoints.ALCHEMY_JTW_GET);
};

export const paymaster = {
  populateWithPaymaster,
  getAlchemyJWT,
};
