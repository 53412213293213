import { ApplyPlayRewardCoinRequest, CoinGemProps, Conditions, GetCoinGemPriceProps, WinConditionType } from '@storyverseco/svs-types';
import { pipelineApi } from './base';
import { Endpoints } from './endpoints';

const getUserBalance = async (): Promise<number> => {
  return (await pipelineApi.get(Endpoints.COIN_BALANCE_GET)) || 0;
};

interface CoinPrice {
  buyPrice: number;
  sellPrice: number;
}

const getBuyPrice = async (offChainGameId: number): Promise<CoinPrice> => {
  const data: GetCoinGemPriceProps = {
    offChainGameId,
    amount: 1,
  };
  return await pipelineApi.post(Endpoints.COIN_PRICE_BUY_GET, { data });
};

const getSellPrice = async (offChainGameId: number): Promise<CoinPrice> => {
  const data: GetCoinGemPriceProps = {
    offChainGameId,
    amount: 1,
  };
  return await pipelineApi.post(Endpoints.COIN_PRICE_SELL_GET, { data });
};

interface PriceDrift {
  message: string;
  newBuyPrice: number;
  newSellPrice: number;
}

interface BuyResponse {
  price?: string;
  gemsBought?: number;
  priceDrift?: PriceDrift;
}
const buy = async (offChainGameId: number, offeredPrice: number, hoid?: number): Promise<BuyResponse> => {
  const data: CoinGemProps = { offChainGameId, hoid, price: offeredPrice };
  return await pipelineApi.post(Endpoints.COIN_BUY, { data });
};

interface SellResponse {
  priceDrift?: PriceDrift;
}
const sell = async (offChainGameId: number, offeredPrice: number): Promise<SellResponse> => {
  const data: CoinGemProps = { offChainGameId, price: offeredPrice };
  return await pipelineApi.post(Endpoints.COIN_SELL, { data });
};

const buyCraftable = async (offChainGameId: number, gameData: any): Promise<void> => {
  const data = { offChainGameId, gameData };
  await pipelineApi.post(Endpoints.CRAFTABLE_BUY, { data });
};

const sellCraftable = async (offChainGameId: number, offeredPrice: number): Promise<SellResponse> => {
  const data: CoinGemProps = { offChainGameId, price: offeredPrice };
  return await pipelineApi.post(Endpoints.CRAFTABLE_SELL, { data });
};

const rewards = async (data: ApplyPlayRewardCoinRequest): Promise<number> => {
  return await pipelineApi.post(Endpoints.COIN_REWARDS, { data });
};

export const coins = {
  getUserBalance,
  // getBuyPrice,
  // getSellPrice,
  buy,
  sell,
  buyCraftable,
  sellCraftable,
  rewards,
};
