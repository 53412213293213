import { mediaServiceApi } from './base';
import { MediaServiceEndpoints } from './endpoints';

/**
 * Create a gemz shortened URL.
 *
 * Note: Only specific domains allowed.
 * @param urlOrPath
 * @returns
 */
export const createShortenedUrl = async (urlOrPath: string) => {
  const body = {
    path: urlOrPath,
  };
  try {
    const response = await mediaServiceApi.post(MediaServiceEndpoints.GEMZ_SHORTENER, body);
    const { result } = await response.json();
    return result;
  } catch (e) {
    console.error('Shortener error:', e);
    return undefined;
  }
};

/**
 * Construct a URL from path and query params, using current origin.
 * @param param0
 * @returns
 */
const baseCreateShareUrl = ({ path, queryParams = {} }: { path: string; queryParams?: Record<string, any> }) => {
  const url = new URL(window.location.origin);
  url.pathname = path;
  for (const key of Object.keys(queryParams)) {
    if (typeof queryParams[key] !== 'undefined') {
      url.searchParams.append(key, queryParams[key]);
    }
  }
  return url.toString();
};

const shortenedCache: Record<string, string> = {};

/**
 * Create a shortened URL from path and query params, using current origin. If exact URL has already been shortened
 * before, it will return a cached shortened URL.
 * @param param0
 * @returns
 */
const createShareUrl = async ({ path, queryParams }: { path: string; queryParams?: Record<string, any> }) => {
  const urlStr = baseCreateShareUrl({ path, queryParams });
  const urlStrKey = urlStr.toLowerCase();
  const cachedShortened = shortenedCache[urlStrKey];
  if (cachedShortened) {
    return cachedShortened;
  }

  const shortened = await createShortenedUrl(urlStr);
  shortenedCache[urlStrKey] = shortened;
  return shortened;
};

/**
 * Get an existing cached shortened URL. If it does not exist, `undefined` is returned.
 * @param param0
 * @returns
 */
const getCachedShortenedUrl = ({ path, queryParams }: { path: string; queryParams?: Record<string, any> }) => {
  const urlStr = baseCreateShareUrl({ path, queryParams });
  const urlStrKey = urlStr.toLowerCase();
  return shortenedCache[urlStrKey];
};

/**
 * Create a shortened game URL from username, gameId, and query params. If this URL has been shortened before,
 * a cached shortened URL will be returned.
 * @param param0
 * @returns
 */
export const createShareGameUrl = async ({
  username,
  gameId,
  queryParams,
}: {
  username: string;
  gameId: string | number;
  queryParams?: Record<string, any>;
}) => {
  return await createShareUrl({ path: `/@${username}/game/${gameId}`, queryParams });
};

/**
 * Get an existing cached shortened URL from username, gameId, and query params. If it does not exist, `undefined`
 * is returned.
 * @param param0
 * @returns
 */
export const getCachedShareGameUrl = ({ username, gameId, queryParams }: { username: string; gameId: string | number; queryParams?: Record<string, any> }) => {
  return getCachedShortenedUrl({ path: `/@${username}/game/${gameId}`, queryParams });
};

/**
 * Create a shortened profile URL from username and query params. If this URL has been shortened before,
 * a cached shortened URL will be returned.
 * @param param0
 * @returns
 */
export const createShareProfileUrl = async ({ username, queryParams }: { username: string; queryParams?: Record<string, any> }) => {
  return await createShareUrl({ path: `/@${username}`, queryParams });
};

/**
 * Get an existing cached shortened URL from username and query params. If it does not exist, `undefined`
 * is returned.
 * @param param0
 * @returns
 */
export const getCachedShareProfileUrl = ({ username, queryParams }: { username: string; queryParams?: Record<string, any> }) => {
  return getCachedShortenedUrl({ path: `/@${username}`, queryParams });
};

/**
 * Share game with device's Open Share API Tray with clipboard backup.
 *
 * If returned true, share tray was used.
 * If returned false, text and URL is put into clipboard.
 * @param username
 * @param gameId
 * @param url
 * @returns
 */
export const openShareOwnGame = async ({ username, gameId, url }: { username: string; gameId: string | number; displayName?: string; url?: string }) => {
  const pubGameId = gameId;

  url ??= `${window.location.origin}/@${username}/game/${pubGameId}`;

  const shareData = {
    title: 'Gemz',
    text: 'Look at my new game!',
    url,
  };

  return await openShare(shareData);
};

/**
 * Share game with device's Open Share API Tray with clipboard backup.
 *
 * If returned true, share tray was used.
 * If returned false, text and URL is put into clipboard.
 * @param username
 * @param gameId
 * @param url
 * @returns
 */
export const openShareGame = async ({
  username,
  gameId,
  displayName,
  url,
}: {
  username: string;
  gameId: string | number;
  displayName: string;
  url?: string;
}) => {
  const pubGameId = gameId;

  url ??= `${window.location.origin}/@${username}/game/${pubGameId}`;

  const shareData = {
    title: 'Gemz',
    text: `Look at ${displayName}'s game!`,
    url,
  };

  return await openShare(shareData);
};

/**
 * Share profile with device's Open Share API tray with clipboard backup.
 *
 * If returned true, share tray was used.
 * If returned false, text and URL is put into clipboard.
 * @param username
 * @param url
 * @returns
 */
export const openShareOwnProfile = async ({ username, url }: { username: string; url?: string }) => {
  url ||= `${window.location.origin}/@${username}`;

  const shareData = {
    title: 'Gemz',
    text: 'Come and play my games on Gemz!',
    url,
  };

  return await openShare(shareData);
};

/**
 * Share profile with device's Open Share API tray with clipboard backup.
 *
 * If returned true, share tray was used.
 * If returned false, text and URL is put into clipboard.
 * @param username
 * @param url
 * @returns
 */
export const openShareProfile = async ({ username, displayName, url }: { username: string; displayName: string; url?: string }) => {
  url ||= `${window.location.origin}/@${username}`;

  const shareData = {
    title: 'Gemz',
    text: `Come and play ${displayName}'s games on Gemz!`,
    url,
  };

  return await openShare(shareData);
};

/**
 * Share via open share API tray, with clipboard copy fallback.
 *
 * If true, open share API tray was used.
 * If false, the text and URL is put into clipboard.
 * @param shareData
 * @returns
 */
export const openShare = async (shareData: ShareData) => {
  console.log('>>> website - sharing through openShare api with shareData:', shareData);

  try {
    await navigator.share(shareData);
    console.log('Gemz shared successfully');
    return true;
  } catch (err) {
    console.warn(`Gemz share Error: ${err}`);
  }

  // clipboard as backup
  try {
    await navigator.clipboard.writeText(`${shareData.text}\n${shareData.url}`);
    console.log(`Gemz clipboard shared successfully`);
  } catch (err) {
    console.warn(`Gemz clipboard share Error: ${err}`);
  }
  return false;
};
