import { useMemo } from 'react';

export function useClassName(...classNames: Array<string | string[]>): string {
  return useMemo(
    () =>
      (classNames as any[])
        .flat()
        .filter(Boolean) // truthy
        .join(' '),
    classNames,
  );
}
