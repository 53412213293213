/*
* @readonly This file is generated dynamically. Change 'envs/{env}.ts' instead.
*/
import { Env } from "@storyverseco/svs-types";
import { baseSepolia, sepolia } from "@alchemy/aa-core";
import { BASE_SEPOLIA_ENTRYPOINT_ADDRESS } from 'config/consts';

export const env = {
  l1Chain: sepolia,
  l2Chain: baseSepolia as any,
  assetsApiPrefix: '',
  authBaseUrl: 'https://auth.beta.pnk.one',
  chainIds: [5] as number[],
  env: 'staging' as Env,
  foundersPassContract: '',
  gameUrl: 'https://view.stage.gemz.pnk.one?iframed=true',
  mbaasApiKey: '',
  mbaasBaseUrl: '',
  contractName: 'NEEDS_UPDATING_LAZY_BUY',
  mediaServiceBaseUrl: '',
  persistPlotServerPrefix: '',
  persistStoryServerPrefix: '',
  pipelineBaseUrl: 'https://pipeline.staging.pnk.one',
  plotsBaseURI: '',
  serviceProxyUrl: '',
  templateApiPrefix: '',
  walletConnectId: '',
  walletConnectorVersion: 1 as 1 | 2,
  privyAppId: 'clpk3zfld005el60fnocm2ugi',
  oneSignalAppId: 'c6b91ea6-5348-41f1-bbfb-e2f701c8abac', // -> staging.pnk.one
  urls: {
    rpc: {
      alchemy: 'https://base-sepolia.g.alchemy.com/v2/Tx_Qwmy8UD7ZCTKoQt0rOeBpFdKiEx_2',
      coinbase: 'https://api.developer.coinbase.com/rpc/v1/base-sepolia/_lIXH-xvn2qABZn1z1qmdim09LbrNJ8n',
    }
  },
  entryPointAddress: BASE_SEPOLIA_ENTRYPOINT_ADDRESS,
}
