import { AppEvents, Conditions, GameEvents } from '@storyverseco/svs-types';
import { listenToGameEvent, navbar, sendGameEvent } from '../navbarSuite';
import { AppController, controller } from '../Controller';
import { AiAssistantController } from '../../pages/aiAssistant/AiAssistantController';
import { StoryTapOpts } from '../../types/types';
import { Routes, gamePages } from 'router';
import { api } from 'lib/apis';
import { Modals } from 'features/Modals';
import { getGameSafeArea, getHeaderSafeArea } from 'lib/apis/pwa';
import { Logger } from 'lib/BaseClass';
import { getAccessToken } from '@privy-io/react-auth';
import { FeedServiceEndpoints } from 'lib/apis/endpoints';
import { feedServiceApi } from 'lib/apis/base';
import { feed } from 'lib/apis/feed';
import { StepId, TimelineId } from '@storyverseco/svs-story-suite';

export enum ViewerState {
  Idle = 'ViewerState/Idle',
  Initialising = 'ViewerState/Initialising',
  Ready = 'ViewerState/Ready',
  Playable = 'ViewerState/Playable',
}

export enum GameState {
  Unloaded = 'GameState/Unloaded',
  Idle = 'GameState/Idle',
  Viewer = 'GameState/Viewer',
  Creator = 'GameState/Creator',
  Loading = 'GameState/Loading',
}

export enum GameMode {
  Idle = 'GameMode/Idle',
  Viewer = 'GameMode/Viewer',
  Creator = 'GameMode/Creator',
}

export const getGameDimensions = () => {
  let width, height;

  if (controller.ui.isDesktopLayout) {
    const elm = document.getElementById('story-game');
    if (elm) {
      const style = getComputedStyle(elm);
      width = parseInt(style.getPropertyValue('width')) * 1;
      height = parseInt(style.getPropertyValue('height')) * 1;
    } else {
      console.error('>>> getGameDimensions - story-game element was not found');
      width = 0;
      height = 0;
    }
    // console.warn('>>> DESKTOP dimensions', width, height);
  } else {
    const elm = document.getElementsByTagName('body')[0];
    if (elm) {
      width = elm.clientWidth;
      height = elm.clientHeight + getHeaderSafeArea();
    } else {
      console.error('>>> getGameDimensions - body element was not found');
      width = 0;
      height = 0;
    }
    // console.warn('>>> MOBILE dimensions', width, height);
  }

  return { width, height };
};

const validateDimensions = ({ width, height }) => {
  return isFinite(width) || isFinite(height) || width >= 2 || height >= 2;
};

export class ViewerController extends Logger {
  private _showViewer = false;

  // Viewer initialisation state
  private _viewerState = ViewerState.Idle;

  // State of the game
  private _gameState = GameState.Unloaded;

  private _version = '';

  private _gameMode = GameMode.Idle;

  private viewerReadyResolver: any;
  public viewerReady = new Promise((resolve) => {
    this.viewerReadyResolver = resolve;
  });

  private firstStoryLoaded = false;

  private eventListeners: (() => void)[] = [];

  private _currentGameProps?: { gameId: number };

  private isHandlingOutcome = false;

  private gameViewSent = false;
  private storyData?: { timelineId: TimelineId; stepId: StepId };

  // Public for now change later
  public create = new AiAssistantController(this.app);

  get version() {
    return this._version;
  }

  get showViewer() {
    return this._showViewer;
  }

  get hasStarted() {
    return this._viewerState !== ViewerState.Idle;
  }

  get isViewerReady() {
    return this._viewerState === ViewerState.Ready;
  }

  get currentGame() {
    return this._currentGameProps;
  }

  constructor(private app: AppController) {
    super();
  }

  private gameSafeRenderArea?: any;
  private get gameSafeArea() {
    // recalculate safe areas for story header and footer
    this.gameSafeRenderArea = getGameSafeArea();
    return this.gameSafeRenderArea;
  }

  private onViewerLoaded = () => {
    this._viewerState = ViewerState.Ready;
    console.log('Viewer Load (Timestamp): ', new Date().toLocaleTimeString(undefined, window.gzTimeFormat));
    this.viewerReadyResolver();
  };

  private updateVisibility = (show: boolean) => {
    this._showViewer = show;
    this.eventListeners.forEach((fn) => fn());
    sendGameEvent('SAFE_AREA' as AppEvents, this.gameSafeArea);
  };

  private maybeSendGameView = (gameId: string) => {
    if (this.gameViewSent) {
      return;
    }
    if (this._gameState !== GameState.Viewer) {
      return;
    }
    this.gameViewSent = true;
    feed.addGameView({ userId: controller.user.me.id, gameId, recommId: controller.feed.recombeeId });
  };

  onAiAssistantStart = () => {
    this.updateVisibility(true);
  };

  hideViewer = () => {
    this.updateVisibility(false);
  };

  onGameVisibilityChange = (value: () => void) => {
    this.eventListeners.push(value);
    return () => {
      this.eventListeners = this.eventListeners.filter((e) => e !== value);
    };
  };

  // Add game events
  onNavbarReady = () => {
    // @TODO: Update this event name :dizzy:
    listenToGameEvent('CAI_IS_HERE' as GameEvents, async () => {
      this._viewerState = ViewerState.Playable;
      this.onViewerLoaded();

      const authToken = await getAccessToken();
      navbar.sendClientEvent('AUTHENTICATED', { authToken });
    });
    listenToGameEvent('GAME_VERSION' as GameEvents, ({ version }: { version: string }) => {
      this._version = version;
    });

    listenToGameEvent('STORY_TAP' as GameEvents, (opts: StoryTapOpts) => {
      if (this.app.currentRoute === Routes.Create) {
        this.create.onStoryTap(opts);
      } else {
        this.handleExistingOutcome();
      }
    });
    listenToGameEvent('STORY_SCROLL' as GameEvents, (opts: any) => {
      if (gamePages.includes(this.app.currentRoute)) {
        console.log('STORY_SCROLL', { opts });
        const hasScrolled = controller.feed.carousel.doScroll(opts);
        if (hasScrolled) {
          this.updateVisibility(false);
        }
      }
    });

    listenToGameEvent(
      GameEvents.OnStoryProgress,
      ({ gameId, portion, timelineId, stepId }: { gameId: string; portion: number; timelineId: TimelineId; stepId: StepId }) => {
        if (controller.user.me) {
          this.maybeSendGameView(gameId);
          feed.addGamePortion({ userId: controller.user.me.id, gameId, portion, recommId: controller.feed.recombeeId });
        }
        this.storyData = { timelineId, stepId };
        this.app.user.checkForCoinReward({
          gameId: Number(gameId),
          timelineId,
          stepId,
          rewardType: 'nextStep',
        });
      },
    );

    listenToGameEvent(GameEvents.OnStoryReady, async (opts) => {
      const storyGem = controller.feed.getGameById(opts.gameId);
      this.log(GameEvents.OnStoryReady, this.app.currentRoute);
      if (!this.firstStoryLoaded) {
        console.log('Game Load (Timestamp): ', new Date().toLocaleTimeString(undefined, window.gzTimeFormat));
        this.firstStoryLoaded = true;
      }

      if (storyGem.gem.type === 'craftable') {
        // lazy load, no need to await
        controller.craft.loadMissionData(opts.gameId);
      }

      // reset gameViewSent
      this.gameViewSent = false;
      this._gameState = GameState.Viewer;

      // Show Game: Add a timeout so there's time for the feed scroll to happen
      setTimeout(() => {
        if (gamePages.includes(this.app.currentRoute)) {
          this.updateVisibility(true);
        }
      }, 350); // 250
    });
    listenToGameEvent(GameEvents.OnChoicePress, (opts) => {
      this.app.user.checkForCoinReward({
        gameId: Number(this.currentGame.gameId),
        timelineId: this.storyData.timelineId,
        stepId: this.storyData.stepId,
        choiceId: opts.choiceId,
        rewardType: 'correctChoice',
      });
    });

    navbar.on('STORY_RESULT', async ({ outcome }) => {
      // @TODO: Investigate why would the game be calling this if not playing a feed game
      if (gamePages.includes(this.app.currentRoute)) {
        this.handleGameOutcome(outcome);
      }
    });

    // AI EVENTS
    listenToGameEvent(GameEvents.AIStoryCreateStarted, this.create.onAiStart);
    listenToGameEvent(GameEvents.AIStoryCreateUpdated, this.create.onAiUpdate);
    listenToGameEvent(GameEvents.AiStoryCreateCompleted, this.create.onAiEnd);
    listenToGameEvent('AiAssistant.AiFeedback' as GameEvents, this.create.onAiFeedback);
  };

  private onOutcomeModalClose = () => {
    this.isHandlingOutcome = false;
  };

  // @TODO: If the user has unlocked the gem somehow else, just show the outcome win regardless
  private handleGameOutcome = async (outcome: Conditions | 'win' | 'lose') => {
    if (!this.app.isAuth) {
      this.app.privy.login();
      return;
    }

    if (this.isHandlingOutcome) {
      return;
    }

    const { currentItem } = this.app.feed;
    if (currentItem.info?.outcome?.state === 'unlocked') {
      return controller.craft.showGameOutcomeModal(currentItem, { data: { referrer: 'HandleGameOutcome' } });
    }

    this.isHandlingOutcome = true;
    // @Carles: IS AI STILL CALLING THIS?
    console.log('handleGameOutcome');
    let outcomeUrl = '';
    // Outcome is only set when coming from AI, otherwise we should figure it out
    if (typeof outcome !== 'string') {
      // This will unlock the game if outcome = win
      const hwid = this.app.helpAFriendOpts?.gameId === this.currentGame.gameId ? this.app.helpAFriendOpts?.hwid : undefined;

      const outcomeResponse = await api.game.get.outcome(this.currentGame.gameId, outcome as Conditions, hwid);
      outcome = outcomeResponse.outcome;
      outcomeUrl = outcomeResponse.url;

      // doing it here so we only do it once, hopefully?
      if (outcome === 'win') {
        // purposely not awaiting
        api.feed.gameRatings
          .increment({ gameId: this.currentGame.gameId, userId: this.app.user.me.id })
          .then((result) => {
            const failedResultEntries = Object.entries(result?.failedResults ?? {});
            if (failedResultEntries.length > 0) {
              console.error('handleGameOutcome gameRatings increment failed result entries', failedResultEntries);
              throw new Error('Error occurred during gameRatings increment');
            }
          })
          .catch((e) => {
            console.error('handleGameOutcome gameRatings increment error:', e);
          });
      } else if (outcome === 'lose') {
        // purposely not awaiting
        api.feed.gameRatings
          .decrement({ gameId: this.currentGame.gameId, userId: this.app.user.me.id })
          .then((result) => {
            const failedResultEntries = Object.entries(result?.failedResults ?? {});
            if (failedResultEntries.length > 0) {
              console.error('handleGameOutcome gameRatings decrement failed result entries', failedResultEntries);
              throw new Error('Error occurred during gameRatings decrement');
            }
          })
          .catch((e) => {
            console.error('handleGameOutcome gameRatings decrement error:', e);
          });
      }
    }

    if (outcome === 'win') {
      await controller.feed.currentItem.refresh();
      const currentGame = controller.feed.getGameById(this.currentGame.gameId);
      controller.craft.showGameOutcomeModal(currentGame, {
        message: outcomeUrl,
        onClose: this.onOutcomeModalClose,
        data: { referrer: 'GameWin' },
      });
      this.app.user.checkForCoinReward({
        gameId: Number(this.currentGame.gameId),
        timelineId: this.storyData.timelineId,
        stepId: this.storyData.stepId,
        rewardType: 'winGame',
      });
    } else if (outcome === 'lose') {
      controller.feed.currentItem.refresh();
      this.app.modals.open(Modals.GameOutcomeLose, {
        message: outcomeUrl,
        onClose: this.onOutcomeModalClose,
      });
      this.app.user.checkForCoinReward({
        gameId: Number(this.currentGame.gameId),
        timelineId: this.storyData.timelineId,
        stepId: this.storyData.stepId,
        rewardType: 'lostGame',
      });
    }
  };

  private handleExistingOutcome = async () => {
    if (this._gameMode !== GameMode.Viewer) return;

    const { currentItem } = this.app.feed;
    const outcomeState = currentItem.info?.outcome?.state;

    if (outcomeState == null) return;

    const modalOpts = {
      data: { item: currentItem },
    };

    if (outcomeState === 'locked') {
      controller.modals.open(Modals.GameOutcomeLose, modalOpts);
    } else {
      controller.craft.showGameOutcomeModal(currentItem, modalOpts);
    }
  };

  loadViewer = () => {
    this._viewerState = ViewerState.Initialising;
  };

  playStory = async (storyUrl: string) => {
    this._gameMode = GameMode.Viewer;
    this._gameState = GameState.Loading;

    const dimensions = getGameDimensions();

    const props: any = {
      gameId: this.app.feed.currentItem.id,
      gameUrl: storyUrl,
      // @Carles: re-add scroll direction and add more feed items, not only the first game in the feed
      preloadData: {
        feed: this.app.feed.items.map((i) => i.getRawFeedData(true)),
        scrollDirection: this.app.feed.carousel.scrollDirection,
        currentFeedItem: this.app.feed.currentItem.getRawFeedData(true),
      },
      safeArea: {
        header: 0,
        footer: 0,
      },
      gameWidth: dimensions.width,
      gameHeight: dimensions.height,
    };

    this._currentGameProps = props;

    // const { steps } = storyData.timelines.t1;

    // const stepWithChoicesId = Object.keys(steps).find((stepId) => Boolean(steps[stepId as StepId].choices));

    // if (deeplinkToFirstChoice && stepWithChoicesId) {
    //   props.deeplink = {
    //     timelineId: 't1',
    //     stepId: stepWithChoicesId,
    //   };
    // }

    console.log('ViewerController', 'playGame', { props });

    navbar.sendClientEvent(AppEvents.PlayStory, props);
  };

  replay = () => {
    sendGameEvent(AppEvents.ReloadCurrentStory);
  };
}
