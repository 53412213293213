import { UnsignedTransactionRequest } from '@privy-io/react-auth';
import { GemType, GemzSignatureResponse, WalletAddress } from '@storyverseco/svs-types';
import { Modals } from 'features/Modals';
import { Logger } from 'lib/BaseClass';
import { AppController } from 'lib/Controller';
import { api } from 'lib/apis';
import { CreateGameSignatureResponse } from 'lib/apis/gemz';
import { getErrorMsg } from 'lib/utils';

interface BuyGemProps {
  offChainGameId: number;
  onChainGameId?: number;
  creatorAddress: WalletAddress;
  free?: boolean;
  newGame?: boolean;
  gemType: GemType;
  offeredPrice: number;
}

const PRICE_DRIFT = 'priceDrift';

export class GemzController extends Logger {
  // Handle buy/sell diamond txs in order
  private buyDiamondQueue: BuyGemProps[] = [];

  private currentTx?: BuyGemProps;

  private isBuying = false;

  constructor(private app: AppController) {
    super();
  }

  private handleTxQueue = async () => {
    // If we already have a request in flight, skip
    if (this.isBuying) {
      return;
    }

    if (this.currentTx) {
      try {
        const response = await this.sendBuyTx(this.currentTx);
        if (response === PRICE_DRIFT) {
          await this.app.feed.getGameById(this.currentTx.offChainGameId).refresh();
          this.app.modals.open(Modals.Error, {
            title: 'Failed to buy gem',
            message: 'The price has changed since you tried to purchase.\nPlease try again',
          });
        } else {
          this.app.feed.getGameById(this.currentTx.offChainGameId).refresh();
        }
      } catch {
        this.app.modals.open(Modals.Error);
      } finally {
        this.currentTx = undefined;
        this.isBuying = false;
        this.handleTxQueue();
      }
    } else {
      if (this.buyDiamondQueue.length > 0) {
        const [nextTx, ...txQueue] = this.buyDiamondQueue;
        this.buyDiamondQueue = txQueue;
        this.currentTx = nextTx;
        this.handleTxQueue();
      }
    }
  };

  buyGem = (props: BuyGemProps) => {
    if (props.gemType === 'coin') {
      const hoid = this.app.helpAFriendOpts?.gameId === props.offChainGameId ? this.app.helpAFriendOpts?.hoid : undefined;
      return api.coins.buy(props.offChainGameId, props.offeredPrice, hoid);
    }

    const buyerAddress = this.app.user.me?.walletAddress;

    if (!buyerAddress) {
      this.error(getErrorMsg('buyGem', `Trying to buy 'diamond' gem without user set`));
      return;
    }

    this.buyDiamondQueue.push(props);
    this.handleTxQueue();
    this.log('buyGem resolve');
    return Promise.resolve();
  };

  buyCraftableGem = async (props) => {
    return api.coins.buyCraftable(props.offChainGameId, props.gameState);
  };

  sellGem = async (gameId: number, gemType: GemType = 'coin', offeredPrice: number) => {
    this.log('sellGem', [{ gameId, gemType }]);
    if (gemType === 'coin') {
      return api.coins.sell(gameId, offeredPrice);
    }

    if (gemType === 'craftable') {
      return api.coins.sellCraftable(gameId, offeredPrice);
    }

    const holderAddress = this.app.user.me?.walletAddress;

    if (!holderAddress) {
      this.log('sellGem', `Missing 'creatorAddress'. Skip.`);
      return;
    }

    try {
      const tx = await api.multibaas.getSellGemTransaction({ gameId, holderAddress });
      await this.app.sendTx(tx);
    } catch (e) {
      console.error(`Error (sellGem|getSellGemTransaction):\n`, e);
      throw new Error(`Failed to retrieve 'sellGem|getSellGemTransaction'.`);
    }
  };

  claimFreeGem = async () => {
    const offChainGameId = this.app.user.me?.attributes.freeGemOffChainGameId;

    if (!offChainGameId) {
      return;
    }

    try {
      const freeGame = await api.game.get.pubGame(offChainGameId.toString());

      if (!freeGame) {
        throw new Error(`Error (claimFreeGem): Could not find user free game`);
      }

      // @TODO: Move this to User.claimFreeGem();
      // Await on everything because is coin gem (should be fast)
      await api.user.claim.start();

      // Buy our free gem
      await this.app.gemzOps.buyGem({
        offChainGameId,
        onChainGameId: undefined,
        creatorAddress: this.app.user.me.walletAddress as WalletAddress,
        free: true,
        gemType: 'coin',
        offeredPrice: 0,
      });

      await api.user.claim.success(offChainGameId);
      // await dispatch(fetchAndUpdateMe());

      // // Make sure the user data has been update with gemState/WaitingForConfirmation
      // await updateFeed();
      // dispatch(uiStore.actions.onModalClose());
      // dispatch(uiStore.actions.toggleDisableNavbar(false));
    } catch (e) {
      // if something went wrong, display error modal when the error returns
      await api.user.claim.failure();
      // console.error(`Error (Controller|onBuyGem)`, e);
      this.app.modals.open(Modals.Error, { subtitle: 'Failed to claim free gem' });
    }
  };

  private sendBuyTx = async (props: BuyGemProps) => {
    const buyerAddress = this.app.user.me?.walletAddress;

    if (!buyerAddress) {
      return;
    }

    this.isBuying = true;

    try {
      const { newGame, free, offChainGameId, creatorAddress, onChainGameId } = props;

      // For free gems we don't set `amount` (priceStr) in the request. So default to undefined
      let priceStr: string;

      if (onChainGameId && !newGame && !free) {
        // @TODO: Ideally would be nice to know if the game is onchain or not at this time
        // So we can set to '0' if it's offchain, and getPrice if it's on chain.
        // For now if we fail to get price, assume the game is offchain and default to '0'.
        try {
          priceStr = await api.multibaas.getBuyPrice({ onchainGameId: onChainGameId });
        } catch (e) {
          console.error(`Error (buyGem|getPrice):\n`, e);
        }
      }

      let gemSignatureData: GemzSignatureResponse;

      try {
        gemSignatureData = await api.gemz.getBuyGemSignature({
          offChainGameId,
          holderAddress: buyerAddress,
        });
      } catch (e) {
        console.error(`Error (buyGem|signBuyGem):\n`, e);
        throw new Error(`Failed to retrieve 'signBuyGem'.`);
      }

      let gameSignResponse: CreateGameSignatureResponse;

      try {
        gameSignResponse = await api.gemz.getCreateGameSignature({
          offChainGameId,
          creatorAddress,
        });
      } catch (e) {
        console.error(`Error (buyGem|signCreateGame):\n`, e);
        throw new Error(`Failed to retrieve 'creteGame' signature.`);
      }

      let tx: UnsignedTransactionRequest;

      try {
        tx = await api.multibaas.getBuyGemTransaction({
          gemRequest: gemSignatureData.rawMessage,
          gemSignature: gemSignatureData.signature,
          gameRequest: gameSignResponse.signatureData.rawMessage,
          gameSignature: gameSignResponse.signatureData.signature,
          fromAddress: buyerAddress,
          amount: priceStr,
        });
      } catch (e) {
        // Handle insufficient payment (in case we have a free game that is not actually free)
        if (e.message.includes('insufficient payment')) {
          try {
            const updatedGame = await api.game.handleInsufficientPayment(offChainGameId);
            if (updatedGame.gameId) {
              return Promise.resolve(PRICE_DRIFT);
            }
          } catch (e) {
            this.error('Failed to handle insufficient payment', e);
            throw e;
          }
        }

        console.error(`Error (buyGem|getBuyGemTransaction):\n`, e);
        throw new Error(`Failed to get transaction`);
      }

      let txHash: string;

      let txType = '';

      try {
        if (free) {
          txType = 'sendPaymasterTransaction';
          txHash = await this.app.sendTx(tx, true);
        } else {
          txType = 'sendL2Transaction';
          txHash = await this.app.sendTx(tx);
        }
      } catch (e) {
        console.error(`Error (buyGem|${txType}):\n`, e);
        // throw new Error(`Failed to send transaction`);
        throw e;
      }

      if (!txHash) {
        throw new Error(`Error(buyGem): Failed to get transaction hash`);
      }

      try {
        const response = await api.gemz.confirmBuyGem({ txHash });
        this.isBuying = false;
        return response;
      } catch (e) {
        console.error(`Error (buyGem):\n`, e);
        throw new Error(`Failed to confirm buy gem`);
      }
    } catch (e) {
      this.isBuying = false;
      throw e;
    }
  };
}
