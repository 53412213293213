// Modern Chrome requires { passive: false } when adding event

function getWheelOptions() {
  var supportsPassive = false;
  try {
    const obj = Object.defineProperty({}, 'passive', {
      get: function () {
        supportsPassive = true;
      },
    });
    // @TODO: Can this be removed? What is the point of this listener?
    // @ts-ignore
    window.addEventListener('test', null, obj);
  } catch (e) {}

  return supportsPassive ? { passive: false } : false;
}

// Enable/Disable scroll gesture on mobile on the app root level,
// by enabling/disabling touchmove gesture

function preventDefaultAndPropagation(e: Event) {
  e.preventDefault();
  e.stopPropagation();
}

// @TODO: Rename this fn
export function setAppScrolling(enabled: boolean) {
  if (enabled) {
    window.removeEventListener('touchmove', preventDefaultAndPropagation, false);
    document.removeEventListener('touchmove', preventDefaultAndPropagation, false);
    document.body.removeEventListener('touchmove', preventDefaultAndPropagation, false);
  } else {
    window.addEventListener('touchmove', preventDefaultAndPropagation, getWheelOptions());
    document.addEventListener('touchmove', preventDefaultAndPropagation, getWheelOptions());
    document.body.addEventListener('touchmove', preventDefaultAndPropagation, getWheelOptions());
  }
}
