import { controller } from '../../lib/Controller';
import { UIElementKey } from '../../lib/controllers/UIController';
import { TopBarCoinCurrency } from './TopBarCoinCurrency';
import { TopBarPointCurrency } from './TopBarPointCurrency';
import { TopBarDiamondCurrency } from './TopBarDiamondCurrency';
import './TopBar.scss';
import TopBarSearch from './TopBarSearch';
import { useReRender } from 'hooks/useReRender';
import { CraftMissions } from 'components/CraftItem/CraftMissions';
import { UserControllerEvent } from 'lib/controllers/auth/UserController';
import { useEffect } from 'react';
import { Routes } from 'router';

export function TopBar() {
  // whenever the ui triggers on_change, we re-render the component
  useReRender({ id: 'TopBar', listener: controller.ui.attachEventListener('on_change') });
  useReRender({ id: 'TopBar:route', listener: controller.attachEventListener('on_route_change') });
  useReRender({ id: 'TopBar:auth', listener: controller.attachEventListener('on_auth_change') });
  useReRender({ id: 'TopBar:missions', listener: controller.craft.attachEventListener('on_mission_update') });
  useReRender({ id: 'TopBar:balance', listener: controller.user.attachEventListener(UserControllerEvent.OnBalanceUpdate) });

  const isGameFeedRoute = controller.currentRoute === Routes.GameFeed || controller.currentRoute === Routes.GameFeedWithUser;
  const isProfileRoute = controller.currentRoute === Routes.Profile || controller.currentRoute === Routes.ProfileAt;
  const isShowing = isGameFeedRoute || isProfileRoute;

  const coinCurrency = controller.ui.elements[UIElementKey.TopBarCoinCurrency];
  const diamondCurrency = controller.ui.elements[UIElementKey.TopBarDiamondCurrency];

  const updateBalance = () => {
    const coinBalance = controller.user.me?.coinBalance;
    const diamonBalance = controller.user.me?.diamondBalance;

    coinCurrency.updateState({ displayBalance: Math.floor(coinBalance) });
    diamondCurrency.updateState({ displayBalance: diamonBalance });
  };

  useEffect(() => {
    coinCurrency.show();
    diamondCurrency.show();
    updateBalance();
  });

  if (!isShowing) {
    return null;
  }

  return (
    <div className={controller.ui.isMobileLayout && isProfileRoute ? 'topbar right-align' : 'topbar'}>
      <div className="topbar-currencies">
        {controller.isAuth && <TopBarCoinCurrency />}
        {controller.isPointsFeatureEnabled && <TopBarPointCurrency />}
        {controller.isAuth && <TopBarDiamondCurrency />}
      </div>
      {controller.ui.isMobileLayout && isGameFeedRoute && <TopBarSearch />}
      {controller.isAuth && isGameFeedRoute && <CraftMissions />}
    </div>
  );
}

export default TopBar;
