import { LazyExoticComponent, lazy } from 'react';

/*
  Register modals here. The naming pattern should be
  {mode: 'modal' | 'drawer'}/{type: ModalState}/{?}
*/
export enum Modals {
  // Modals
  // PWA = 'modal/pwa-gate', // Deprecated
  // Login = 'modal/login-gate', // Deprecated
  // AiOutcome = 'modal/ai-outcome', // Deprecated
  // AiConfirm = 'drawer/drawer-confirm-ai', // Deprecated
  // GemSellSuccess = 'drawer/gem-sell-success', // Deprecated

  // Drawers
  Error = 'drawer/error-msg',
  Message = 'drawer/generic-msg',

  PWA = 'drawer/pwa-install',

  InviteUser = 'drawer/invite-user',
  ConnectX = 'drawer/connect-x',
  FollowUser = 'drawer/follow-user',
  FreeGem = 'drawer/free-gem',

  GameOutcomeLocked = 'drawer/outcome-locked',
  GameOutcomeWin = 'drawer/outcome-win',
  GameOutcomeLose = 'drawer/outcome-lose',

  GemBuyConfirm = 'drawer/gem-buy-confirm',
  GemSellConfirm = 'drawer/gem-sell-confirm',
  GemBuySuccess = 'drawer/gem-buy-success',
  GemPublishSuccess = 'drawer/gem-publish-success',

  HelpFriend = 'drawer/help-friend',

  Comments = 'drawer/comments',

  DrawerCraftMake = 'drawer/craft-make',
  DrawerCraftList = 'drawer/craft-list',

  GetPoints = 'drawer/get-points',

  Withdraw = 'drawer/withdraw',
}

export const modal: Record<Modals, LazyExoticComponent<() => JSX.Element>> = {
  // [Modals.Login]: lazy(() => import('./components/ModalLoginGate')), // Deprecated
  // [Modals.AiOutcome]: lazy(() => import('./components/ModalOutcomeAi')), // Deprecated
  // [Modals.AiConfirm]: lazy(() => import('./components/DrawerConfirmAi')), // Deprecated
  // [Modals.GemSellSuccess]: lazy(() => import('./components/DrawerGemSellSuccess')), // Deprecated

  [Modals.Error]: lazy(() => import('./components/DrawerError')),
  [Modals.Message]: lazy(() => import('./components/DrawerMessage')),

  [Modals.PWA]: lazy(() => import('./components/onboarding/DrawerPWAInstall')),
  [Modals.InviteUser]: lazy(() => import('./components/onboarding/DrawerInviteUser')),
  [Modals.ConnectX]: lazy(() => import('./components/onboarding/DrawerConnectX')),
  [Modals.FollowUser]: lazy(() => import('./components/onboarding/DrawerFollowUser')),
  [Modals.FreeGem]: lazy(() => import('./components/onboarding/DrawerFreeGem')),

  [Modals.GameOutcomeLocked]: lazy(() => import('./components/DrawerOutcomeLocked')),
  [Modals.GameOutcomeWin]: lazy(() => import('./components/DrawerOutcomeWin')),
  [Modals.GameOutcomeLose]: lazy(() => import('./components/DrawerOutcomeLose')),

  [Modals.GemBuyConfirm]: lazy(() => import('./components/DrawerGemBuyConfirm')),
  [Modals.GemSellConfirm]: lazy(() => import('./components/DrawerGemSellConfirm')),
  [Modals.GemBuySuccess]: lazy(() => import('./components/DrawerGemBuySuccess')),
  [Modals.GemPublishSuccess]: lazy(() => import('./components/DrawerGemPublishSuccess')),

  [Modals.HelpFriend]: lazy(() => import('./components/DrawerHelpFriend')),

  [Modals.Comments]: lazy(() => import('./components/DrawerComments')),

  [Modals.DrawerCraftMake]: lazy(() => import('./components/DrawerCraftMake')),
  [Modals.DrawerCraftList]: lazy(() => import('./components/DrawerCraftList')),

  [Modals.GetPoints]: lazy(() => import('./components/DrawerGetPoints')),
  [Modals.Withdraw]: lazy(() => import('./components/DrawerWithdraw')),
};
