import './CraftItem.scss';
import './CraftInfo.scss';
import { controller } from 'lib/Controller';
import { images } from 'assets/assets';
import { useEffect, useState } from 'react';
export type ProgressRenderMode = 'none' | 'bar' | 'label' | 'all';

export function CraftItem(opts: {
  size: number;
  progress?: number;
  progressRenderMode?: ProgressRenderMode;
  gameData: any;
  showUser?: boolean;
  onClick?: (progress: number) => void;
}) {
  const mockupGemImage = images.gemAssetMockup;

  const progressRenderMode = opts.progressRenderMode === undefined ? 'bar' : opts.progressRenderMode;
  const showBar = progressRenderMode === 'bar' || progressRenderMode === 'all';
  const showLabel = progressRenderMode === 'label' || progressRenderMode === 'all';
  const showUser = opts.showUser === undefined ? true : opts.showUser;
  const sz = opts.size; // 64;
  const { game, amount, availableAmount, progress } = opts.gameData;

  return (
    <div className="craft-item craft-gem" style={{ '--size': `${sz}px` } as React.CSSProperties} onClick={() => opts?.onClick(opts.progress)}>
      <div className="craft-item-content">
        {/* progress bar */}
        {showBar && (
          <svg
            width={sz}
            height={sz}
            viewBox={`0 0 ${sz} ${sz}`}
            className="circular-progress-bar"
            style={{ '--size': `${sz}px`, '--progress': progress } as React.CSSProperties}
          >
            <circle className="bg" />
            {progress > 0 && <circle className="fg" />}
          </svg>
        )}

        {/* tbd icons (low + high) */}
        <div className="craft-item-icons-container">
          <div className="craft-item-icon" style={{ backgroundImage: `url(${game?.gem?.img || mockupGemImage})` }} />
        </div>

        {/* tbd user icon (low + high) */}
        {showUser && (
          <div className="craft-item-user-icons-container">
            <div className="craft-item-user-icon" style={{ backgroundImage: `url(${game?.twitter?.image || images.defaultUser})`, zIndex: 1 }} />
          </div>
        )}
      </div>

      {/* bottom label */}
      {showLabel && <div className="craft-item-label">{`${availableAmount} / ${amount}`}</div>}
    </div>
  );
}

export default CraftItem;
