import { createLightAccountClient } from '@alchemy/aa-accounts';
import { alchemyActions } from '@alchemy/aa-alchemy';
import { SmartAccountClient, SmartAccountSigner, UserOperationRequest, WalletClientSigner, deepHexlify, resolveProperties } from '@alchemy/aa-core';
import { ConnectedWallet, UnsignedTransactionRequest } from '@privy-io/react-auth';
import { type Chain, createWalletClient, custom, http, parseEther, toHex } from 'viem';
import { Optional, WalletAddress } from '@storyverseco/svs-types';
import { extractUserOpFromError } from './utils';
import { env } from '../../config/env';
import { api } from '../apis';
import { Logger } from '../BaseClass';

// If the error includes the text in here, we should drop and replace operation
const dropAndReplaceErrorList = ['replacement underpriced'];

enum InitState {
  Idle,
  Loading,
  Ready,
}

type Client = any; //Awaited<ReturnType<typeof createLightAccountClient>>;

export class SmartAccountService extends Logger {
  private eoa?: ConnectedWallet;

  private signer?: SmartAccountSigner;

  private alchemyClient: Client;

  private coinbaseClient: Client;

  private _isReady = InitState.Idle;

  get isReady() {
    return this._isReady === InitState.Ready;
  }

  get address() {
    return this.isReady ? this.alchemyClient.account.address : undefined;
  }

  constructor(private chain: Chain) {
    super();
  }

  init = async (eoa: ConnectedWallet) => {
    if (this._isReady !== InitState.Idle) {
      console.warn(`Warning (SmartAccountService): Trying to init more than once.`);
      return;
    }
    this._isReady = InitState.Loading;
    this.eoa = eoa;
    await this.createSigner();
    await this.createAlchemyClient();
    await this.createCoinbaseClient();

    this._isReady = InitState.Ready;
  };

  reset = () => {
    this.eoa = undefined;
    this.signer = undefined;
    this.alchemyClient = undefined;
    this.coinbaseClient = undefined;
    this._isReady = InitState.Idle;
  };

  sendTx = (tx: UnsignedTransactionRequest) => {
    this.log('sendTx', { tx });
    if (!this.isReady) {
      console.warn(`Warning (SmartAccountService): Trying to 'sendTx' before initialising.`);
      return;
    }
    return this._sendTx(tx, this.alchemyClient);
  };

  withdraw = async (amountEth: string, toWalletAddress: string) => {
    const weiValue = parseEther(amountEth);

    try {
      const txHash = await this.alchemyClient.sendTransaction({
        to: toWalletAddress,
        value: weiValue,
      });

      this.log({ txHash });
    } catch (e) {
      this.error(e);
    }
  };

  sendPaymasterTx = async (tx: UnsignedTransactionRequest) => {
    this.log('sendPaymasterTx', { tx });
    if (!this.isReady) {
      console.warn(`Warning (SmartAccountService): Trying to 'sendPaymasterTx' before initialising.`);
      return;
    }
    return this._sendTx(tx, this.coinbaseClient);
  };

  private _sendTx = async (tx: UnsignedTransactionRequest, client: Client) => {
    const userOpParams = {
      uo: {
        target: tx.to as WalletAddress,
        data: tx.data as WalletAddress,
        value: tx.value && BigInt(tx.value),
      },
      account: client.account,
    };

    try {
      const userOpResult = await client.sendUserOperation(userOpParams);
      return client.waitForUserOperationTransaction(userOpResult);
    } catch (e) {
      const userOp = this.shouldDropAndReplace(e);
      if (userOp) {
        return this.sendDropAndReplace(userOp, client);
      }
      throw e;
    }
  };

  private sendDropAndReplace = async (userOp: UserOperationRequest, client: Client) => {
    try {
      const userOpResult = await client.dropAndReplaceUserOperation({
        uoToDrop: userOp,
        account: client.account,
      });
      return client.waitForUserOperationTransaction(userOpResult);
    } catch (e) {
      throw e;
    }
  };

  private requireEOA = (caller: string) => {
    if (!this.eoa) {
      this.throw(`Cannot call '${caller}'. EOA is not set.`);
    }
  };

  private createSigner = async () => {
    this.requireEOA('createSigner');

    const eoaProvider = await this.eoa.getEthereumProvider();
    const eoaClient = createWalletClient({
      account: this.eoa.address as `0x${string}`,
      chain: this.chain,
      transport: custom(eoaProvider),
    }) as any; // @TODO: fix-me (chain type error below),

    // Initialize a SmartAccountSigner from the EOA to authorize actions takenby the smart account
    this.signer = new WalletClientSigner(eoaClient, 'json-rpc');
  };

  private createAlchemyClient = async () => {
    const config = {
      chain: this.chain,
      transport: http(env.urls.rpc.alchemy),
      account: { signer: this.signer },
    } as any; // @TODO: fix-me (chain type error below)

    try {
      this.alchemyClient = await createLightAccountClient(config);
      this.alchemyClient.extend(alchemyActions);
    } catch (e) {
      console.warn('ALCHEMY CLIENT FAILED TO CREATE', e);
      // just let it go to unblock UI
      this.alchemyClient = {
        account: {
          address: undefined,
        },
      };
      return Promise.resolve();
    }
  };

  private createCoinbaseClient = async () => {
    const config = {
      chain: this.chain,
      transport: http(env.urls.rpc.coinbase),
      account: { signer: this.signer },
      gasEstimator: async (struct: any) => ({
        ...struct,
        callGasLimit: 0n,
        preVerificationGas: 0n,
        verificationGasLimit: 0n,
      }),
      paymasterAndData: {
        dummyPaymasterAndData: () => '0x',
        paymasterAndData: async (userop, opts) => {
          if (opts.overrides?.paymasterAndData === '0x') {
            // don't sponsor gas
            return {
              ...userop,
              paymasterAndData: '0x',
            };
          }
          const resolvedUserOp = deepHexlify(await resolveProperties(userop));

          return api.paymaster.populateWithPaymaster(resolvedUserOp);
        },
      },
    } as any; // @TODO: fix-me (chain type error below)
    this.coinbaseClient = await createLightAccountClient(config);
    this.coinbaseClient.extend(alchemyActions);
  };

  private shouldDropAndReplace = (err: Error | any): Optional<UserOperationRequest> => {
    const should = dropAndReplaceErrorList.some((str) => err.message.includes(str));
    if (!should) {
      return undefined;
    }

    return extractUserOpFromError(err);
  };
}
