import { getEndpoint, pipelineApi } from './base';
import { Endpoints } from './endpoints';

export interface InviteProps {
  code: string;
  // userId: string;
}
const consumeInviteCode = async ({ code }: InviteProps) => {
  return pipelineApi.get(getEndpoint(Endpoints.INVITE_CODE, { code }));
};

const getTwitterSecureKey = async () => pipelineApi.get(Endpoints.TWITTER_SECURE);

export const onboarding = {
  invite: consumeInviteCode,
  getTwitterSecureKey,
};
