import { sessionOpts } from 'config/consts';
import { feedServiceApi, getEndpoint, pipelineApi } from './base';
import { FeedServiceEndpoints } from './endpoints';
import { AddRatingRequest, GameFeedItem, Interaction, WinConditionType } from '@storyverseco/svs-types';

const mockFeed: GameFeedItem[] = [
  {
    onChainGameId: 666,
    id: 0,
    createdAt: '',
    creatorAddress: '0x0',
    gameId: 0,
    gamePath:
      'https://cms.stories.beta.pnk.one/stories/gemz/0xe67c21f8a2a640289e71ab20650873e894fb66e6/7c3bc05e63225ab77b812013fb872ca663dcb032ff5e6de9bcdf0b1aeea20634.json',
    charpassContractAddress: '0xe1754daa9fd0d845c3a64d6779e73d085a1b43b7',
    multimediaUrls: {
      desktopThumbnail:
        'https://media.pnk.one/gemz/0xe67c21f8a2a640289e71ab20650873e894fb66e6_7c3bc05e63225ab77b812013fb872ca663dcb032ff5e6de9bcdf0b1aeea20634_desktopThumbnail_1698962606510.jpg',
      profileThumbnail:
        'https://media.pnk.one/gemz/0xe67c21f8a2a640289e71ab20650873e894fb66e6_7c3bc05e63225ab77b812013fb872ca663dcb032ff5e6de9bcdf0b1aeea20634_profileThumbnail_1698962606510.jpg',
      mobileWideThumbnail:
        'https://media.pnk.one/gemz/0xe67c21f8a2a640289e71ab20650873e894fb66e6_7c3bc05e63225ab77b812013fb872ca663dcb032ff5e6de9bcdf0b1aeea20634_mobileWideThumbnail_1698962606510.jpg',
      mobilePortraitThumbnail:
        'https://media.pnk.one/gemz/0xe67c21f8a2a640289e71ab20650873e894fb66e6_7c3bc05e63225ab77b812013fb872ca663dcb032ff5e6de9bcdf0b1aeea20634_mobilePortraitThumbnail_1698962606510.jpg',
    },
    txHash: '0xb541aa61325e247875dd4ce96316b636e79ed5c232ee69fd3dde43815177668d',
    gemName: 'A nice prize',
    winCondition: { conditions: { t1: 'c1', t4: 'c1' }, conditionType: WinConditionType.CHOICES },
    twitter: {
      handle: 'Cai',
      image: 'https://statusneo.com/wp-content/uploads/2023/02/MicrosoftTeams-image551ad57e01403f080a9df51975ac40b6efba82553c323a742b42b1c71c1e45f1.jpg',
      displayName: 'Cai DisplayName',
    },
    freeGame: false,
  },
  {
    onChainGameId: 667,
    id: 1,
    createdAt: '',
    creatorAddress: '0x0',
    gameId: 1,
    gamePath:
      'https://cms.stories.beta.pnk.one/stories/gemz/0x0811ee568f014e7c7ebc99a5428979b1929be799/0f11657327cda1f024d0c9377fb6880fcd0e510904a3092c81bbace7295aa6dc.json',
    charpassContractAddress: '0xe1754daa9fd0d845c3a64d6779e73d085a1b43b7',
    multimediaUrls: {
      desktopThumbnail:
        'https://media.pnk.one/gemz/0x0811ee568f014e7c7ebc99a5428979b1929be799_0f11657327cda1f024d0c9377fb6880fcd0e510904a3092c81bbace7295aa6dc_desktopThumbnail_1698962781997.jpg',
      profileThumbnail:
        'https://media.pnk.one/gemz/0x0811ee568f014e7c7ebc99a5428979b1929be799_0f11657327cda1f024d0c9377fb6880fcd0e510904a3092c81bbace7295aa6dc_profileThumbnail_1698962781997.jpg',
      mobileWideThumbnail:
        'https://media.pnk.one/gemz/0x0811ee568f014e7c7ebc99a5428979b1929be799_0f11657327cda1f024d0c9377fb6880fcd0e510904a3092c81bbace7295aa6dc_mobileWideThumbnail_1698962781997.jpg',
      mobilePortraitThumbnail:
        'https://media.pnk.one/gemz/0x0811ee568f014e7c7ebc99a5428979b1929be799_0f11657327cda1f024d0c9377fb6880fcd0e510904a3092c81bbace7295aa6dc_mobilePortraitThumbnail_1698962781997.jpg',
    },
    txHash: '0xb541aa61325e247875dd4ce96316b636e79ed5c232ee69fd3dde43815177668d',
    gemName: 'Surprise Party',
    winCondition: { conditions: { t1: 'c1', t4: 'c1' }, conditionType: WinConditionType.CHOICES },
    twitter: {
      handle: 'Cai',
      image: 'https://statusneo.com/wp-content/uploads/2023/02/MicrosoftTeams-image551ad57e01403f080a9df51975ac40b6efba82553c323a742b42b1c71c1e45f1.jpg',
      displayName: 'Cai DisplayName',
    },
    freeGame: false,
  },
];

interface RecombeeResponse {
  numberNextRecommsCalls: number;
  recommId: string;
  recomms: GameFeedItem[];
}

const PAGE_SIZE = 20;

const fetchFeed = async (
  userId: number,
  nextPageId?: string,
): Promise<{
  items: GameFeedItem[];
  paginationId: string;
}> => {
  if (sessionOpts.isMockupMode) {
    return Promise.resolve({
      paginationId: 'mock',
      items: mockFeed,
    });
  }

  let response;
  if (!nextPageId) {
    response = await feedServiceApi.post(FeedServiceEndpoints.RECOMMEND_FEED_USER, { userId, count: PAGE_SIZE }, false, undefined, true);
  } else {
    response = await feedServiceApi.post(FeedServiceEndpoints.RECOMMEND_FEED_NEXT, { recommId: nextPageId, count: PAGE_SIZE }, false, undefined, true);
  }

  const res = await response.json();
  const result: RecombeeResponse = res.result;
  console.log({ result });

  return {
    paginationId: result.recommId,
    items: result.recomms,
  };
};

const addRating = (addRatingRequest: AddRatingRequest) => {
  return feedServiceApi.post(FeedServiceEndpoints.EVENTS_ADD_RATINGS, [addRatingRequest], true);
};

const addGamePortion = ({ gameId, userId, portion, recommId }: { gameId: string; userId: number; portion: number; recommId: string }) => {
  feedServiceApi.post(
    FeedServiceEndpoints.EVENTS_GAME_PORTION,
    [
      {
        userId: userId.toString(),
        gameId,
        portion,
        recommId,
      },
    ],
    true,
  );
};

const addGameView = ({ gameId, userId, recommId }: { gameId: string; userId: number; recommId: string }) => {
  feedServiceApi.post(
    FeedServiceEndpoints.EVENTS_GAME_VIEW,
    [
      {
        userId: userId.toString(),
        gameId,
        duration: performance.now(),
        recommId,
      },
    ],
    true,
  );
};

const ratingsIncrement = ({ gameId, userId }: { gameId: number | string; userId: number | string }) => {
  return addRating({
    gameId: gameId.toString(),
    userId: userId.toString(),
    rating: 1,
  });
};

const ratingsDecrement = ({ gameId, userId }: { gameId: number | string; userId: number | string }) => {
  return addRating({
    gameId: gameId.toString(),
    userId: userId.toString(),
    rating: -1,
  });
};

const getGameViewsCount = async (itemId: string): Promise<number> => {
  const response = await feedServiceApi.post(FeedServiceEndpoints.GAME_VIEW_COUNTER, { itemId }, true);
  return response.length;
};

const addBookmark = ({ gameId, userId }: { gameId: number | string; userId: number | string }) => {
  const interaction: Interaction = {
    gameId: gameId.toString(),
    userId: userId.toString(),
  };
  return feedServiceApi.post(FeedServiceEndpoints.EVENTS_ADD_BOOKMARK, [interaction], true);
};

const deleteBookmark = ({ gameId, userId }: { gameId: number | string; userId: number | string }) => {
  const interaction: Interaction = {
    gameId: gameId.toString(),
    userId: userId.toString(),
  };
  return feedServiceApi.post(FeedServiceEndpoints.EVENTS_DELETE_BOOKMARK, [interaction], true);
};

// const getFirstGameInFeed = async (): Promise<GameFeedItem> => {
//   return pipelineApi.get(Endpoints.GAME_GET_FIRST_IN_FEED, false);
// };

// const getFeedPage = async (page: number): Promise<GameFeedItem[]> => {
//   return pipelineApi.get(getEndpoint(Endpoints.FEED, { page }), false);
// };

export const feed = {
  get: fetchFeed,
  gameRatings: {
    increment: ratingsIncrement,
    decrement: ratingsDecrement,
  },
  bookmarks: {
    add: addBookmark,
    // even if `delete` is allowed as a property name, better to not use it because it's a reserved word
    remove: deleteBookmark,
  },
  addGamePortion,
  addGameView,
  getGameViewsCount,
  // firstInFeed: getFirstGameInFeed,
  // feedPage: getFeedPage,
};
