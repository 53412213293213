import { useEffect } from 'react';
import { createBrowserRouter, redirect, useNavigate } from 'react-router-dom';
import { AppWrapper, appLoader } from './pages/AppWrapper';
import { controller } from './lib/Controller';
import { FollowsPageTab } from 'pages/followLists/types';
import { removeAtUsername } from './lib/utils';
import { getQueryParamsWithoutConsumables } from 'config/consts';

export enum Routes {
  /**
   * PRIVATE - Do not navigate to Root
   */
  Root = '/',
  Test = 'test',
  Test2 = 'test2',
  GameFeed = 'feed/:gameId?',
  Profile = 'profile/:username?',
  Leaderboard = 'leaderboard',
  Account = 'account',
  Create = 'create',
  Deposit = 'deposit',
  SingleMsgRoom = 'room/:username',
  MessageRooms = 'room',
  ToS = 'terms_and_conditions',
  Privacy = 'privacy',
  Copyright = 'copyright',
  Cookies = 'cookies',
  Inbox = 'inbox',
  GameFeedWithUser = ':username/game/:gameId',
  Followers = `:username/followers`,
  Following = `:username/following`,
  Holders = `:username/holders`,
  ProfileAt = ':username',
}

export const gamePages = [Routes.GameFeed, Routes.GameFeedWithUser];

/**
 * Omit any dynamic route (those with path params)
 * Dynamic routes should use 'getRouteWithParams'
 */
// @TODO: Should have all the values of the dynamicRoutes list
export type StaticRoutes = Exclude<Routes, Routes.GameFeed | Routes.Profile | Routes.Followers | Routes.Following | Routes.Holders | Routes.SingleMsgRoom>;

export const getRouteWithSearchParams = (route: string, searchParams?: URLSearchParams): string => {
  if (!searchParams?.size) {
    return route;
  }
  const qIndex = route.indexOf('?');
  if (qIndex !== -1) {
    const existingParams = new URLSearchParams(route.substring(qIndex));
    const combinedParams = new URLSearchParams();
    for (const [key, value] of existingParams) {
      combinedParams.append(key, value);
    }
    for (const [key, value] of searchParams) {
      combinedParams.append(key, value);
    }
    searchParams = combinedParams;
    route = route.substring(0, qIndex);
  }
  return route + '?' + searchParams.toString();
};

export const getRouteWithParams = (route: Routes, params: Record<string, string | number> = {}, searchParams?: URLSearchParams): StaticRoutes => {
  const paramsInPath = route.split('/').filter((path) => path.includes(':'));
  if (paramsInPath.length === 0) {
    return getRouteWithSearchParams(route, searchParams) as StaticRoutes;
  }
  console.log({ route, params, paramsInPath });
  let finalRoute: string = route;
  paramsInPath.forEach((param) => {
    const isOptional = param.includes('?');
    const key = param.substring(1, isOptional ? param.length - 1 : param.length);
    const value = params[key];
    if (isOptional) {
      if (value) {
        finalRoute = finalRoute.replace(param, value.toString());
      } else {
        finalRoute = finalRoute.replace(`/${param}`, '');
      }
    } else {
      if (!value) {
        throw new Error(`Error (getRouteWithParams): Required param '${param}' missing from props.`);
      }
      finalRoute = finalRoute.replace(param, value.toString());
    }
  });
  finalRoute = getRouteWithSearchParams(finalRoute, searchParams);
  console.log({ finalRoute });
  return `${finalRoute}` as StaticRoutes;
};

const redirectWithQP = (route: StaticRoutes) => {
  return redirect(`/${route}${getQueryParamsWithoutConsumables()}`);
};

const requiresAuth = async () => {
  if (!controller.isAuth) {
    return redirectWithQP(getRouteWithParams(Routes.GameFeed));
  }
  return null;
};

const NavigateToFeed = ({ replace }: { replace?: boolean }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const route = getRouteWithParams(Routes.GameFeed);
    const routeQP = `/${route}${getQueryParamsWithoutConsumables()}`;
    navigate(routeQP, { replace });
  }, [navigate, replace]);

  return <div />;
};

export const router = createBrowserRouter([
  {
    path: '/',
    element: <AppWrapper />,
    loader: appLoader,
    children: [
      {
        path: Routes.Account,
        loader: requiresAuth,
        lazy: () => import('./pages/account/Account'),
      },
      {
        path: Routes.GameFeed,
        loader: async ({ params }) => {
          const { gameId } = params;
          if (gameId) {
            await controller.waitForReady;
            await controller.feed.loadTempItem(gameId);
          }
          return gameId || null;
        },
        lazy: () => import('./pages/gameFeed/GameFeed'),
      },
      {
        loader: requiresAuth,
        path: Routes.Create,
        lazy: () => import('./pages/aiAssistant/AiAssistant'),
      },
      {
        path: Routes.Profile,
        loader: async ({ params }) => {
          const { username } = params;
          if (!username && !controller.isAuth) {
            return redirectWithQP(getRouteWithParams(Routes.GameFeed));
          } else if (username) {
            controller.profile.load(username);
            return controller.profile.ready;
          } else {
            controller.profile.load(controller.user.me?.username || 'KantoCerulean');
            return controller.profile.ready;
          }
        },
        lazy: () => import('./pages/profile/Profile'),
      },
      {
        loader: requiresAuth,
        path: Routes.Leaderboard,
        lazy: () => import('./pages/leaderboard/Leaderboard'),
      },
      {
        loader: requiresAuth,
        path: Routes.Deposit,
        lazy: () => import('./pages/onboarding/DepositStep'),
      },
      {
        loader: async ({ params }) => {
          await controller.waitForReady;
          const redirectResponse = await requiresAuth();
          if (redirectResponse) {
            return redirectResponse;
          }

          let username = params.username;

          // only valid path if username starts with @
          if (!username?.startsWith('@')) {
            return redirectWithQP(getRouteWithParams(Routes.MessageRooms));
          }

          username = removeAtUsername(username);

          return {
            username,
          };
        },
        path: Routes.SingleMsgRoom,
        lazy: () => import('./pages/messageRooms/SingleMsgRoom'),
      },
      {
        loader: async () => {
          await controller.waitForReady;
          return await requiresAuth();
        },
        path: Routes.MessageRooms,
        lazy: () => import('./pages/messageRooms/MessageRooms'),
      },
      {
        path: Routes.ToS,
        lazy: () => import('./pages/tos/TermsAndConditions'),
      },
      {
        path: Routes.Privacy,
        lazy: () => import('./pages/privacy/Privacy'),
      },
      {
        path: Routes.Copyright,
        lazy: () => import('./pages/copyright/Copyright'),
      },
      {
        path: Routes.Cookies,
        lazy: () => import('./pages/cookies/Cookies'),
      },
      {
        path: Routes.Inbox,
        loader: requiresAuth,
        lazy: () => import('./pages/inbox/Inbox'),
      },
      {
        path: Routes.GameFeedWithUser,
        loader: async ({ params }) => {
          const { username, gameId } = params;
          // only valid path if the username path starts with @
          if (!username.startsWith('@')) {
            return null;
          }
          if (gameId) {
            controller.startSessionWithGame(Number(gameId));
            await controller.waitForReady;
            // await controller.feed.loadTempItem(gameId);
          }
          return gameId || null;
        },
        lazy: () => import('./pages/gameFeed/GameFeed'),
      },
      {
        path: Routes.Followers,
        loader: async ({ params }) => {
          const { username } = params;
          // only valid path if the username param starts with @
          if (!username?.startsWith('@')) {
            return redirectWithQP(getRouteWithParams(Routes.GameFeed));
          }

          const normalized = removeAtUsername(username);

          if (!normalized) {
            return redirectWithQP(getRouteWithParams(Routes.GameFeed));
          }

          await controller.user.waitForInit;
          // only viewable if logged in
          if (!controller.isAuth) {
            return redirectWithQP(getRouteWithParams(Routes.GameFeed));
          } else {
            return {
              username: normalized,
              tabId: FollowsPageTab.Followers,
            };
          }
        },
        lazy: () => import('./pages/followLists/FollowLists'),
      },
      {
        path: Routes.Following,
        loader: async ({ params }) => {
          const { username } = params;
          // only valid path if the username param starts with @
          if (!username?.startsWith('@')) {
            return redirectWithQP(getRouteWithParams(Routes.GameFeed));
          }

          const normalized = removeAtUsername(username);

          if (!normalized) {
            return redirectWithQP(getRouteWithParams(Routes.GameFeed));
          }

          await controller.user.waitForInit;
          // only viewable if logged in
          if (!controller.isAuth) {
            return redirectWithQP(getRouteWithParams(Routes.GameFeed));
          } else {
            return {
              username: normalized,
              tabId: FollowsPageTab.Following,
            };
          }
        },
        lazy: () => import('./pages/followLists/FollowLists'),
      },
      {
        path: Routes.Holders,
        loader: async ({ params }) => {
          const { username } = params;
          // only valid path if the username param starts with @
          if (!username?.startsWith('@')) {
            return redirectWithQP(getRouteWithParams(Routes.GameFeed));
          }

          const normalized = removeAtUsername(username);

          if (!normalized) {
            return redirectWithQP(getRouteWithParams(Routes.GameFeed));
          }

          await controller.user.waitForInit;
          // only viewable if logged in
          if (!controller.isAuth) {
            return redirectWithQP(getRouteWithParams(Routes.GameFeed));
          } else {
            return {
              username: normalized,
              tabId: FollowsPageTab.Holders,
            };
          }
        },
        lazy: () => import('./pages/followLists/FollowLists'),
      },
      {
        path: Routes.ProfileAt,
        loader: async ({ params }) => {
          const { username } = params;
          console.log({ username });
          // only valid path if username starts with @
          if (!username?.startsWith('@')) {
            return redirectWithQP(getRouteWithParams(Routes.GameFeed));
          }

          const normalized = removeAtUsername(username);
          console.log({ normalized });

          if (!normalized) {
            return redirectWithQP(getRouteWithParams(Routes.GameFeed));
          }

          // @TODO: Why were we waiting for the user to init?
          // await controller.user.waitForInit;
          controller.profile.load(normalized);
          return await controller.profile.ready;
        },
        lazy: () => import('./pages/profile/Profile'),
      },
      {
        // we're doing it this way because we can't use redirect to replace in history instead of push
        element: <NavigateToFeed replace />,
        index: true,
      },
      // 404 - redirect to feed
      {
        loader: async () => redirectWithQP(getRouteWithParams(Routes.GameFeed)),
        path: '*',
      },
    ],
  },
]);
