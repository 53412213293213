import { EventListener } from '../EventListener';

interface SetComponentOpts {
  updateOnSet?: boolean;
}

export class ComponentController extends EventListener {
  protected isVisible = false;

  protected onVisibilityChange?: () => void;

  private componentUpdateListener?: () => void;
  /**
   * @param updateListener () => void
   * @param opts sendUpdateOnSet?: boolean
   * @returns unsetComponent function
   */
  setComponent = (updateListener: () => void, opts?: SetComponentOpts) => {
    if (this.componentUpdateListener) {
      throw new Error(`ComponentController (setComponent): Trying to set more than one component.`);
    }
    this.componentUpdateListener = updateListener;
    if (opts?.updateOnSet) {
      this.updateComponent();
    }
    return () => {
      this.componentUpdateListener = undefined;
    };
  };

  protected updateComponent = () => {
    this.componentUpdateListener && this.componentUpdateListener();
  };

  private setVisibile = (visible: boolean) => {
    this.isVisible = visible;
    this.onVisibilityChange && this.onVisibilityChange();
  };

  onComponentMount = () => {
    this.setVisibile(true);
    return () => {
      this.setVisibile(false);
    };
  };
}
