// @TODO: fix-me (temp)
interface LoggerConfig {
  color?: string;
  enabled?: boolean;
}

export class Logger {
  protected logConfig: LoggerConfig = {
    color: undefined,
    enabled: true,
  };

  protected color?: string;

  private sendLog(type: 'log' | 'warn' | 'error', ...msg: any[]) {
    const { enabled = true, color } = this.logConfig;
    if (!enabled) {
      return;
    }
    if (color) {
      console[type](`%c${this.constructor.name}:`, `color: ${color}`, ...msg);
    } else {
      console[type](`${this.constructor.name}:`, ...msg);
    }
  }

  private isDisabled = (msg: any[]) => {
    const lastItem = msg[msg.length - 1];
    if (lastItem) {
      const isObj = typeof lastItem === 'object' && lastItem.length === undefined;
      if (isObj && lastItem.disabled) {
        return true;
      }
    }
    return false;
  };

  /**
   * @param msg send `{ disabled: true }` as last parameter to disable the log
   * @returns void;
   */
  log(...msg: any[]) {
    if (this.isDisabled(msg)) {
      return;
    }
    this.sendLog('log', ...msg);
  }

  /**
   * @param msg send `{ disabled: true }` as last parameter to disable the log
   * @returns void;
   */
  warn(...msg: any[]) {
    if (this.isDisabled(msg)) {
      return;
    }
    this.sendLog('warn', ...msg);
  }

  /**
   * @param msg send `{ disabled: true }` as last parameter to disable the log
   * @returns void;
   */
  error(...msg: any[]) {
    if (this.isDisabled(msg)) {
      return;
    }
    this.sendLog('error', ...msg);
  }

  throw(msg: string) {
    throw new Error(`Error (${this.constructor.name}): ${msg}`);
  }
}

export class BaseClass extends Logger {}
