import { TwtAuthVersion } from '@storyverseco/svs-navbar';
import { QueryParams, initialQueryParams } from '../../config/consts';
import { AppController } from '../Controller';
import { navbar } from '../navbarSuite';
import { api } from '../apis';
import { WelcomeGemState } from '@storyverseco/svs-types';
import { UserController } from './auth/UserController';
import { Logger } from 'lib/BaseClass';
import { Modals } from 'features/Modals';
import { getAccessToken } from '@privy-io/react-auth';

interface TwitterStatusError {
  message: string;
  publicMessage: string | null;
}

/**
 * most of these status keys come from the twitterCallback in pipeline.
 */
const twitterStatusErrorMap: Record<string, { message: string; publicMessage: string | null }> = {
  nokey: {
    message: 'twitter secure key missing',
    publicMessage: null, // treat as no error
  },
  authError: {
    message: 'Unknown twitter auth error',
    publicMessage: 'Unknown authorize error. Please try again.',
  },
  denied: {
    message: 'User denied authorization',
    publicMessage: 'Authorization denied. Please try again.',
  },
  linkedtoanother: {
    message: 'Twitter account already linked to another profile',
    publicMessage: 'X account already linked to another profile. Please use another X account.',
  },
  noauth: {
    message: 'missing auth data',
    publicMessage: 'Something went wrong (no auth data). Please try again.',
  },
  invalidauthformat: {
    message: 'invalid auth data format',
    publicMessage: 'Something went wrong (invalid auth format). Please try again.',
  },
  nouserid: {
    message: 'missing user id from auth data',
    publicMessage: 'Something went wrong (missing data). Please try again.',
  },
  nousername: {
    message: 'missing username from auth data',
    publicMessage: 'Something went wrong (missing data). Please try again.',
  },
  nobearertoken: {
    message: 'missing bearer token from auth data',
    publicMessage: 'Something went wrong (missing data). Please try again.',
  },
  norefreshtoken: {
    message: 'missing refresh token from auth data',
    publicMessage: 'Something went wrong (missing data). Please try again.',
  },
  invalidkey: {
    message: 'invalid twitter secure key',
    publicMessage: 'Something went wrong (expired key). Please try again.',
  },
  notfound: {
    message: 'user not found from secure key',
    publicMessage: 'Something went wrong (not found). Please try again.',
  },
  unknownerror: {
    message: 'unknown error',
    publicMessage: 'Something went wrong (unknown). Please try again.',
  },
  success: {
    message: '',
    publicMessage: null,
  },
};

const inviteCodeRegex = /gz-[a-zA-Z0-9]{8}/;

export const sanitizeInviteCode = (code: string) => {
  // only return the first match
  const matches = code.match(inviteCodeRegex);
  if (matches) {
    return matches[0];
  }

  // if no match, naively trim and get the 11 characters
  return code.trim().substring(0, 11);
};

enum OnboardingStep {
  Twitter = 'OnboardingStep/Twitter',
  Invite = 'OnboardingStep/Invite',
  Follow = 'OnboardingStep/Follow',
  FreeGem = 'OnboardingStep/FreeGem',
  Complete = 'OnboardingStep/Complete',
}

const stepModals = {
  [OnboardingStep.Twitter]: Modals.ConnectX,
  [OnboardingStep.Invite]: Modals.InviteUser,
  [OnboardingStep.Follow]: Modals.FollowUser,
  [OnboardingStep.FreeGem]: Modals.FreeGem,
};

export interface FollowSuggestion {
  username: string;
  image: string;
  followerCount: number;
}

// Grab 25 users as options to follow
export const MIN_FOLLOW_COUNT = 5;
export class OnboardingHandler extends Logger {
  private _isOnboarding = false;
  get isOnboarding() {
    return this._isOnboarding;
  }

  // use to prevent double clicks/requests
  private isLoading = false;

  private folllowRecommendations: FollowSuggestion[] = [];

  get usersToFollow() {
    return this.folllowRecommendations;
  }

  private triedToClaim = false;
  private claimFailed = false;

  private freeGemImage: string;

  private _twitterStatusError: TwitterStatusError = undefined;

  get twitterStatusError(): TwitterStatusError {
    return this._twitterStatusError;
  }

  constructor(private app: AppController, private user: UserController) {
    super();
    this.initTwitterStatus().catch((e) => {
      console.error('initTwitterStatus error:', e);
    });
  }

  private initTwitterStatus = async (): Promise<void> => {
    const statusKey = initialQueryParams.get(QueryParams.TwitterStatusParam);
    if (!statusKey) {
      return;
    }
    if (statusKey === 'success') {
      return;
    }

    if (statusKey === 'authError') {
      const authError = await navbar.api.twitterService.auth.erroredOnRedirect();
      if (authError) {
        // user denied: "Unexpected Twitter auth error: 2"
        if (authError.message === 'Unexpected Twitter auth error: 2') {
          this._twitterStatusError = twitterStatusErrorMap.denied;
        } else {
          this._twitterStatusError = {
            message: `Error from Twitter auth: ${authError.message}`,
            publicMessage: authError.message,
          };
        }
      } else {
        this._twitterStatusError = twitterStatusErrorMap.authError;
      }
    } else {
      this._twitterStatusError = twitterStatusErrorMap[statusKey] ?? twitterStatusErrorMap.unknown;
    }
    console.error('Twitter post redirect error:', this.twitterStatusError?.message);
  };

  private getOnboardingStep = () => {
    const { me } = this.user;
    if (!Boolean(me.twitter?.handle)) {
      return OnboardingStep.Twitter;
    }
    if (!Boolean(me.myConsumedInviteCode)) {
      return OnboardingStep.Invite;
    }
    if (me.attributes.followingCount < MIN_FOLLOW_COUNT) {
      return OnboardingStep.Follow;
    }
    if (me.attributes.welcomeGemState !== WelcomeGemState.Confirmed) {
      // Only show the modal once, in case an error happened
      if (this.triedToClaim) {
        return OnboardingStep.Complete;
      }
      return OnboardingStep.FreeGem;
    }
    return OnboardingStep.Complete;
  };

  private fetchFollowRecommendations = async () => {
    const response = await api.user.get.usersToFollow(this.user.me.id);

    this.folllowRecommendations = response.recomms.map((user) => ({
      username: user.username,
      image: user.twitter_picture,
      followerCount: Math.round(Math.random() * 1000),
    }));
  };

  private preClaimFreeGem = (gameId: number) => {
    this.app.feed.loadAndGetItemById(gameId).then((game) => {
      this.freeGemImage = game.gem.img;
    });

    this.app.gemzOps
      .buyGem({
        offChainGameId: Number(gameId),
        creatorAddress: this.user.me.walletAddress,
        free: true,
        gemType: 'coin',
        offeredPrice: 0,
      })
      .then(async () => {
        await api.user.claim.start();
      })
      .catch(async () => {
        this.claimFailed = true;
        await api.user.claim.failure();
      });
  };

  start = async () => {
    // already completed
    if (this.user.me.attributes.welcomeGemState === WelcomeGemState.Confirmed) {
      return;
    }
    await this.user.fetchFromCachedToken();
    const step = this.getOnboardingStep();
    this.log('start', { step, me: this.user.me });
    // Fetch async because we pre-fetching
    if (step === OnboardingStep.Invite) {
      this.fetchFollowRecommendations();
    }
    // fetch sync if we are on the step we using the data
    if (step === OnboardingStep.Follow) {
      await this.fetchFollowRecommendations();
    }
    if (step !== OnboardingStep.Complete) {
      this._isOnboarding = true;
      this.showNextModal(step);
    }
  };

  private showNextModal = (step: OnboardingStep) => {
    const modalId = stepModals[step];
    if (modalId) {
      this.app.modals.open(modalId, {
        disableBackdrop: true,
        onClose: this.onCurrentStepComplete,
        useConfettiEffect: step === OnboardingStep.FreeGem,
        data: { gemImage: this.freeGemImage },
      });
    } else {
      if (step == OnboardingStep.Complete) {
        if (this.app.isWebMobile) {
          this.app.modals.open(Modals.PWA, { disableBackdrop: true });
        }
      }
    }
  };

  private onCurrentStepComplete = async () => {
    await this.updateUser();
    const nextStep = this.getOnboardingStep();
    this.log('onCurrentStepComplete', { nextStep });
    this.showNextModal(nextStep);
  };

  private updateUser = async () => {
    await this.app.user.refetch();
    if (
      this.user.me.attributes.freeGemOffChainGameId &&
      (this.user.me.attributes.welcomeGemState === WelcomeGemState.Unset || this.user.me.attributes.welcomeGemState === WelcomeGemState.Failed)
    ) {
      try {
        this.preClaimFreeGem(this.user.me.attributes.freeGemOffChainGameId);
      } catch (e) {
        this.error(e);
      }
    }
  };

  // Twitter Step
  public connectWithX = async () => {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    try {
      // Make sure we store current privy token so we can fetch user asap when returning from twitter login
      await this.user.setPrivyToken(await getAccessToken());
      const secureKey = await api.onboarding.getTwitterSecureKey();
      const baseRedirectUrl = this.app.config.globals.urls.publish;
      const url = new URL(`${baseRedirectUrl}/gemz/secure/twitter/callback`);
      url.searchParams.append(QueryParams.TwitterSecureParam, secureKey);
      const origin = window.location.origin;
      url.searchParams.append('origin', origin);
      await navbar.api.twitterService.auth.logIn({ authVersion: TwtAuthVersion.V2, redirectUrl: url.toString(), force: true, newTab: false });
    } catch (e) {
      this.error(e);
      throw e;
    } finally {
      this.isLoading = false;
    }
  };

  // Invite Step
  public submitInviteCode = async (inviteCode: string) => {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    try {
      const sanitizedCode = sanitizeInviteCode(inviteCode);
      const response = await api.onboarding.invite({ code: sanitizedCode });
      if (response) {
        await this.app.user.refetch();
        // Just close, opening the next one is auto handled
        this.app.modals.close();
      }
    } catch (e: any) {
      let errorMessage = '';
      try {
        const errorData = JSON.parse(e.message);
        const errorDataMessage = errorData.error.toLowerCase();
        if (errorDataMessage.includes('code could not be found')) {
          errorMessage = 'Code could not be found.';
        } else if (errorDataMessage.includes('already been consumed')) {
          errorMessage = 'Code has already been used.';
        } else {
          errorMessage = errorData.error;
        }
      } catch (e) {
        // do nothing
      }
      throw new Error(errorMessage || e.message);
    } finally {
      this.isLoading = false;
    }
  };

  // Follow sep
  public confirmFollowingUsers = async () => {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    await this.app.user.refetch();
    // Just close, opening the next one is auto handled
    this.app.modals.close();
    this.isLoading = false;
  };

  // Free gem step
  public claimFreeGem = async () => {
    this.triedToClaim = true;
    if (this.claimFailed) {
      this.app.modals.open(Modals.Error, {
        title: 'Oops!',
        message: `Something went wrong when claiming\nyour free gem. Try again later.`,
      });
    } else {
      await api.user.claim.success(Number(this.user.me.attributes.freeGemOffChainGameId));
      setTimeout(async () => {
        await this.app.user.refetch();
        this.app.modals.close();
      });
    }
  };

  public clearTwitterStatusError = () => {
    this._twitterStatusError = undefined;
  };
}
