import { Fingerprint, GemzUser, MyGemzUser, Optional, WalletAddress } from '@storyverseco/svs-types';
import { activityServiceApi, feedServiceApi, getEndpoint, pipelineApi } from './base';
import { Endpoints, FeedServiceEndpoints } from './endpoints';
import { User as PrivyUser } from '@privy-io/react-auth';
import { UserLikedGame } from 'pages/profile/profileTypes';

const createUser = (gemzUser: PrivyUser, smartAddress: WalletAddress) =>
  pipelineApi.post(Endpoints.USER_CREATE, {
    data: { gemzUser, smartAddress: smartAddress },
  });

// @TODO: Type this properly
const updateUser = (gemzUser: MyGemzUser) =>
  pipelineApi.post(Endpoints.USER_UPDATE, {
    data: {
      gemzUser: {
        ...gemzUser,
        username: gemzUser.twitter.handle,
      },
    },
  });

const updateUserTwitterSecure = (secureTwitterKey: string, twitter: MyGemzUser['twitter']) =>
  pipelineApi.post(
    Endpoints.USER_UPDATE,
    {
      data: {
        gemzUser: {
          twitter,
        },
      },
      secureTwitterKey,
    },
    true,
    'foo',
  );

interface GetUser {
  code?: string;
  walletAddress?: WalletAddress;
}
const getUserByCode = async ({ code }: GetUser): Promise<GemzUser> => {
  if (!code) {
    throw new Error(`Error (getUserByCode): Cannot get user without 'code'.`);
  }

  try {
    return pipelineApi.get(getEndpoint(Endpoints.USER_GET_CODE, { code }), false);
  } catch (e) {
    throw e;
  }
};

const getMe = async (accessToken?: string): Promise<MyGemzUser> => {
  try {
    return pipelineApi.get(Endpoints.USER_ME, true, false, accessToken);
  } catch (e) {
    throw e;
  }
};

const onboardingComplete = async (): Promise<GemzUser> => {
  return pipelineApi.get(Endpoints.USER_ONBOARDING_COMPLETE);
};

const getUserByWallet = async ({ walletAddress }: GetUser): Promise<GemzUser> => {
  if (!walletAddress) {
    throw new Error(`Error (getUserByWallet): Cannot get user without 'walletAddress'.`);
  }

  try {
    return pipelineApi.get(getEndpoint(Endpoints.USER_GET_BY_WALLET, { walletAddress }));
  } catch (e) {
    throw e;
  }
};

const getUserByUsername = async (username: string): Promise<GemzUser> => {
  try {
    return pipelineApi.get(getEndpoint(Endpoints.USER_GET_BY_USERNAME, { username }), false);
  } catch (e) {
    throw e;
  }
};

// @TODO: Type this properly
const getUserGames = async (ownerAddress: string) => {
  try {
    return pipelineApi.get(getEndpoint(Endpoints.USER_GAMES, { ownerAddress }));
  } catch (e) {
    throw e;
  }
};
// @TODO: Type this properly
const getUserGamesByUsername = async (username: string) => {
  try {
    return pipelineApi.get(getEndpoint(Endpoints.USER_GAMES_BY_USERNAME, { username }), false);
  } catch (e) {
    throw e;
  }
};
// @TODO: Type this properly
const getUserHolders = async (ownerAddress: string) => {
  try {
    return pipelineApi.get(getEndpoint(Endpoints.USER_HOLDERS, { ownerAddress }));
  } catch (e) {
    throw e;
  }
};
// @TODO: Type this properly
const getUserHoldersByUsername = async (username: string, isAuth: boolean) => {
  try {
    return pipelineApi.get(getEndpoint(Endpoints.USER_HOLDERS_BY_USERNAME, { username }), isAuth);
  } catch (e) {
    throw e;
  }
};
// @TODO: Type this properly
const getUserHoldings = async (ownerAddress: string) => {
  try {
    return pipelineApi.get(getEndpoint(Endpoints.USER_HOLDINGS, { ownerAddress }));
  } catch (e) {
    throw e;
  }
};
// @TODO: Type this properly
const getUserHoldingsByUsername = async (username: string, isAuth: boolean) => {
  try {
    return pipelineApi.get(getEndpoint(Endpoints.USER_HOLDINGS_BY_USERNAME, { username }), isAuth);
  } catch (e) {
    throw e;
  }
};
// @TODO: Type this properly
const getUserPortfolio = async (ownerAddress: string) => {
  try {
    return pipelineApi.get(getEndpoint(Endpoints.USER_PORTFOLIO, { ownerAddress }));
  } catch (e) {
    throw e;
  }
};

const getUserLikedGames = async (ownerAddress: string, offset?: number): Promise<{ items: UserLikedGame[]; count: number }> => {
  let endpoint = getEndpoint(Endpoints.USER_LIKED_GAMES, { ownerAddress });
  if (offset) {
    endpoint = `${endpoint}?offset=${offset}` as Endpoints;
  }
  try {
    return pipelineApi.get(endpoint);
  } catch (e) {
    throw e;
  }
};

const getUserLikedGamesByUsername = async (username: string, offset?: number): Promise<{ items: UserLikedGame[]; count: number }> => {
  let endpoint = getEndpoint(Endpoints.USER_LIKED_GAMES_BY_USERNAME, { username });
  if (offset) {
    endpoint = `${endpoint}?offset=${offset}` as Endpoints;
  }
  try {
    return pipelineApi.get(endpoint, false);
  } catch (e) {
    throw e;
  }
};

// @TODO: Type this properly
const getUserRewards = async (walletAddress: string) => {
  try {
    return pipelineApi.get(getEndpoint(Endpoints.USER_REWARDS, { walletAddress }));
  } catch (e) {
    throw e;
  }
};
// @TODO: Type this properly
const getUserMetadata = async (userId: string) => {
  try {
    return pipelineApi.get(getEndpoint(Endpoints.USER_METADATA, { userId }));
  } catch (e) {
    throw e;
  }
};

const getAllActivity = async (userId: string) => {
  try {
    return activityServiceApi.get(getEndpoint(Endpoints.USER_ACTIVITY, { userId }));
  } catch (e) {
    throw e;
  }
};

// @TODO: Type this properly
const addActivity = async (notification: any) => {
  try {
    return activityServiceApi.post(Endpoints.USER_NOTIFY, {
      data: { ...notification },
    });
  } catch (e) {
    throw e;
  }
};

// @TODO: Type this properly
const markActivitiesAsRead = async (userId: string, activityIds: string[]) => {
  try {
    return activityServiceApi.post(getEndpoint(Endpoints.USER_ACTIVITY, { userId }), { data: activityIds });
  } catch (e) {
    throw e;
  }
};

const claimFreeGem = async (): Promise<any> => pipelineApi.get(Endpoints.USER_CLAIM_GEM);

const claimFreeGemConfirm = async (gameId: number) => pipelineApi.get(getEndpoint(Endpoints.USER_CLAIM_GEM_CONFIRM, { gameId: gameId.toString() }));

const claimFreeGemFailure = async () => pipelineApi.get(Endpoints.USER_CLAIM_GEM_FAILURE);
// @TODO: Type this properly
const onPWAInstalled = async () => await pipelineApi.get(Endpoints.USER_PWA_INSTALLED);

const getFingerprint = async (fingerprintId: number, userId?: number): Promise<Optional<Fingerprint>> =>
  pipelineApi.get(getEndpoint(Endpoints.USER_FINGERPRINT_GET, { fingerprintId, userId }), false);

const createFingerprint = async (fingerprintId: number, gameId: number) => {
  try {
    await pipelineApi.get(getEndpoint(Endpoints.USER_FINGERPRINT_CREATE, { fingerprintId, gameId }), false);
  } catch (e) {
    // Fail silently, most likely because duplicate when comming from web mobile into pwa
  }
};

const assignUserToFingerprint = async (fingerprintId: number) => {
  await pipelineApi.get(getEndpoint(Endpoints.USER_FINGERPRINT_ASSIGN, { fingerprintId }));
};

const mergeUserWithFingerprint = async (fingerprintId: number, userId: number) => {
  await feedServiceApi.post(FeedServiceEndpoints.MERGE_USER, { targetUserId: fingerprintId, sourceUserId: userId }, true);
};

const getUserToFollowRecommendations = async (userId: number, numOfRecomms = 25) => {
  return feedServiceApi.post(FeedServiceEndpoints.USER_LIST, { userId, count: numOfRecomms }, true);
  // return feedServiceApi.post(FeedServiceEndpoints.RECOMMEND_USERS_TO_FOLLOW, { userId, count: numOfRecomms }, true);
};

export const user = {
  create: createUser,
  update: updateUser,
  get: {
    byCode: getUserByCode,
    me: getMe,
    byWallet: getUserByWallet,
    usersToFollow: getUserToFollowRecommendations,
  },
  onboardingComplete,
  updateUserTwitterSecure,
  getUserGames,
  getUserGamesByUsername,
  getUserHolders,
  getUserHoldersByUsername,
  getUserHoldings,
  getUserHoldingsByUsername,
  getUserPortfolio,
  getUserLikedGames,
  getUserLikedGamesByUsername,
  getUserRewards,
  getUserByUsername,
  claim: {
    start: claimFreeGem,
    success: claimFreeGemConfirm,
    failure: claimFreeGemFailure,
  },
  claimFreeGem,
  onPWAInstalled,
  getUserMetadata,
  addActivity,
  getAllActivity,
  markActivitiesAsRead,
  fingerprint: {
    get: getFingerprint,
    create: createFingerprint,
    assign: assignUserToFingerprint,
  },
  mergeUserWithFingerprint,
};
