import { images } from 'assets/assets';
import { useNavigation } from 'hooks/useNavigation';
import { TopBarCurrency } from './TopBarCurrency';
import { Routes } from 'router';
import { useReRender } from 'hooks/useReRender';
import { controller } from 'lib/Controller';

export function TopBarPointCurrency() {
  const { navigate } = useNavigation();

  // todo(Cai): visible is always false for points. added element to controller etc, so not sure why?.
  const { visible, setComponent, state } = controller.ui.elements.TopBarPointCurrency;

  // todo(BE): how do we retrieve points earned ??? hardcoding them for now
  // const { coinBalance = 0 } = controller.user.me || {};
  const pointsEarned = 128000;

  useReRender({ id: 'TopBarPointCurrency', listener: setComponent });

  const onClick = () => {
    navigate(Routes.Account);
  };

  // todo: restore show={visibe} and figure out how to get and display the number of points that the user earned between sessions

  return (
    <TopBarCurrency type="point" onClick={onClick} show={true} icon={images.iconPoints} fakeBalance={pointsEarned} userBalance={Number(pointsEarned)} />
    // <TopBarCurrency type="coin" onClick={onClick} show={true} icon={images.iconCoin} fakeBalance={state.displayBalance} userBalance={Number(coinBalance)} />
  );
}
