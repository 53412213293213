import { NavigateOptions, matchRoutes, useLocation, useNavigate } from 'react-router-dom';
import { Routes, StaticRoutes, getRouteWithParams, getRouteWithSearchParams, router } from '../router';
import { useCallback, useEffect } from 'react';
import { controller } from 'lib/Controller';
import { QueryParams, persistedQueryParams } from '../config/consts';

const useCurrentRoute = () => {
  const location = useLocation();

  const match = matchRoutes(router.routes, location);

  if (match) {
    const { route } = match[match.length - 1];
    return route.path as Routes;
  }

  console.error('Should never not have a match!!!', { location, router, match });

  return undefined;
};

interface FeedItemNavOpts {
  gameId: number;
}

interface NavProps {
  attachController?: boolean;
  backFallbackRoute?: StaticRoutes;
  backFallbackOptions?: NavigateOptions;
}

export const useNavigation = (props?: NavProps) => {
  const route = useCurrentRoute();

  const routerNavigate = useNavigate();
  const routerLocation = useLocation();

  const navigate = useCallback(
    (route: StaticRoutes, options?: NavigateOptions) => {
      const currentSearchParams = new URLSearchParams(window.location.search);

      // with persisted query params
      for (const [key, value] of currentSearchParams) {
        if (!persistedQueryParams.includes(key)) {
          currentSearchParams.delete(key, value);
        }
      }

      // combine current query params with route query params
      route = getRouteWithSearchParams(route, currentSearchParams) as StaticRoutes;

      routerNavigate(`/${route}`, options);
    },
    [routerNavigate],
  );

  const navigateBack = useCallback(() => {
    if (routerLocation.key !== 'default') {
      routerNavigate(-1);
    } else {
      navigate(props?.backFallbackRoute ?? getRouteWithParams(Routes.GameFeed), props?.backFallbackOptions);
    }
  }, [
    routerNavigate,
    routerLocation,
    navigate,
    props?.backFallbackRoute,
    props?.backFallbackOptions,
    props?.backFallbackOptions?.state,
    props?.backFallbackOptions?.preventScrollReset,
    props?.backFallbackOptions?.relative,
    props?.backFallbackOptions?.replace,
  ]); // depending on the option properties rather than the option object itself since the object ref can change per render

  const navigateToFeedItem = useCallback(
    ({ gameId }: FeedItemNavOpts) => {
      const r = getRouteWithParams(Routes.GameFeed, { gameId });
      console.warn('navigateToFeedItem', { r, gameId });
      navigate(r);
    },
    [navigate],
  );

  useEffect(() => {
    if (props?.attachController) {
      controller.setRoute(route as StaticRoutes);
      return controller.addEventListener('on_navigate', ({ route }) => {
        navigate(route);
      });
    }
  }, [props?.attachController, route]);

  return {
    route,
    navigate,
    back: navigateBack,
    navigateToFeedItem,
  };
};
