import { ConnectedWallet, UnsignedTransactionRequest } from '@privy-io/react-auth';
import { WalletAddress } from '@storyverseco/svs-types';
import { consts } from 'config/consts';
import { env } from 'config/env';
import { api } from 'lib/apis';
import { createPublicClient, http, parseEther } from 'viem';

const l1PubClient = createPublicClient({
  // @ts-ignore
  chain: env.l1Chain,
  transport: http(),
});

const getL1Balance = async (address: WalletAddress) => {
  return l1PubClient.getBalance({ address });
};

export const l1Client = {
  getBalance: getL1Balance,
};

const l2PubClient = createPublicClient({
  chain: env.l2Chain,
  transport: http(),
});

const getL2Balance = async (address: WalletAddress) => {
  return l2PubClient.getBalance({ address });
};

const getL2GasPrice = async () => {
  return l2PubClient.getGasPrice();
};

export const l2Client = {
  getBalance: getL2Balance,
  getGasPrice: getL2GasPrice,
};

interface DepositOpts {
  receiverAddress: WalletAddress;
  amount: string;
}

export const depositETH = async (wallet: ConnectedWallet, { receiverAddress, amount }: DepositOpts) => {
  if (!wallet.address) {
    return;
  }

  // Make sure we are on l1 to deposit eth
  if (wallet.chainId !== consts.chainIds.sepolia.strId) {
    try {
      await wallet.switchChain(consts.chainIds.sepolia.numId);
    } catch {
      return;
    }
  }

  const tx = await api.multibaas.getDepositETHTransaction({
    fromAddress: wallet.address as WalletAddress,
    toAddress: receiverAddress,
    amount: parseEther(amount),
  });

  tx.chainId = consts.chainIds.sepolia.numId;

  const bridgeResponse = await sendBridgeTransaction({ wallet, tx });

  const depositHash = bridgeResponse.hash as WalletAddress;

  // @TODO: fix-me (re-implement this)
  // localCache.set('currentDepositTxHash', depositHash);

  // Handle transaction success/error async
  waitForDepositTx(depositHash, receiverAddress);

  return bridgeResponse;
};

interface SendTransactionOpts {
  wallet: ConnectedWallet;
  tx: UnsignedTransactionRequest;
}
// Handle L1 transactions
const sendBridgeTransaction = async ({ wallet, tx }: SendTransactionOpts) => {
  if (!wallet) {
    return;
  }

  const provider = await wallet.getEthersProvider();
  const signer = await provider?.getSigner();
  const response = await signer.sendTransaction(tx);
  return response;
};

const waitForDepositTx = (hash: WalletAddress, receiverAddress: WalletAddress, retries = 0) => {
  return l1PubClient
    .waitForTransactionReceipt({
      hash,
      timeout: 1000 * 90,
      confirmations: 1,
    })
    .then(() => {
      // localCache.set('currentDepositTxHash', undefined);
    })
    .catch(async (error) => {
      console.error(error);
      if (retries >= 3) {
        // localCache.set('currentDepositTxHash', undefined);
        // Send an alert that we have errored so we can handle anywhere
        // dispatch(
        //   onDepositFail({
        //     error,
        //     receiverAddress,
        //     navigate,
        //   }),
        // );
      } else {
        return waitForDepositTx(hash, receiverAddress, ++retries);
      }
    });
};
