import { CraftRecipe } from '@storyverseco/svs-types';
import { controller } from 'lib/Controller';
import { Game } from 'lib/Game';
import { api } from 'lib/apis';

export type RawMissionData = {
  gameId: number;
  recipe?: CraftRecipe;
};

type RecipeUIData = {
  game: Game;
  gameId: number;
  amount: number;
  availableAmount: number;
  progress: number;
};

export class CraftMission {
  id: number;
  private rawData: RawMissionData;
  recipeUIData: RecipeUIData[];
  uiData: any;
  constructor(data: RawMissionData, silently = false) {
    this.id = data.gameId;
    this.rawData = data;

    // create processed data
    if (data.recipe) {
      this.updateRecipeUI(data.recipe);
    } else {
      this.recipeUIData = [];
    }

    this.uiData = {
      game: {} as Game,
      gameId: this.id,
      recipeData: this.recipeUIData,
      progress: 0,
    };

    if (!silently) {
      this.preloadRecipeComponent();
    }
  }

  private updateRecipeUI(data: CraftRecipe) {
    this.recipeUIData = data.ingredients.map((ingredient) => {
      return {
        ...ingredient,
        availableAmount: 0,
        game: null,
        progress: 0,
      } as RecipeUIData;
    });
  }

  async preloadRecipeComponent() {
    // update main data component;
    const mainGameData = await controller.feed.loadAndGetItemById(this.id);
    this.uiData.game = mainGameData;

    // load recipe if it's not loaded
    if (!this.rawData.recipe) {
      const recipe = await api.craft.getRecipe(this.id);
      this.rawData.recipe = recipe;
      this.updateRecipeUI(recipe);
      this.setProgress(controller.user.me.holdings);
    }
    // preload all in bg
    this.recipeUIData.forEach(async (recipeItem) => {
      const gameData = await controller.feed.loadAndGetItemById(recipeItem.gameId);
      recipeItem.game = gameData;
    });
    this.setProgress(controller.user.me.holdings);
  }

  setProgress(holdings: any) {
    this.recipeUIData = this.recipeUIData.map((recipeItem) => {
      const availableAmount = holdings.find((item) => item.offChainGameId === recipeItem.gameId)?.count || 0;
      recipeItem.availableAmount = availableAmount;
      recipeItem.progress = Math.min(100, (availableAmount / recipeItem.amount) * 100);
      return recipeItem;
    });

    this.uiData.progress = this.recipeUIData.reduce((acc, item) => acc + item.progress, 0) / this.recipeUIData.length;
  }
}
