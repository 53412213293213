import { GemType, Nullable } from '@storyverseco/svs-types';

export interface IpItemChoice {
  id: string;
  ip: string;
}

export type GemImageChoice = string;

export type CurrencyChoice = { currencyName: string; currencyType: string };

export type GemItem = {
  offchainGameId: number;
  gemName: string;
  gemIcon: string;
  count: number;
  ip: string;
  price: number;
  currency: GemType;
};

export type AssistantChoice = IpItemChoice | GemImageChoice | CurrencyChoice | GemItem;

export type AiChatSelection = string | Partial<AssistantChoice>;

// types for expanded or minimized mode
export type AiChatMode = 'chat' | 'preview';

// types we expect from game's aiAisstant events
const aiFeedbackTypeArr = [
  'characterSuggestion',
  'locationSuggestion',
  'craftIngredients',
  'itemCurrencies',
  'ipList',
  'gemPreviews',
  'gemProfile',
  'text',
  'user',
  'loading',
  'error',
  'sessionDump',
  'gameUpdate',
  'publishSuccess',
] as const;

// types to be used while generating chat entries
export const chatInputTypeArr = [...aiFeedbackTypeArr, 'user'] as const;
export type ChatInputType = (typeof aiFeedbackTypeArr)[number];

export interface AiChatEntry {
  timestamp?: number;
  requestUid: Nullable<number>;
  type: ChatInputType;
  result?: any;
}

export type SuggestionFn = (suggestionType: ChatInputType, selection: AiChatSelection) => void;
