import React, { useEffect, useMemo, useRef, useState } from 'react';
import './HostedApp.css';
import { navbar } from '../../lib/navbarSuite';
import { controller } from '../../lib/Controller';
// import { Spinner } from 'components/spinner/Spinner';
// import { Web3Unavailable } from 'components/web3-unavailable/Web3Unavailable';

const iframeAllow = 'clipboard-read; clipboard-write';

export type HostedAppProps = {
  src: string;
  noBridge?: boolean;
  requiresWeb3?: boolean;
} & React.ComponentPropsWithoutRef<'iframe'>;

export function HostedApp(props: HostedAppProps): JSX.Element {
  const { src, noBridge, requiresWeb3, ...iframeProps } = props;
  // const [iframe, setIframe] = useState<HTMLIFrameElement>(null);

  const iframe = useRef<HTMLIFrameElement>(null);

  const [web3Capable, setWeb3Capable] = useState<boolean | undefined>(requiresWeb3 ? undefined : true);

  useEffect(() => {
    if (!iframe?.current) {
      return;
    }

    navbar.connect(iframe?.current).then(() => {
      console.log('navbar connected');
      controller.viewer.onNavbarReady();
    });

    return () => {
      navbar.disconnect();
    };
  }, [iframe?.current]);

  useEffect(() => {
    if (requiresWeb3) {
      navbar.isWeb3Capable().then((capable: boolean) => {
        setWeb3Capable(capable);
      });
    }
  }, [requiresWeb3]);

  useEffect(() => {
    controller.viewer.loadViewer();
  }, []);
  const actualSrc = useMemo(() => {
    const urlObj = new URL(src);
    urlObj.searchParams.append('v', Date.now().toString());
    return urlObj.toString();
  }, [src]);

  if (web3Capable === undefined) {
    return <></>; //<Spinner />;
  } else if (web3Capable) {
    return <iframe {...iframeProps} src={actualSrc} className={'hosted-app'} allow={iframeAllow} ref={iframe} />;
  } else {
    return <></>; //<Web3Unavailable />;
  }
}
