export enum Endpoints {
  USER_ME = 'gemz/user/me',
  USER_CREATE = 'gemz/user/create',
  USER_UPDATE = 'gemz/user/update',
  USER_GET_CODE = 'gemz/user/code/:code',
  USER_ONBOARDING_COMPLETE = 'gemz/user/onboardingComplete',
  INVITE_CODE = 'gemz/invite/:code/consume',
  DEPOSIT = 'gemz/deposit',
  USER_GAMES = 'gemz/profile/games/:ownerAddress',
  USER_GAMES_BY_USERNAME = 'gemz/profile/gamesByUsername/:username',
  USER_HOLDERS = 'gemz/profile/holders/:ownerAddress',
  USER_HOLDERS_BY_USERNAME = 'gemz/profile/holdersByUsername/:username',
  USER_HOLDINGS = 'gemz/profile/holdings/:ownerAddress',
  USER_HOLDINGS_BY_USERNAME = 'gemz/profile/holdingsByUsername/:username',
  USER_PORTFOLIO = 'gemz/profile/portfolio/:ownerAddress',
  USER_METADATA = 'gemz/profile/metadata/:userId',
  USER_LIKED_GAMES = 'gemz/profile/likedGames/:ownerAddress',
  USER_LIKED_GAMES_BY_USERNAME = 'gemz/profile/likedGamesByUsername/:username',
  USER_REWARDS = 'gemz/rewards/:walletAddress/',
  OWNED_GAMES_ACTIVITY = 'gemz/activity/owned/:ownerAddress',
  CREATED_GAMES_ACTIVITY = 'gemz/activity/created/:ownerAddress',
  USER_GET_BY_WALLET = 'gemz/user/:walletAddress',
  USER_PWA_INSTALLED = 'gemz/user/install/pwa',
  USER_GET_BY_USERNAME = 'gemz/user/info/:username',
  USER_NOTIFY = 'gemz/activity',
  USER_ACTIVITY = 'gemz/activity/:userId',

  USER_CLAIM_GEM = 'gemz/user/claim/start',
  USER_CLAIM_GEM_CONFIRM = 'gemz/user/claim/confirm/:gameId',
  USER_CLAIM_GEM_FAILURE = 'gemz/user/claim/failure',

  USER_FINGERPRINT_GET = 'gemz/user/fp/:fingerprintId/:userId',
  USER_FINGERPRINT_CREATE = 'gemz/user/fp/create/:fingerprintId/:gameId',
  USER_FINGERPRINT_ASSIGN = 'gemz/user/fp/assign/:fingerprintId',

  CREATE_GAME_SIGN = 'gemz/getGameCreationSignature',
  CREATE_GAME_CONFIRM = 'gemz/confirmCreationMint',
  GAME_CREATE_OFFCHAIN = 'gemz/createGameOffChain',

  GEM_BUY_SIGN = 'gemz/getGemzCreationSignature',
  GEM_BUY_CONFIRM = 'gemz/confirmBuyGem',

  COIN_PRICE_BUY_GET = 'gemz/coin/getBuyPrice',
  COIN_BUY = 'gemz/coin/buyGemz',
  COIN_PRICE_SELL_GET = 'gemz/coin/getSellPrice',
  COIN_SELL = 'gemz/coin/sellGemz',
  COIN_BALANCE_GET = 'gemz/coin/balance',
  COIN_REWARDS = 'gemz/coin/rewards',

  TWITTER_SECURE = 'gemz/secure/twitter/update',

  GAME_GET_EXTRA_INFO = 'gemz/game/:offChainGameId/extraInfo',
  GAME_GET_OUTCOME = 'gemz/game/outcome',
  GAME_GET_PUB = 'gemz/game/:pubGameId',
  GAME_WEB_SESSION_LINK = 'gemz/game/:gameId/link/:privyId',
  GAME_WEB_SESSION_GET = 'gemz/game/getLink/:privyId',
  GAME_GET_FREE_GEM = 'gemz/game/free',
  GAME_GET_FIRST_IN_FEED = 'gemz/game/firstInFeed',
  GAME_HANDLE_INSUFFICIENT_PAYMENT = 'gemz/game/insufficient_payment/:gameId',
  GAME_UNLOCK_WITH_HELP = 'gemz/game/outcome/helpWantedUnlock',

  GAME_LIKE = 'gemz/game/:gameId/like',
  GAME_UNLIKE = 'gemz/game/:gameId/unlike',
  GAME_ALL_LIKES = 'gemz/game/:gameId/allLikes',
  GAME_ALL_LIKES_COUNT = 'gemz/game/:gameId/allLikesCount',

  GAME_COMMENTS = 'gemz/game/:offChainGameId/comments',
  GAME_COMMENTS_SINGLE = 'gemz/game/comment/:commentId',
  GAME_COMMENTS_DELETE = 'gemz/game/comment/:commentId/delete',
  GAME_COMMENTS_REPLIES = 'gemz/game/comment/:commentId/replies',

  DEPOSIT_MADE = 'gemz/deposit/made/:walletAddress',
  DEPOSIT_CONFIRM = 'gemz/deposit/confirm/:walletAddress',
  DEPOSIT_FAIL = 'gemz/deposit/fail/:walletAddress',

  NOTIFY_PWA = 'gemz/notif/pwa',

  ALCHEMY_JTW_GET = 'gemz/alchemy/jwt',
  PAYMASTER_USER_OP_GET = 'gemz/wallet/paymaster',

  FOLLOWS_CREATE = 'gemz/follows/create',
  FOLLOWS_DELETE = 'gemz/follows/delete',
  FOLLOWS_IS_FOLLOWING = 'gemz/follows/isFollowing/:userId',
  FOLLOWS_IS_FOLLOWING_USERNAME = 'gemz/follows/isFollowingByUsername/:username',
  FOLLOWS_IS_FOLLOWING_ADDRESS = 'gemz/follows/isFollowingByAddress/:walletAddress',
  FOLLOWS_RELATIONSHIP = 'gemz/follows/relationship/:userId',
  FOLLOWS_RELATIONSHIP_USERNAME = 'gemz/follows/relationshipByUsername/:username',
  FOLLOWS_RELATIONSHIP_ADDRESS = 'gemz/follows/relationshipByAddress/:walletAddress',
  FOLLOWS_FOLLOWERS_MINE = 'gemz/follows/followers',
  FOLLOWS_FOLLOWERS_ID = 'gemz/follows/followers/:userId',
  FOLLOWS_FOLLOWERS_USERNAME = 'gemz/follows/followersByUsername/:username',
  FOLLOWS_FOLLOWERS_ADDRESS = 'gemz/follows/followersByAddress/:walletAddress',
  FOLLOWS_FOLLOWINGS_MINE = 'gemz/follows/followings',
  FOLLOWS_FOLLOWINGS_ID = 'gemz/follows/followings/:userId',
  FOLLOWS_FOLLOWINGS_USERNAME = 'gemz/follows/followingsByUsername/:username',
  FOLLOWS_FOLLOWINGS_ADDRESS = 'gemz/follows/followingsByAddress/:walletAddress',

  CHAT_ROOM_BY_ID = 'gemz/chat/room/:roomId',
  CHAT_ROOM_BY_CREATOR_ID = 'gemz/chat/roomByCreatorId/:creatorId',
  CHAT_ROOM_BY_CREATOR_USERNAME = 'gemz/chat/roomByCreatorUsername/:creatorUsername',
  CHAT_ROOMS = 'gemz/chat/room',
  CHAT_ROOM_MESSAGE = 'gemz/chat/room/:roomId/message',
  CHAT_ROOM_STATS = 'gemz/chat/room/:roomId/stats',
  CHAT_MESSAGES = 'gemz/chat/messages/:messageId',
  CHAT_ALL_ROOM_MESSAGES = 'gemz/chat/allRoomMessages',

  CRAFTABLE_RECIPE_GET = 'gemz/craftRecipe/:gameId',
  CRAFTABLE_BUY = 'gemz/craft/buyGemz',
  CRAFTABLE_SELL = 'gemz/craft/sellGemz',
  CRAFTABLE_MISSIONS = 'gemz/craft/missions/:userId',
  CRAFTABLE_MISSION_START = 'gemz/craft/missions/start',
  CRAFTABLE_MISSION_COMPLETE = 'gemz/craft/missions/complete',
  CRAFTABLE_MISSION_UPDATE = 'gemz/craft/missions/update',

  // Demo Links
  FEED = 'gems/feed/page/:page',
  FEED_CONTENT = 'gems/feed/content',
  GEMZ_MINT = 'gems/minted',
}

export enum MediaServiceEndpoints {
  GEMZ_SHORTENER = 'gemz/shortener',
}

export enum FeedServiceEndpoints {
  RECOMMEND_FEED_USER = 'recommend/itemsToUser',
  RECOMMEND_FEED_NEXT = 'recommend/nextItems',
  MERGE_USER = 'users/merge', // used to merge feeds, when using fingerprint vs userId

  EVENTS_ADD_RATINGS = 'events/add/ratings',
  EVENTS_GAME_VIEW = 'events/add/view',
  EVENTS_GAME_PORTION = 'events/add/portion',
  EVENTS_ADD_BOOKMARK = 'events/add/bookmark',
  EVENTS_DELETE_BOOKMARK = 'events/delete/bookmark',
  RECOMMEND_USERS_TO_FOLLOW = 'recommend/usersToUser',
  USER_LIST = 'users/list',
  GAME_VIEW_COUNTER = 'events/listItemDetailViews',
}
