import { IDBPDatabase, openDB } from 'idb';

const CACHE_NAME = 'SVSCacheDB';

export class CacheDB<T extends Record<string, any>> {
  db?: IDBPDatabase;

  constructor(private name: string, private version: number) {
    this.init();
  }

  init = async () => {
    this.db = await openDB(this.name, this.version, {
      upgrade(idb) {
        idb.createObjectStore(CACHE_NAME);
      },
    });
  };

  get = (key: IDBValidKey) => this.db?.get(CACHE_NAME, key);

  set = (key: IDBValidKey, val: any) => this.db?.put(CACHE_NAME, val, key);

  remove = (key: IDBValidKey) => this.db?.delete(CACHE_NAME, key);

  clear = () => this.db?.clear(CACHE_NAME);

  keys = () => this.db?.getAllKeys(CACHE_NAME) || ([] as string[]);

  getCache = async (): Promise<Partial<T>> => {
    const dbKeys = await this.keys();

    const dbCache: Record<string, any> = {};

    for (const key of dbKeys) {
      const value = await this.get(key);
      dbCache[key.toString()] = value;
    }

    return dbCache as Partial<T>;
  };
}
