import { LazyExoticComponent, Suspense, useEffect, useState } from 'react';
import { controller } from '../../lib/Controller';
import { useReRender } from '../../hooks/useReRender';
import { modal } from '.';
import './GameModals.scss';
import '../../styles/_animations.scss';
import { useClassName } from 'hooks/useClassName';
import ConfettiEffect from './components/effects/ConfettiEffect';
import CurrencyAnimationEffect from './components/effects/CurrencyAnimationEffect';

const test = {};
export const ModalContainer = () => {
  useReRender({ id: 'modals', listener: controller.modals.setComponent });
  useReRender({ id: 'modals', listener: controller.ui.elements.CommentSidebar.attachEventListener('on_change') });

  const { show, active, current, onLazyModalLoaded } = controller.modals;

  const { id, disableBackdrop, useConfettiEffect, useAnimationEffect, mode, type, onBackdropClick: onCustomBackdropClick } = current;

  const [CurrentModal, setCurrentModal] = useState<LazyExoticComponent<() => JSX.Element>>();

  const withSidebar = controller.ui.elements.CommentSidebar.visible;

  const onBackdropClick = () => {
    if (disableBackdrop) return;
    controller.modals.close();
    onCustomBackdropClick && onCustomBackdropClick();
  };

  useEffect(() => {
    setCurrentModal(modal[id]);
  }, [id]);

  // This is only fired off the first time we load a modal component
  const onLoaded = () => {
    onLazyModalLoaded(id);
  };

  const style = useClassName('modal-game', mode, withSidebar ? 'with-sidebar' : '', type, show ? 'show-anim' : 'hide-anim');

  return (
    <div className={style}>
      {active && <div className="backdrop" onClick={onBackdropClick} />}

      <div id="modal-box" className={`modal-box ${show ? 'show-anim' : 'hide-anim'}`}>
        {CurrentModal && (
          <Suspense fallback={<ModalLoader id={id} onLoaded={onLoaded} />}>
            <CurrentModal {...test} />
          </Suspense>
        )}
      </div>
      {useConfettiEffect ? <ConfettiEffect show /> : null}
      {useAnimationEffect ? <CurrencyAnimationEffect /> : null}
    </div>
  );
};

// There's no way for us to know when the modal is loaded via <Suspense />
// So using the fallback component's unmount to define we are ready to display the modal
const ModalLoader = ({ onLoaded, id }: { onLoaded: () => void; id?: string }) => {
  console.log(`ModalLoader (unmount): ${id}`);
  onLoaded();

  // TODO: Double check use of useEffect here.
  // For some reason with DrawerGemSellConfirm, the function being passed never gets called and not even the mount log never gets executed.
  /*
  useEffect(() => {
    console.log(`ModalLoader (mount): ${id}`);
    return () => {
      console.log(`ModalLoader (unmount): ${id}`);
      onLoaded();
    };
  }, []);
  */
  return <></>;
};
