import { SwMsgTypes } from 'lib/swMsgTypes';
import { EventListener } from '../EventListener';

export enum AppUpdateState {
  Idle = 'AppUpdateState/Idle',
  UpdateAvailable = 'AppUpdateState/UpdateAvailable',
  FirstTimeCached = 'AppUpdateState/FirstTimeCached',
}

export enum AppUpdateEvent {
  StateChange = 'AppUpdateEvent/StateChange',
}

/**
 * Like a controller, but purposely separate and not part of main controller
 * because this needs to be used in index.tsx ASAP.
 */
class SwUpdater extends EventListener {
  private swRegistration: ServiceWorkerRegistration;
  private _state = AppUpdateState.Idle;

  constructor() {
    super();
    this.addEventListener(AppUpdateEvent.StateChange, () => {
      console.log('SwUpdater on state change:', this.state);
    });
  }

  get state(): AppUpdateState {
    return this._state;
  }

  serviceWorkerOnUpdate = (registration: ServiceWorkerRegistration) => {
    this.swRegistration = registration;
    this._state = AppUpdateState.UpdateAvailable;
    this.sendEvents([AppUpdateEvent.StateChange]);
  };
  serviceWorkerOnSuccess = (registration: ServiceWorkerRegistration) => {
    this.swRegistration = registration;
    this._state = AppUpdateState.FirstTimeCached;
    this.sendEvents([AppUpdateEvent.StateChange]);
  };

  applyNewUpdate = async (skipReload = false): Promise<boolean> => {
    // only apply update if there's a new update
    if (this.state !== AppUpdateState.UpdateAvailable) {
      return false;
    }
    const waitPromises: Promise<void>[] = [
      // make sure enough time has passed for service worker to do its job
      new Promise((resolve) => setTimeout(resolve, 500)),
    ];
    if (this.swRegistration?.waiting) {
      // tell service worker to swap cache

      // wait for skip wait response
      // even if we don't get a response, the max time promise above will let the app reload anyway
      const skipWaitResponse: Promise<void> = new Promise((resolve) => {
        const onSkipWaitdone = (e: MessageEvent) => {
          if (e?.data?.type !== SwMsgTypes.SkipWaitingDone) {
            return;
          }

          console.log('swUpdater', 'received skip wait response');
          this.swRegistration.waiting.removeEventListener('message', onSkipWaitdone);
          resolve();
        };
        this.swRegistration.waiting.addEventListener('message', onSkipWaitdone);
      });
      waitPromises.push(skipWaitResponse);

      this.swRegistration.waiting.postMessage({ type: SwMsgTypes.SkipWaiting });
    } else {
      // this should never happen but just in case...
      console.error('swUpdater', 'no waiting service worker while update is available');
    }

    await Promise.race(waitPromises);

    if (!skipReload) {
      // reload to use new cache
      console.log('swUpdater', 'reload page');
      window.location.reload();
    }
    return true;
  };
}

export const swUpdater = new SwUpdater();
