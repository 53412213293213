import { pipelineApi, getEndpoint } from './base';
import { Endpoints } from './endpoints';
import { CraftRecipe } from '@storyverseco/svs-types';
import { controller } from '../Controller';

const getRecipe = async (gameId: number): Promise<CraftRecipe> => {
  return await pipelineApi.get(getEndpoint(Endpoints.CRAFTABLE_RECIPE_GET, { gameId }));
};

type MissionData = {
  offchain_game_id: number;
  user_id: number;
  last_activity: string;
};

const getMissions = async (): Promise<any[]> => {
  return await pipelineApi.get(getEndpoint(Endpoints.CRAFTABLE_MISSIONS, { userId: controller.user.me.id }));
};

const startMission = async (gameId: number): Promise<any> => {
  const data = { gameId };
  return await pipelineApi.post(Endpoints.CRAFTABLE_MISSION_START, { data });
};

const completeMission = async (gameId: number): Promise<any> => {
  const data = { gameId };
  return await pipelineApi.post(Endpoints.CRAFTABLE_MISSION_COMPLETE, { data });
};

const updateMission = async (gameId: number): Promise<any> => {
  const data = { gameId };
  return await pipelineApi.post(Endpoints.CRAFTABLE_MISSION_UPDATE, { data });
};

export const craft = {
  getRecipe,
  getMissions,
  startMission,
  completeMission,
  updateMission,
};
