import type { AppController } from '../Controller';
import { EventListener } from '../EventListener';

export enum KeyboardCtrlEvent {
  ViewportChange = 'KeyboardCtrlEvent/ViewportChange',
  MobileKeyboardChange = 'KeyboardCtrlEvent/MobileKeyboardChange',
}

export class KeyboardController extends EventListener {
  private keyboardRatioThreshold = 0.85; // 15% of screen

  private _visualViewportWidth = -1;
  private _visualViewportHeight = -1;

  private maxWidth = -1;
  private maxHeight = -1;

  private _mobileKeyboardShowing = false;

  get viewportWidth(): number {
    return this._visualViewportWidth;
  }

  get viewportHeight(): number {
    return this._visualViewportHeight;
  }

  /**
   * This is more of an educated guess whether or not the keyboard is showing for mobile
   */
  get mobileKeyboardShowing(): boolean {
    return this._mobileKeyboardShowing;
  }

  constructor(private app: AppController) {
    super();
    if (window?.visualViewport) {
      this.updateViewport();
      window.visualViewport.addEventListener('resize', this.onResize, false);
    }
  }

  private updateViewport(): void {
    this._visualViewportWidth = window.visualViewport.width;
    this._visualViewportHeight = window.visualViewport.height;

    if (this._visualViewportWidth > this.maxWidth) {
      this.maxWidth = this._visualViewportWidth;
    }
    if (this._visualViewportHeight > this.maxHeight) {
      this.maxHeight = this._visualViewportHeight;
    }
  }

  private onResize = (e: UIEvent): void => {
    this.updateViewport();
    this.sendEvents([KeyboardCtrlEvent.ViewportChange]);

    // only for mobile
    if (this.app.ui.isMobileLayout) {
      const showing = this._visualViewportHeight / this.maxHeight < this.keyboardRatioThreshold;
      if (showing !== this._mobileKeyboardShowing) {
        this._mobileKeyboardShowing = showing;
        this.sendEvents([KeyboardCtrlEvent.MobileKeyboardChange]);
      }
    }
  };
}
