function defaultCondition(value: any): boolean {
  return !!value;
}

export type Condition<T> = (value: T) => boolean;
export type FailCondition<T> = (value: T) => Error | undefined;
export type Resolve<T> = (value: T) => void;
export type Reject = (reason: any) => void;

export class ConditionWaiter<T> {
  private value: T = undefined;
  private pendingResolve: Resolve<T> = undefined;
  private pendingReject: Reject = undefined;

  private promise: Promise<T> = undefined;

  constructor(initialValue?: T, private condition: Condition<T> = defaultCondition, private failCondition: FailCondition<T> = undefined) {
    this.value = initialValue;
  }

  private checkAndMaybeResolve() {
    if (!this.condition(this.value)) {
      return;
    }
    const error = this.failCondition?.(this.value);
    if (error) {
      this.reject(error);
      return;
    }

    this.pendingResolve?.(this.value);
    this.pendingResolve = undefined;
    this.pendingReject = undefined;
    this.promise = undefined;
  }

  setCondition(condition: Condition<T>) {
    this.condition = condition;
  }

  set(value: T) {
    this.value = value;
    this.checkAndMaybeResolve();
  }

  get(): T {
    return this.value;
  }

  wait(): Promise<T> {
    if (this.condition(this.value)) {
      return Promise.resolve(this.value);
    }

    if (!this.promise) {
      this.promise = new Promise((resolve, reject) => {
        this.pendingResolve = resolve;
        this.pendingReject = reject;
      });
    }

    return this.promise;
  }

  reject(reason: any): void {
    if (!this.pendingReject) {
      return;
    }

    this.pendingReject(reason);
    this.pendingResolve = undefined;
    this.pendingReject = undefined;
    this.promise = undefined;
  }
}
