
export enum TwitterQPStatus { 
  Success = 'success',
  AuthError = 'authError',
}

export enum QueryParams {
  TwitterSecureParam = 'stk',
  TwitterStatusParam = 'tws',
  SkipPWA = 'skipPwa',
  Mock = 'useMock',
  LocalViewer = 'useLocalViewer',
  Debug = 'useDebug',
  CommentId = 'ci',
  LocalServer = 'useLocalServer',
  HelpWantedID = 'hwid',
  HelpOfferedID = 'hoid',
}

export const persistedQueryParams: string[] = [
  QueryParams.SkipPWA,
  QueryParams.Mock,
  QueryParams.LocalViewer,
  QueryParams.Debug,
];

const { hostname } = window.location;

export const initialQueryParams = new URLSearchParams(window.location.search);

export const sessionOpts = {
  skipPwa: initialQueryParams.has(QueryParams.SkipPWA),
  isMockupMode: initialQueryParams.has(QueryParams.Mock) || hostname.includes('localhost'),
  localDev: hostname.includes('ngrok'),
  localViewer: initialQueryParams.has(QueryParams.LocalViewer),
  showDebug: initialQueryParams.has(QueryParams.Debug),
  needsHelp: initialQueryParams.get(QueryParams.HelpWantedID),
  offeringHelp: initialQueryParams.get(QueryParams.HelpOfferedID),
  localServer: initialQueryParams.has(QueryParams.LocalServer),
}
// consume query params
export const consumableQueryParams: string[] = [
  QueryParams.HelpOfferedID,
  QueryParams.HelpWantedID,
  QueryParams.TwitterStatusParam,
];

export const getQueryParamsWithoutConsumables = () => {
  const qps = location.search.split('&');
  const [first, ...rest] = qps;
  const nonConsumableQps = [first.replace('?', ''), ...rest]
    .filter(qp => !consumableQueryParams.some(cqp => qp.includes(cqp)));
  return `?${nonConsumableQps.join('&')}`;
}

// consume query params
if (location.search.length > 2) {
  window.history.replaceState({}, document.title, `${window.location.pathname}${getQueryParamsWithoutConsumables()}`);
}

// RPC URLS
export const BASE_SEPOLIA_ENTRYPOINT_ADDRESS = "0x5FF137D4b0FDCD49DcA30c7CF57E578a026d2789";


export const SENTRY_DNS_URL = 'https://8106aae18ae55c3967cbd52019aaa638@o4505230490861568.ingest.sentry.io/4506196715831296';

const getChainName = (chainId: string = ''): string =>
  chains[chainId] || 'Unknown';

const chains: Record<string, string> = {
  'eip155:5': 'Ethereum Goerli',
  'eip155:1': 'Ethereum Mainnet',
  'eip155:11155111': 'Ethereum Sepolia',
}

export const consts = {
  minGasFee: 0.0001,
  chainIds: {
    baseSepolia: {
      numId: 84532,
      srtId: 'eip155:84532'
    },
    sepolia: {
      numId: 11155111,
      strId: 'eip155:11155111',
    },
    mainnet: {
      numId: 1,
      strId: 'eip155:1',
    },
    // add base L1
  },
  multibaas: {
    l1: {
      baseUrl: 'https://i5ukegjumvdxhcryvwahks7w5q.multibaas.com',
      token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIyNzY3MThhZi04ZmFmLTRiN2YtYmFkYS04MDA0M2Q3MmEwOGYiLCJpYXQiOjE2ODU5OTk5NDMsInN1YiI6IjcifQ.pT-PYZXxD5deSpV0s_Jq3x7OhkiAzT10YISnow3rM7E'
    }, 
    l2: {
      baseUrl: 'https://ibganvxymve6fi4is44kfi5chy.multibaas.com',
      token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI1IiwiaWF0IjoxNzA3OTQ1MTI0LCJqdGkiOiIwY2ZhM2FkNS1kYjMyLTQ0MzktOTM5MS05M2I3MzhhNmFiNzYifQ.nF-9ej5i-gEVCmsKG9Hffk7MS0qIvrXkMtTSnVQNN6M'
    }
  },
  getChainName,
}

