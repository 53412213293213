import { GameMultimediaUrls, GemType, WalletAddress } from '@storyverseco/svs-types';

/**
 * ========================================================
 *  THIS HAS TO BE MOVED TO svs-types
 * ========================================================
 */

export interface UserGame {
  gameThumbnails: GameMultimediaUrls;
  price: string;
  gemIcon: string;
  gameLink: string;
  // gameId: string;
  offChainGameId: number;
  gemType: GemType;
  onChainGameId: number;
}

export interface UserData<T extends UserGame | Holdings | Holder | UserLikedGame> {
  count: number;
  items: T[];
}

export interface Holder {
  offChainGameId: number;
  onChainGameId: number;
  ownerWallet: WalletAddress;
  itemCount: number;
  ownerTwitterPicture: string;
  ownerUsername: string;
  ownerDisplayName: string;
  ownerWalletAddress: WalletAddress;
}
/*
g.id AS "offChainGameId",
gb.game_id AS "onChainGameId",
gb.wallet_address AS "ownerWallet",
COUNT(gb.game_id) AS "itemCount",
u2.twitter_picture AS "ownerTwitterPicture",
u2.username AS "ownerUsername",
u2.twitter_displayname AS "ownerDisplayName",
u2.wallet_address_4337 AS "ownerWalletAddress" -- Extracting the whole JSONB object
*/

// export interface UserHolder {
//   gemImage: string;
//   userImage: string;
//   gameId: string;
//   price: string;
//   userName: string;
// }

export interface Holdings {
  offChainGameId: string;
  onChainGameId: string;
  gemIcon: string;
  gameThumbnails: GameMultimediaUrls;
  gemName: string;
  purchaseDate: string;
  count: string;
  creatorImage: string;
  creatorWalletAddress: WalletAddress;
  creatorUsername: string;
  creatorDisplayName: string;
  price: string;
  gemType: GemType;
}

//================================================================================
// If you are making changes here. Remember to update `getProfileDefaults` below
//================================================================================
export interface CachedProfile {
  games: UserData<UserGame>;
  holders: UserData<Holder>;
  holdings: UserData<Holdings>;
  likedGames: UserData<UserLikedGame>;
  portfolioValue: number;
  followerCount: number;
  followingCount: number;
  expirationTime: number;
  creatorLikesCount: number;

  userTwitterHandle?: string;
  userTwitterDisplayName?: string;
  userProfilePic?: string;
  userTwitterUrl?: string;
  originalTwitterPic?: string;

  // cache-control
  lastFetched?: number;
  // games views count
  gamesViewsCount?: Record<string, number>;
}

export interface UserLikedGame {
  gameThumbnails: GameMultimediaUrls;
  price: string;
  gemIcon: string;
  offChainGameId: string;
  gemType: GemType;
}

export const getItemsWithCount = <T>(items: T[]) => ({
  count: items.length,
  items,
});

export const getProfileDefaults = () => ({
  games: getItemsWithCount([]),
  holders: getItemsWithCount([]),
  holdings: getItemsWithCount([]),
  likedGames: getItemsWithCount([]),
  portfolioValue: 0,
  followerCount: 0,
  followingCount: 0,
  expirationTime: 0,
  creatorLikesCount: 0,
  userTwitterHandle: undefined,
  userTwitterDisplayName: undefined,
  userProfilePic: undefined,
  userTwitterUrl: undefined,
  originalTwitterPic: undefined,
});
