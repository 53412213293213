import { Fn } from 'lib/EventListener';
import { ConnectedWallet, User as PrivyUser, getAccessToken } from '@privy-io/react-auth';
import { Logger } from 'lib/BaseClass';
import { AppController } from 'lib/Controller';

enum State {
  Idle = 'idle',
  WaitForUser = 'WaitForUser',
  Ready = 'ready',
}

interface Props {
  onLogout: () => void;
}

export class PrivyController extends Logger {
  logConfig = {
    color: '#e3671b',
  };

  private _state = State.Idle;
  get ready() {
    return this._state === State.Ready;
  }

  private waitForUserResolver: Fn<void>;
  private waitForUser = new Promise((resolve) => {
    this.waitForUserResolver = resolve;
  });

  private waitForUserWalletResolver: Fn<void>;
  private waitForUserWallet = new Promise((resolve) => {
    this.waitForUserWalletResolver = resolve;
  });

  private user?: PrivyUser;
  get privyUser() {
    return this.user;
  }

  private privyWallet?: ConnectedWallet;
  get eoa() {
    return this.privyWallet;
  }

  private privyLoginFn?: Fn<void>;
  get login() {
    return this.privyLoginFn;
  }

  private privyLogoutFn?: Fn<Promise<void>>;

  private userReadyResolver: Fn<void>;
  public onUserReady = new Promise((resolve) => {
    this.userReadyResolver = resolve;
  });

  private exportWalletFn?: Fn<Promise<void>>;
  get exportWallet() {
    return this.exportWalletFn;
  }

  constructor(private app: AppController, private props: Props) {
    super();
  }

  private onAuth = async () => {
    await this.waitForUserWallet;
    await this.waitForUser;
    this.userReadyResolver();
  };

  init = async (authenticated: boolean) => {
    this.log('init', { authenticated });
    if (this._state !== State.Idle) {
      this.warn(`Trying to call 'init' more than once.`);
      return;
    }

    if (authenticated) {
      this._state = State.WaitForUser;
      this.onAuth();
    }

    this._state = State.Ready;
  };

  logout = async () => {
    await this.privyLogoutFn();
    this.props.onLogout();
  };

  reset = () => {
    this._state = State.Idle;
    this.waitForUser = new Promise((resolve) => {
      this.waitForUserResolver = resolve;
    });
    this.waitForUserWallet = new Promise((resolve) => {
      this.waitForUserWalletResolver = resolve;
    });
    this.user = undefined;
    this.privyWallet = undefined;
    this.onUserReady = new Promise((resolve) => {
      this.userReadyResolver = resolve;
    });
  };

  getToken = () => getAccessToken();

  /**
   * Note: To be used by PrivyConnect only
   */
  setUser = (user: PrivyUser) => {
    this.log('setUser', { user });
    // If we are setting a user on a ready state, that means a sign up by phone. (Social login restarts the app)
    this.user = user;
    this.waitForUserResolver();
    if (this.ready) {
      this.onAuth();
    }
  };

  /**
   * Note: To be used by PrivyConnect only
   */
  setWallet = (wallet: ConnectedWallet) => {
    this.log('setWallet', { wallet });
    this.privyWallet = wallet;
    this.waitForUserWalletResolver();
  };

  /**
   * Note: To be used by PrivyConnect only
   */
  setLogin = (privyLoginFn: Fn<void>) => {
    this.privyLoginFn = privyLoginFn;
  };

  /**
   * Note: To be used by PrivyConnect only
   */
  setExportWallet = (exportWalletFn: Fn<Promise<void>>) => {
    this.exportWalletFn = exportWalletFn;
  };

  /**
   * Note: To be used by PrivyConnect only
   */
  setLogout = (privyLogoutFn: Fn<Promise<void>>) => {
    this.privyLogoutFn = privyLogoutFn;
  };
}
