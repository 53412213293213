import './polyfill';
import ReactDOM from 'react-dom/client';
// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import 'features/globals.scss';
import { swUpdater } from './lib/apis/swupdate';
import { startCheckForPwa } from './lib/apis/pwa';
import './styles/index.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: swUpdater.serviceWorkerOnUpdate,
  onSuccess: swUpdater.serviceWorkerOnSuccess,
});
// debug: comment above and uncomment below so there's no caching
// shenanigans for local dev. Might still need to manually unregister though
// serviceWorkerRegistration.unregister();

startCheckForPwa();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
