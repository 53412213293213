import { WalletAddress } from '@storyverseco/svs-types';
import { ImagesCache } from '../controllers/AssetsController';
import { ShareURLCache } from '../controllers/ShareController';
import { UICache } from '../controllers/UIController';
import { CachedRelationship, StrippedCachedFollows } from '../followsTypes';
import { NotificationsCacheSchema } from 'features/Notifications/types';
import { FingerprintCache } from 'lib/controllers/FingerprintController';
import { UserCache } from 'lib/controllers/auth/types';

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// !! Make sure that you add a default (even if undefined) to 'getCacheWithDefaults' (just below) !!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
export interface CacheSchema {
  // OneSignal permission check
  readonly hasShownOneSignalPermission: boolean;
  // Not sure if still in use
  readonly pwaFirstInstall: boolean;
  // current / last user operation sent
  readonly currenUserOpTxHash?: any; //SendUserOperationResult;
  readonly profileCache?: any; //Record<string, CachedProfile>;
  readonly gameViewsCounterCache?: Record<string, number>;
  readonly followsCache?: Record<string, StrippedCachedFollows>;
  readonly relationshipCache?: Record<string, CachedRelationship>;
  // track user ids that used the device (should match fingerprint DB)
  readonly userIds: number[];
  // current / last fingerprint set
  readonly fingerpint?: any; //Fingerprint;
  // current / last deposit hash
  readonly currentDepositTxHash?: WalletAddress;

  // Feature cache
  readonly imagesCache: ImagesCache;
  readonly shareUrlCache: ShareURLCache;
  readonly uiCache?: UICache;

  readonly notificationsCache: NotificationsCacheSchema;

  readonly fingerprintCache?: FingerprintCache;

  readonly userCache?: UserCache;
}

export type CacheKey = keyof CacheSchema;

export const getCacheWithDefaults = ({
  pwaFirstInstall,
  userIds,
  currenUserOpTxHash,
  profileCache,
  followsCache,
  relationshipCache,
  fingerpint,
  hasShownOneSignalPermission,
  currentDepositTxHash,
  imagesCache,
  shareUrlCache,
  uiCache,
  notificationsCache,
  fingerprintCache,
  userCache,
}: Partial<CacheSchema>): CacheSchema => ({
  pwaFirstInstall: Boolean(pwaFirstInstall),
  userIds: userIds || [],
  hasShownOneSignalPermission: Boolean(hasShownOneSignalPermission),
  imagesCache: imagesCache || {},
  shareUrlCache: shareUrlCache || {},
  // optionals (default to undefined)
  currenUserOpTxHash,
  profileCache,
  followsCache,
  relationshipCache,
  fingerpint,
  currentDepositTxHash,
  uiCache,
  notificationsCache: notificationsCache || {},
  fingerprintCache,
  userCache,
});
