import { FeatureCache } from '../cache/FeatureCache';

// Might need to move this type else where
export type ImagesCache = Record<string, true>; // we only care to know we have loaded them before

export class AssetsController {
  private imageCache = new FeatureCache<ImagesCache>('imagesCache', () => ({}));

  private imagesInFlight: string[] = [];

  constructor() {}

  private save = async () => {
    await this.imageCache.isReady;
    this.imageCache.save();
  };

  cacheImages = async (imageUrls: string[]) => {
    // Make sure we don't duplicate requests
    const onlyNewRequests = imageUrls.filter((url) => {
      if (!url) {
        return false;
      }
      const hasCache = this.imageCache.get(url);
      const alreadyRequested = this.imagesInFlight.includes(url);
      return !hasCache && !alreadyRequested;
    });

    const promises = onlyNewRequests.map((url) => {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = url;
        image.onload = () => {
          this.imageCache.set(url, true);
          resolve(true);
        };
        image.onerror = reject;
        this.imagesInFlight.push(url);
      });
    });

    await Promise.all(promises);

    this.save();

    return true;
  };
}
