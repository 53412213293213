import { FollowData, FollowRelationship } from '@storyverseco/svs-types';
import { getEndpoint, pipelineApi } from './base';
import { Endpoints } from './endpoints';

export interface FollowsProps {
  userId?: number;
  username?: string;
  walletAddress?: string;
}

const follow = (props: FollowsProps) => pipelineApi.post(Endpoints.FOLLOWS_CREATE, props);

const unfollow = (props: FollowsProps) => pipelineApi.post(Endpoints.FOLLOWS_DELETE, props);

const isFollowing = ({ userId, username, walletAddress }: FollowsProps): Promise<boolean> => {
  if (userId) {
    return pipelineApi.get(getEndpoint(Endpoints.FOLLOWS_IS_FOLLOWING, { userId: userId.toString() }));
  } else if (username) {
    return pipelineApi.get(getEndpoint(Endpoints.FOLLOWS_IS_FOLLOWING_USERNAME, { username }));
  } else if (walletAddress) {
    return pipelineApi.get(getEndpoint(Endpoints.FOLLOWS_IS_FOLLOWING_ADDRESS, { walletAddress }));
  } else {
    throw new Error('follows.isFollowing error: must specify userId, username, or walletAddress');
  }
};

const getRelationship = ({ userId, username, walletAddress }: FollowsProps): Promise<FollowRelationship> => {
  if (userId) {
    return pipelineApi.get(getEndpoint(Endpoints.FOLLOWS_RELATIONSHIP, { userId: userId.toString() }));
  } else if (username) {
    return pipelineApi.get(getEndpoint(Endpoints.FOLLOWS_RELATIONSHIP_USERNAME, { username }));
  } else if (walletAddress) {
    return pipelineApi.get(getEndpoint(Endpoints.FOLLOWS_RELATIONSHIP_ADDRESS, { walletAddress }));
  } else {
    throw new Error('follows.getRelationship error: must specify userId, username, or walletAddress');
  }
};

const getMyFollowers = (): Promise<FollowData[]> => {
  return pipelineApi.get(Endpoints.FOLLOWS_FOLLOWERS_MINE);
};

const getOthersFollowers = ({ userId, username, walletAddress }: FollowsProps): Promise<FollowData[]> => {
  if (userId) {
    return pipelineApi.get(getEndpoint(Endpoints.FOLLOWS_FOLLOWERS_ID, { userId: userId.toString() }));
  } else if (username) {
    return pipelineApi.get(getEndpoint(Endpoints.FOLLOWS_FOLLOWERS_USERNAME, { username }));
  } else if (walletAddress) {
    return pipelineApi.get(getEndpoint(Endpoints.FOLLOWS_FOLLOWERS_ADDRESS, { walletAddress }));
  } else {
    throw new Error('follows.getOthersFollowers error: must specify userId, username, or walletAddress');
  }
};

const getMyFollowings = (): Promise<FollowData[]> => {
  return pipelineApi.get(Endpoints.FOLLOWS_FOLLOWINGS_MINE);
};

const getOthersFollowings = ({ userId, username, walletAddress }: FollowsProps): Promise<FollowData[]> => {
  if (userId) {
    return pipelineApi.get(getEndpoint(Endpoints.FOLLOWS_FOLLOWINGS_ID, { userId: userId.toString() }));
  } else if (username) {
    return pipelineApi.get(getEndpoint(Endpoints.FOLLOWS_FOLLOWINGS_USERNAME, { username }));
  } else if (walletAddress) {
    return pipelineApi.get(getEndpoint(Endpoints.FOLLOWS_FOLLOWINGS_ADDRESS, { walletAddress }));
  } else {
    throw new Error('follows.getOthersFollowings error: must specify userId, username, or walletAddress');
  }
};

export const follows = {
  follow,
  unfollow,
  isFollowing,
  getRelationship,
  getMyFollowers,
  getOthersFollowers,
  getMyFollowings,
  getOthersFollowings,
};
