import './CraftInfo.scss';
import './CraftItem.scss';

import { useCallback, useEffect, useState } from 'react';
import CraftItem from './CraftItem';
import { controller } from 'lib/Controller';
import { Modals } from 'features/Modals';

export function CraftMissions() {
  const tapOnEllipsisIcon = useCallback(() => {
    controller.modals.open(Modals.DrawerCraftList);
  }, []);

  return (
    <div className="craft-info svg-shadow">
      <div className="craft-sidebar">
        {controller.craft.visibleMissions.map((missionId, i) => {
          let mission = controller.craft.getMissionData(missionId);
          let uiData = mission?.uiData;
          if (!uiData) return null;
          const data = {
            referrer: 'VisibleMissionsList',
          };
          return (
            <div key={`mission-${i}`}>
              <CraftItem size={64} gameData={uiData} onClick={() => controller.craft.showMissionInfo(missionId, { data })} />
            </div>
          );
        })}
        {!!controller.craft.invisibleMissions.length && (
          <div className="button-ellipsis" onClick={tapOnEllipsisIcon}>
            <div className="label">...</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CraftMissions;
