import Confetti from 'react-confetti';
import { controller } from 'lib/Controller';
import './ConfettiEffect.scss';

const ConfettiEffect = (opts: { show: boolean }) => {
  const { show } = opts;

  return (
    <div className="confetti-effect">
      <Confetti
        run={true}
        width={Math.min(visualViewport.width, controller.ui.isDesktopLayout ? visualViewport.width : 414)}
        height={visualViewport.height}
        gravity={show ? 0.05 : 0.5}
        // wind={show ? 0 : 0.5}
        friction={show ? 0.99 : 1}
        numberOfPieces={show ? 80 : 0}
      />
    </div>
  );
};

export default ConfettiEffect;
