import { FollowData, FollowRelationship } from '@storyverseco/svs-types';
import { AppController } from '../Controller';
import { api } from '../apis';
import { EventListener, Fn } from '../EventListener';
import { FollowState, startingFollowStateMap } from '../followsUtils';
import { getHighResTwitterPic } from '../twitterUtils';
import { followsCache } from '../cache/FollowsCache';
import { FollowsPair } from '../followsTypes';
import { FollowsProps } from '../apis/follows';
import { UserControllerEvent } from './auth/UserController';
import { waitForMs } from 'lib/wait';
import { Holder } from 'pages/profile/profileTypes';

export enum FollowEvent {
  FollowStateChanged = 'FollowEvent/FollowStateChanged',
  FollowRelationshipChanged = 'FollowEvent/FollowRelationshipChanged',
}

export interface RelationshipData {
  username: string;
  followRelationship: FollowRelationship;
}

export const followBtnStates = [FollowState.Idle, FollowState.FirstLoad, FollowState.NotFollowing, FollowState.NotFollowingProgress];
export const unfollowBtnStates = [FollowState.Following, FollowState.FollowingProgress];
export const disabledFollowBtnStates = [FollowState.Idle, FollowState.FirstLoad, FollowState.NotFollowingProgress, FollowState.FollowingProgress];

const replaceTwitterPic = (data: FollowData) => {
  if (data.followerUser?.twitter?.picture) {
    data.followerUser.twitter.picture = getHighResTwitterPic(data.followerUser.twitter.picture);
  }
  if (data.followingUser?.twitter?.picture) {
    data.followingUser.twitter.picture = getHighResTwitterPic(data.followingUser.twitter.picture);
  }
};

const followerDataToRelData = (data: FollowData): RelationshipData => ({
  username: data.followerUser?.username,
  followRelationship: data.relationship,
});

const followingDataToRelData = (data: FollowData): RelationshipData => ({
  username: data.followingUser?.username,
  followRelationship: data.relationship,
});

export class FollowController extends EventListener {
  private followStateMap: Record<string, FollowState> = {};

  private followRelationshipMap: Record<string, FollowRelationship> = {};

  private sessionUserId: number = undefined;

  constructor(private app: AppController) {
    super();

    app.addEventListener(UserControllerEvent.OnUserUpdate, this.onUserUpdate);
  }

  initSessionUser = async () => {
    if (this.sessionUserId) {
      return;
    }

    const sessionUser = this.app.user?.me;
    if (!sessionUser?.id) {
      return;
    }

    await followsCache.init(sessionUser.id.toString());
    const cachedFollows = followsCache.get({ userId: sessionUser.id }) ?? {};
    this.handleFollowDataList({ username: sessionUser.username }, cachedFollows);
    this.sessionUserId = sessionUser.id;
  };

  setFollowState = (username: string, followState: FollowState, noTrigger = false): boolean => {
    const lcUsername = username.toLowerCase();
    if (this.followStateMap[lcUsername] === followState) {
      return false;
    }

    this.followStateMap[lcUsername] = followState;
    if (!noTrigger) {
      this.triggerFollowStateChanged(username);
    }
    return true;
  };

  getFollowState = (username: string): FollowState => {
    return this.followStateMap[username.toLowerCase()];
  };

  setFollowRelationship = (username: string, followRelationship: FollowRelationship, noTrigger = false): boolean => {
    const lcUsername = username.toLowerCase();
    if (this.followRelationshipMap[lcUsername] === followRelationship) {
      return false;
    }

    this.followRelationshipMap[lcUsername] = followRelationship;
    if (!noTrigger) {
      this.triggerFollowRelationshipChanged(username);
    }
    return true;
  };

  getFollowRelationship = (username: string): FollowRelationship => {
    return this.followRelationshipMap[username.toLowerCase()];
  };

  addRelationship = ({ username, followRelationship }: RelationshipData, noTrigger = false): boolean => {
    const relChanged = this.setFollowRelationship(username, followRelationship, noTrigger);
    const followState = startingFollowStateMap[followRelationship];
    const stateChanged = this.setFollowState(username, followState, noTrigger);

    return relChanged || stateChanged;
  };

  multiAddRelationships = (rels: RelationshipData[]): boolean => {
    const changedUsernames = new Set<string>();
    for (const rel of rels) {
      // do all the triggers at the end
      const changed = this.addRelationship(rel, true);
      if (changed) {
        changedUsernames.add(rel.username);
      }
    }
    for (const username of changedUsernames) {
      this.triggerFollowStateChanged(username);
      this.triggerFollowRelationshipChanged(username);
    }

    return changedUsernames.size > 0;
  };

  loadSingleFollow = async (username: string): Promise<void> => {
    const currentFollowState = this.getFollowState(username) ?? FollowState.Idle;

    if (currentFollowState && currentFollowState !== FollowState.Idle) {
      return;
    }

    this.setFollowState(username, FollowState.FirstLoad);

    try {
      const followRelationship = await api.follows.getRelationship({ username });
      const newFollowState = startingFollowStateMap[followRelationship];
      this.setFollowState(username, newFollowState);
      this.setFollowRelationship(username, followRelationship);
    } catch (e) {
      console.error('FollowController.loadSingleFollow error: Failed to check if following', e);
      this.setFollowState(username, FollowState.Idle);
    }
  };

  loadMyFollowers = async (cached = false): Promise<FollowData[]> => {
    const userId = this.app.user?.me?.id;
    if (!userId) {
      throw new Error('FollowController.loadMyFollowers: No logged in user');
    }

    // return cached if specified
    if (cached) {
      await this.initSessionUser();
      const rawFollowers = followsCache.get({ userId })?.followers ?? [];
      return rawFollowers.filter((data) => Boolean(data.followerUser?.username ?? data.followerUser?.twitter?.handle));
    }

    const rawFollowers = await api.follows.getMyFollowers();
    const followers = rawFollowers.filter((data) => Boolean(data.followerUser?.username ?? data.followerUser?.twitter?.handle));
    this.handleFollowDataList({ userId }, { followers });
    return followers;
  };

  loadMyFollowings = async (cached = false): Promise<FollowData[]> => {
    const userId = this.app.user?.me?.id;
    if (!userId) {
      throw new Error('FollowController.loadMyFollowings: No logged in user');
    }

    if (cached) {
      await this.initSessionUser();
      const rawFollowings = followsCache.get({ userId })?.followings ?? [];
      return rawFollowings.filter((data) => Boolean(data.followingUser?.username ?? data.followingUser?.twitter?.handle));
    }

    const rawFollowings = await api.follows.getMyFollowings();
    const followings = rawFollowings.filter((data) => Boolean(data.followingUser?.username ?? data.followingUser?.twitter?.handle));
    this.handleFollowDataList({ userId }, { followings });
    return followings;
  };

  loadOtherFollowers = async (followsProps: FollowsProps, cached = false): Promise<FollowData[]> => {
    if (cached) {
      if (this.app.user?.me?.id) {
        await this.initSessionUser();
        const rawFollowers = followsCache.get(followsProps)?.followers ?? [];
        return rawFollowers.filter((data) => Boolean(data.followerUser?.username ?? data.followerUser?.twitter?.handle));
      }
      return [];
    }

    const rawFollowers = await api.follows.getOthersFollowers(followsProps);
    const followers = rawFollowers.filter((data) => Boolean(data.followerUser?.username ?? data.followerUser?.twitter?.handle));
    this.handleFollowDataList(followsProps, { followers });
    return followers;
  };

  loadOtherFollowings = async (followsProps: FollowsProps, cached = false): Promise<FollowData[]> => {
    if (cached) {
      if (this.app.user?.me?.id) {
        await this.initSessionUser();
        const rawFollowings = followsCache.get(followsProps)?.followings ?? [];
        return rawFollowings.filter((data) => Boolean(data.followingUser?.username ?? data.followingUser?.twitter?.handle));
      }
      return [];
    }

    const rawFollowings = await api.follows.getOthersFollowings(followsProps);
    const followings = rawFollowings.filter((data) => Boolean(data.followingUser?.username ?? data.followingUser?.twitter?.handle));
    this.handleFollowDataList(followsProps, { followings });
    return followings;
  };

  loadMyHolders = async (cached = false): Promise<Holder[]> => {
    await this.app.user.waitForInit;
    if (!this.app.user.me) {
      return [];
    }
    await this.app.profile.load(this.app.user.me.username);
    const { items } = this.app.profile.current.holders;
    return items as Holder[];
  };
  loadOtherHolders = async (username: string, cached = false): Promise<Holder[]> => {
    await this.app.profile.load(username);
    const { items } = this.app.profile.current.holders;
    return items as Holder[];
  };

  unfollow = async (username: string): Promise<void> => {
    const prevFollowState = this.getFollowState(username) ?? FollowState.Idle;
    this.setFollowState(username, FollowState.NotFollowingProgress);

    try {
      await api.follows.unfollow({ username });
      this.setFollowState(username, FollowState.NotFollowing);

      const followRelationship = this.getFollowRelationship(username);
      if (followRelationship === FollowRelationship.Mutual) {
        this.setFollowRelationship(username, FollowRelationship.Follower);
      } else {
        this.setFollowRelationship(username, FollowRelationship.None);
      }
    } catch (e) {
      console.error('FollowController.unfollow error:', e);
      // revert to previous state
      this.setFollowState(username, prevFollowState);
    }
  };

  follow = async (username: string): Promise<void> => {
    const prevFollowState = this.getFollowState(username) ?? FollowState.Idle;
    this.setFollowState(username, FollowState.FollowingProgress);

    try {
      await api.follows.follow({ username });
      this.setFollowState(username, FollowState.Following);

      const followRelationship = this.getFollowRelationship(username);
      if (followRelationship === FollowRelationship.Follower) {
        this.setFollowRelationship(username, FollowRelationship.Mutual);
      } else {
        this.setFollowRelationship(username, FollowRelationship.Following);
      }
    } catch (e) {
      console.error('FollowController.follow error:', e);
      // revert to previous state
      this.setFollowState(username, prevFollowState);
    }
  };

  attachUsernameListener = (eventName: FollowEvent, username: string) => (callback: Fn<void>) => {
    if (!username) {
      return () => {};
    }
    return this.addEventListener(`${eventName}/${username.toLowerCase()}`, callback);
  };

  private triggerFollowStateChanged = (username: string, followState?: FollowState): void => {
    followState ??= this.followStateMap[username.toLowerCase()];
    this.sendEvents([{ name: FollowEvent.FollowStateChanged, params: [username, followState] }]);
    this.sendEvents([{ name: `${FollowEvent.FollowStateChanged}/${username.toLowerCase()}`, params: [followState] }]);
  };

  private triggerFollowRelationshipChanged = (username: string, followRelationship?: FollowRelationship): void => {
    followRelationship ??= this.followRelationshipMap[username.toLowerCase()] ?? FollowRelationship.None;
    this.sendEvents([{ name: FollowEvent.FollowRelationshipChanged, params: [username, followRelationship] }]);
    this.sendEvents([{ name: `${FollowEvent.FollowRelationshipChanged}/${username.toLowerCase()}`, params: [followRelationship] }]);
  };

  private handleFollowDataList = (followsProps: FollowsProps, { followers, followings }: FollowsPair) => {
    if (followers) {
      followers.forEach(replaceTwitterPic);
      const relDataList = followers.map(followerDataToRelData);
      this.multiAddRelationships(relDataList);
    }
    if (followings) {
      followings.forEach(replaceTwitterPic);
      const relDataList = followings.map(followingDataToRelData);
      this.multiAddRelationships(relDataList);
    }
    // TODO add to user info to user cache
    followsCache.set(followsProps, { followers, followings });
  };

  private onUserUpdate = () => {
    // clear stuff if different user ID (or not logged in)
    if (!this.sessionUserId) {
      // already cleared
      return;
    }

    const user = this.app.user?.me;
    if (user && user.id === this.sessionUserId) {
      // same id, don't change
      return;
    }

    // clear stuff
    this.sessionUserId = undefined;
    this.followStateMap = {};
    this.followRelationshipMap = {};
  };
}
