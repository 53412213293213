import * as Sentry from '@sentry/react';
import TweenOne from 'rc-tween-one';
import Children from 'rc-tween-one/lib/plugin/ChildrenPlugin';

import { SENTRY_DNS_URL } from '../config/consts';
import { env } from '../config/env';

const initSentry = () => {
  if (['staging', 'prod'].includes(env.env)) {
    Sentry.init({
      dsn: SENTRY_DNS_URL,
      integrations: [
        new Sentry.BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: [
            /\.pnk\.one$/, // Matches any URL ending with .pnk.one, including all subdomains
          ],
        }),
        new Sentry.Replay(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  } else {
    console.log('Sentry not initialized for', env.env);
  }
};

const initTweenOne = () => {
  TweenOne.plugins.push(Children);
};

export const providers = {
  initSentry,
  initTweenOne,
};
