import { useMemo } from 'react';
import { humanizeTimestamp } from 'lib/time';

export const useHumanizedAgo = (timestamp: number | string | undefined | null, nonce = 0): string => {
  return useMemo(() => {
    if (!timestamp) {
      return '...';
    }
    const now = Date.now();
    const time = new Date(timestamp).getTime();
    return humanizeTimestamp(now - time, true);
  }, [timestamp, nonce]);
};
