export const extractHashFromError = (errorString: string) => {
  const regex = /Transaction with hash "([^"]+)"/;
  const match = errorString.match(regex);

  if (match && match.length > 1) {
    return match[1];
  } else {
    return null;
  }
};

export const extractUserOpFromError = (error: any) => {
  // Viem produces error messages with the first being the RPC url and the second being the details
  const msg = error.metaMessages?.[1];
  if (!msg) {
    return undefined;
  }
  const jsonStr = msg.replace('Request body: ').trim();
  try {
    const { params } = JSON.parse(jsonStr);
    // When sending userOp the first param is the userOp data nd the second is the network entry point address
    const [userOp] = params;
    return userOp;
  } catch (e) {
    console.error(`Error (extractUserOpFromError): failed to extract json`, { msg, jsonStr });
    return undefined;
  }
};

// 'pending ops: replacement op must increase'
