import { FollowData, FollowRelationship } from '@storyverseco/svs-types';

export type FollowDataNoRel = Omit<FollowData, 'relationship' | 'relationTo'>;

export interface FollowsPair {
  followers?: FollowData[];
  followings?: FollowData[];
}

export interface CachedFollows {
  followers?: FollowData[];
  followings?: FollowData[];
  expireTime: number;
}

export interface StrippedCachedFollows {
  followers?: FollowDataNoRel[];
  followings?: FollowDataNoRel[];
  expireTime: number;
}

export interface CachedRelationship {
  relationship: FollowRelationship;
  expireTime: number;
}

export enum FollowBtnType {
  Follow = 'FollowBtnType/Follow',
  Unfollow = 'FollowBtnType/Unfollow',
  FollowBack = 'FollowBtnType/FollowBack',
  /**
   * i.e. Friends
   */
  Mutuals = 'FollowBtnType/Mutuals',
}
