import { WalletAddress } from '@storyverseco/svs-types';
import { getEndpoint, pipelineApi } from './base';
import { Endpoints } from './endpoints';

const onDepositMade = (walletAddress: WalletAddress) => 
  pipelineApi.get(getEndpoint(Endpoints.DEPOSIT_MADE, {walletAddress}), false);

const onDepositConfirm = (walletAddress: WalletAddress) => 
  pipelineApi.get(getEndpoint(Endpoints.DEPOSIT_CONFIRM, {walletAddress}), false);  

const onDepositFail = (walletAddress: WalletAddress) => 
  pipelineApi.get(getEndpoint(Endpoints.DEPOSIT_FAIL, {walletAddress}), false);  

export const deposit = {
  onDepositMade,
  onDepositConfirm,
  onDepositFail
};
