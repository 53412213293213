// ----------------------------------------------

import { AppEvents, GameCreateProps, GemProps, GemType, Nullable } from '@storyverseco/svs-types';
import { AppController } from '../../lib/Controller';
import { ComponentController } from '../../lib/controllers/ComponentController';
import { AiUpdateProps, StoryTapOpts } from '../../types/types';
import { sendGameEvent } from '../../lib/navbarSuite';
// import { Modals } from "../../lib/controllers/ModalController";
import { getGameSafeArea } from '../../lib/apis/pwa';
import { setAppScrolling } from '../../lib/scroll';
import { MutableRefObject } from 'react';
import { ChatInputType, AiChatEntry, AiChatMode, chatInputTypeArr, AiChatSelection, GemImageChoice, GemItem } from './types';
import { Modals } from 'features/Modals';
import { Routes, getRouteWithParams } from 'router';
import { isMobileEmulatedByBrowser } from 'lib/device';
import { getAccessToken } from '@privy-io/react-auth';

// ----------------------------------------------
// types and interfaces

// react doesnt have enterkeyhint prop out of the box for textarea,
// so we need to add it manually
declare module 'react' {
  interface TextareaHTMLAttributes<T> extends HTMLAttributes<T> {
    enterKeyHint?: 'search' | 'done' | 'enter' | 'go' | 'next' | 'previous' | 'send';
  }
}

// ----------------------------------------------
// general function checks

export function isHardcodedMessage(type: ChatInputType, requestId: number) {
  if (type === 'user') return false; // let's not consider user entries as hardcoded messages
  return requestId === null;
}

export function isSuggestionWithButtons(type: ChatInputType) {
  if (type === 'locationSuggestion') return true;
  if (type === 'characterSuggestion') return true;
  if (type === 'craftIngredients') return true;
  if (type === 'itemCurrencies') return true;
  if (type === 'ipList') return true;
  if (type === 'gemPreviews') return true;
  return false;
}

export function isSuggestionWithThumbnails(type: ChatInputType) {
  if (type === 'locationSuggestion') return true;
  if (type === 'characterSuggestion') return true;
  if (type === 'craftIngredients') return true;
  if (type === 'gemPreviews') return true;
  if (type === 'ipList') return true;
  return false;
}

function isChatInputType(str: ChatInputType): str is ChatInputType {
  if (str === 'gemProfile') return false;
  if (str === 'gameUpdate') return false;
  if (str === 'publishSuccess') return false;

  // console.error('>>> isChatInputType', str, chatInputTypeArr.indexOf(str) !== -1);
  return chatInputTypeArr.indexOf(str) !== -1;
}

export function isChatInputTypeUsableInChat(type: ChatInputType, result?: any) {
  // if this is an error feedback type, escape early
  if (type === 'error') {
    console.error('>>> AiFeedBackType', type, result);
    return false;
  }

  // if this is an unknown feedback type, escape early
  if (!isChatInputType(type)) {
    console.warn('>>> AiFeedBackType', type, 'is not recognized by frontend as a chat entry. Escaping.');
    return false;
  }

  return true;
}

function filterGemPreviews(previews: string[]) {
  const arr = previews?.filter((url) => url.includes('_low'));
  return arr;
}

function generateChatHistory(opts: {
  aiFeedbacks: { timestamp: number; feedback: { requestUid: number; type: ChatInputType; result: string } }[];
  userMessages: { timestamp: number; message: { requestUid: number; type: ChatInputType; userInput: string } }[];
}) {
  // generate array of ai feedbacks in chatEntryData format
  const aiFeedbacks = opts.aiFeedbacks
    .filter((item) => {
      // if (item.message.type === 'gemProfile') return false;
      return isChatInputTypeUsableInChat(item.feedback.type) && item.feedback.type !== 'loading';
    })
    .map((item) => {
      return { ...item.feedback, timestamp: item.timestamp } as AiChatEntry;
    })
    // filter gem previews to use only _low assets
    .map((item) => {
      if (item.type === 'gemPreviews') {
        item.result.previews = filterGemPreviews(item.result.previews);
      }

      return item;
    });

  // generate array of user messages in chatEntryData format
  const userMessages = opts.userMessages.map((item) => {
    return { timestamp: item.timestamp, requestUid: null, type: 'user', result: item.message.userInput } as AiChatEntry;
  });

  // merge both arrays and sort them by timestamps
  const _chatHistory = aiFeedbacks.concat(userMessages).sort(function (x, y) {
    return (x?.timestamp || 0) - (y?.timestamp || 0);
  });

  // insert gem preview text where necessary
  for (let i = 0; i < _chatHistory.length; i += 1) {
    const item = _chatHistory[i];
    if (item.type === 'gemPreviews') {
      _chatHistory.splice(i, 0, { requestUid: item.requestUid, type: 'text', result: item.result.text });
      i += 1;
    }
  }

  // console.warn('>>> ai feedbacks', aiFeedbacks);
  // console.warn('>>> user messages', userMessages);
  console.warn('>>> AiAssistant- generated chatHistory', JSON.parse(JSON.stringify(_chatHistory)));
  return _chatHistory;
}

interface CallbackOpts {
  onSuccess?: (gameId: number) => void;
  onFailure?: () => void;
}

interface FailureOpts {
  retry?: boolean;
  maxRetries?: number;
  retryPointer?: number;
  retryDelay?: number;
  retryBackoff?: number;
}

interface MintOpts {
  autoBuyFirstGem?: boolean;
}

type Opts = CallbackOpts & FailureOpts & MintOpts;

function convertGemProfileMessageFromGameToGemProps(result: { gemId: string; name: string; description: string }, props?: GameCreateProps) {
  return {
    ...props?.gemData,
    id: result.gemId,
    name: result.name,
    description: result.description,
  };
}

interface ElementRefs {
  // containerRef?: MutableRefObject<HTMLDivElement>;
  aiTopRef?: MutableRefObject<HTMLDivElement>;
  // aiBottomRef?: MutableRefObject<HTMLDivElement>;
  textAreaRef?: MutableRefObject<HTMLDivElement>;
}

export class AiAssistantController extends ComponentController {
  logConfig = {
    color: '#96f366',
  };

  private createProps?: GameCreateProps;
  get props() {
    return this.createProps;
  }

  private _gemType: GemType = 'coin';
  get gemType() {
    return this._gemType;
  }

  private _chatMode: AiChatMode = 'chat';
  get chatMode() {
    return this._chatMode;
  }

  private _chatEntryData: AiChatEntry[] = [];
  get chatEntryData() {
    return this._chatEntryData;
  }

  private _chatHistory: AiChatEntry[] = [];
  get chatHistory() {
    return this._chatHistory;
  }

  private aiGenerationStarted = false;
  private aiProgress = 0;
  private aiComplete = false;

  private _userInput = '';
  get userInput() {
    return this._userInput;
  }

  private _isFocus = false;
  get isFocus() {
    return this._isFocus;
  }

  private pfpIp?: string;
  get currentIp() {
    return this.pfpIp;
  }

  private gameIframeElement?: HTMLDivElement;
  private get gameIframe() {
    if (!this.gameIframeElement) {
      this.gameIframeElement = document.querySelector(`.story-game`) as HTMLDivElement;
    }
    return this.gameIframeElement;
  }
  private gameSafeRenderArea?: any;
  private get gameSafeArea() {
    // recalculate safe areas for story header and footer
    this.gameSafeRenderArea = getGameSafeArea();
    return this.gameSafeRenderArea;
  }

  private elementRefs: ElementRefs = {};

  constructor(private app: AppController) {
    super();
    this.events = {
      on_game_published: [],
      on_focus_change: [],
      on_chat_mode_change: [],
    };
    this.onVisibilityChange = this.handleVisibilityChange;

    app.addEventListener('app_state_change', this.onAppStateChange);
  }

  private onAppStateChange = () => {
    if (this.app.visibilityState === 'background') {
      // on mobile, reset things or otherwise we'll have a black hole on screen
      // left by the previous opened keyboard that magically disappeared and is not rendering anymore
      if (!this.app.ui.isDesktopLayout) {
        // @TODO: fix-me (need to grab the ref from component and call this here)
        this.elementRefs.textAreaRef?.current?.blur();
        window.scrollTo(0, 0); // this here is what fixes everything, please do not remove.
      }
    }
  };

  private handleVisibilityChange = async () => {
    if (this.isVisible) {
      this.app.viewer.onAiAssistantStart();

      // send event to game for ai to start generating, passing user twitter handler as user id
      // console.warn('>>> AiAssistant - show - AppEvents.GenerateAIStory', { userId: user.id });

      const authToken = await getAccessToken();
      sendGameEvent(AppEvents.GenerateAIStory, { authToken });

      // initialize safe areas
      // set special safe area for bubbles/choices to appear always over the minimized chat area
      setTimeout(() => {
        sendGameEvent('SAFE_AREA' as AppEvents, this.gameSafeArea);

        // start chat in minimized mode. once the user taps on input text it will expand anyways.
        // otherwise we will need to implement sending an event from game, adding types to svs-types, receiving it in viewercontroller, distributing it here etc
        const initialChatMode = 'preview';
        this.setChatMode(initialChatMode);
      }, 0);
    } else {
      this.setChatMode('chat');
    }
  };

  private onFocusChange = () => {
    // for mobile only, adjust elements to match open/close keyboard
    // we dont need to do anything on desktop
    if (this.app.ui.isDesktopLayout) return;

    // show/hide game bubbles and choices, depending on focused state
    sendGameEvent('BubblesOpacity' as AppEvents, { alpha: this.isFocus ? 0.2 : 1, duration: 0.35 });

    // get topbar reference with back and publish button
    const topEl = this.elementRefs.aiTopRef?.current;

    if (this.isFocus) {
      // set topbar and game iframe to match half-screen visor dimensions
      setAppScrolling(false);
      this.setChatMode('chat');

      if (isMobileEmulatedByBrowser()) return;

      // ----------------------------------------------------------------
      // Get layout offset adjustment when opening/closing keyboard

      // this fixed number works just for all phones in browser mode
      let offsetY = 267 * 1; // carles window.innerHeight; //

      // console.error('>>> isPWA', this.app.isPWA);

      // todo: in v2,  this is no longer necessary and just breaks things
      // todo: instead, we need to add +110 pixels to topEl top position
      // Ok, so after a lot of testing, the conclusion is that each phone behaves nearly the same
      // except iphone15pro only sets it 10px lower.
      // we need to distinguish between normal browser and pwa
      // in pwa, micro-adjust offset depending on screen width (fix for phone 15 Pro)
      // if (this.app.isPWA) {
      //   const diff = Math.max(0, (visualViewport?.width || 0 - 390) * 2);
      //   offsetY += 116 - diff;
      // }

      // apply the diff depending on keyboard showing or not
      // setDebugOffsetY(offsetY); // just to debug

      // ----------------------------------------------------------------
      // apply changes to layout using the calculated offset
      if (topEl) topEl.style.top = `${offsetY + 110}px`;

      if (this.gameIframe) {
        this.gameIframe.style.top = `${offsetY * 0.76}px`;
      }
    } else {
      // reset topbar and game iframe to match full-screen visor dimensions
      setAppScrolling(true);
      this.setChatMode(this.aiGenerationStarted ? 'preview' : 'chat');

      if (topEl) topEl.style.top = '0';

      if (this.gameIframe) {
        this.gameIframe.style.top = '0';
      }
    }
  };

  setElementRef = (el: keyof typeof this.elementRefs, ref: MutableRefObject<HTMLDivElement>) => {
    this.elementRefs[el] = ref;
  };

  setGemType = (type: GemType) => {
    this._gemType = type;
  };

  setChatMode = (mode: AiChatMode) => {
    if (this.app.ui.isDesktopLayout) {
      this._chatMode = 'chat';
    } else {
      this._chatMode = mode;
    }

    // console.warn('>>> setChatMode', this._chatMode);

    this.sendEvents(['on_chat_mode_change']);
  };

  setFocused = (focused: boolean) => {
    this._isFocus = focused;
    this.onFocusChange();
  };

  setUserInput = (userInput: string) => {
    this._userInput = userInput;
  };

  submitAiInput = () => {
    // send user input request to ai
    // console.warn('handleSubmit - sending user input request to ai', inputValue);
    sendGameEvent('AiAssistant.Request' as AppEvents, this._userInput);

    this._chatEntryData.push({ requestUid: null, type: 'user', result: this._userInput });

    this._userInput = '';
  };

  resetAiCreationState = () => {
    this.setFocused(false);
    this.setChatMode('chat');
    this._chatEntryData = [];
    this.aiGenerationStarted = false;
  };

  cancel = () => {
    sendGameEvent(AppEvents.GenerateAIStoryCancel);
    this.resetAiCreationState();
    this.aiProgress = 0;
    sendGameEvent('AiAssistant.Leave' as AppEvents);
  };

  /**
   * To be called for DEV only
   */
  devReset = () => {
    sendGameEvent('AiAssistant.StartOver' as AppEvents);
  };

  publish = async () => {
    this.log('publish', this.props);

    sendGameEvent('AiAssistant.PublishGame' as AppEvents);
  };

  onPublished = async (opts: { gameId: number }) => {
    this.log('publish', this.props);

    // cancel current generation  (so autoplay gets canceled when back to feed)
    sendGameEvent(AppEvents.GenerateAIStoryCancel);

    this.aiProgress = 0;

    const gameId = opts.gameId;

    // reset the chat
    this.chatEntryData.length = 0;
    this.chatHistory.length = 0;

    this.error(`publish`, { gameId });

    // Not sure who's going to listen to this
    this.sendEvents([
      {
        name: 'on_game_published',
        params: { gameId },
      },
    ]);

    // reset and navigate to feed
    this.resetAiCreationState();
    this.app.navigate(getRouteWithParams(Routes.GameFeed));

    // Open GemPublishSuccess modal
    // Note: GemProps type for ai assistant is shaped differently to what we usually use outside of it, so let's reshape it
    const shareData = { username: this.app.user.me.username, gameId };
    const gemData = {
      id: this.createProps?.gemData.id,
      type: this._gemType,
      name: this.createProps?.gemData.name,
      img: this.createProps?.gemData.image,
    };
    this.app.modals.open(Modals.GemPublishSuccess, {
      useConfettiEffect: true,
      data: { shareData, gemData },
    });
  };

  handleSuggestionClick = (suggestionType: ChatInputType, selection: AiChatSelection, isFromConfirmation: boolean = false) => {
    this.log('handleSuggestionCLick', { suggestionType, selection, props: this.createProps }, { disabled: true });

    switch (suggestionType) {
      case 'characterSuggestion':
        // console.warn('>>> setting characters...', suggestionId);
        sendGameEvent('AiAssistant.SetCharacter' as AppEvents, selection);
        break;
      case 'locationSuggestion':
        // console.warn('>>> setting background...', suggestionId);
        sendGameEvent('AiAssistant.SetLocation' as AppEvents, selection);
        break;
      case 'craftIngredients':
        // console.warn('>>> setting background...', suggestionId);
        const gemItem = selection as GemItem;
        sendGameEvent('AiAssistant.SetCraftIngredient' as AppEvents, {
          ip: gemItem.ip,
          gameId: gemItem.offchainGameId,
        });
        break;
      case 'ipList':
        console.warn('>>> setting ip...', selection);
        sendGameEvent('AiAssistant.SetIP' as AppEvents, selection);
        break;
      case 'itemCurrencies':
        // console.warn('>>> setting currency...', selection);
        // @todo: remove when publishing happens on the server
        this.setGemType(selection as GemType);
        sendGameEvent('AiAssistant.SetItemCurrency' as AppEvents, selection);
        break;
      case 'gemPreviews':
        // update gameCreateProps state with the selected gem image
        // console.error('>>> re-updating gemData when clicking on a thumbnail', { ...gameCreateProps?.gemData, image: suggestionId });

        const gemSelection = selection as GemImageChoice;
        this.createProps.gemData.image = gemSelection;

        // send message to game with the gem info, so game ai can start generating the gem high-res image
        // console.error('>>> sending selected gem info to game', { gemId: gameCreateProps.gemData.id, gemImage: suggestionId });
        sendGameEvent('AiAssistant.SetGemImage' as AppEvents, { gemImage: gemSelection });
        break;
      default: // 'text' | 'user'
        console.error('>>> just a text, this should never happen...');
        break;
    }
    // this.updateComponent();
  };

  // ===============================================================
  // Game Events
  // ===============================================================
  /**
   * To be called only by ViewerController
   */
  onStoryTap = (opts: StoryTapOpts) => {
    if (!opts.isAutoPlay) {
      this.setChatMode('preview');
    }
  };
  /**
   * To be called only by SVSController
   */
  onAiStart = () => {
    this.aiGenerationStarted = true;
    this.aiProgress = 0;
    this.aiComplete = false;
  };
  /**
   * To be called only by SVSController
   */
  onAiUpdate = (data: AiUpdateProps) => {
    this.log('onAiUpdate', { data, cProps: this.props }, { disabled: true });
    this.aiProgress = data.progress;
    this.createProps = {
      ...this.props,
      ...data.props,
    };
    this.updateComponent();
  };
  /**
   * To be called only by ViewerController
   */
  onAiEnd = () => {
    this.aiComplete = true;
  };
  /**
   * To be called only by ViewerController
   */
  onAiFeedback = (data: AiChatEntry) => {
    let callUpdateComponent = true;

    // each AI feedback type has to be handled in a specific way
    switch (data.type) {
      case 'sessionDump':
        // sessionDump event will give us the necessary data to regenerate the chat
        this.handleAiFeedbackSessionDump(data);
        break;
      case 'gameUpdate':
        if (data.result.diff && data.result.diff.ip) {
          this.pfpIp = data.result.diff.ip;
        }
        break;
      case 'gemProfile':
        // gemProfile type is not for using in the chat, but to silently record the gem name in gameCreateProps,
        // which is used when publishing the game in order to use the right gem information
        this.handleAiFeedbackGemProfile(data);
        callUpdateComponent = false;
        break;
      case 'gemPreviews':
        // console.error('>>> Creating gemPreview chat entries', opts);

        // now we need to adapt the entry array of thumbnails and pick just the mid ones.
        // filter gem previews to use only _low assets
        data.result.previews = filterGemPreviews(data.result.previews);

        this._chatEntryData.push({ requestUid: data.requestUid, type: 'text', result: data.result.text });
        this._chatEntryData.push(data);
        break;
      case 'publishSuccess':
        this.onPublished({ gameId: data.result.gameId });
        break;
      default:
        if (!isChatInputTypeUsableInChat(data.type, data.result)) {
          return;
        }

        this._chatEntryData.push(data);
        break;
    }

    this.log('onAiFeedback', { callUpdateComponent, data }, { disabled: true });

    // Then trigger a component re-render
    if (callUpdateComponent) {
      this.updateComponent();
    }
  };

  private handleAiFeedbackSessionDump = (data: AiChatEntry) => {
    // console.error('>>> received sessionDump notification.', data.result);
    // Find the gemProfile entry an restore the gemId locally so it can be used when selecting a gem thumbnail from the chat history
    const gemProfile = data.result.aiFeedbacks.find((item: any) => item.feedback.type === 'gemProfile');
    // console.error('>>> found gemProfile in sessionDump:', gemProfile);
    if (gemProfile) {
      const gemData = convertGemProfileMessageFromGameToGemProps(gemProfile.feedback.result, this.props);
      // console.error('>>> storing gem id from sessionDump in selector', gemData);
      this.createProps = {
        ...this.props,
        gemData,
      };
    }

    this.log('handleAiFeedbackSessionDump', this.createProps, this.props, { disabled: true });
    this.pfpIp = data.result.game.ip;
    // console.error('>>> checking for game.ip in sessionDump.', data.result.game);
    // Set the chat history. AiChat will pick it up and generate all the chat entries when this changes
    this._chatHistory = generateChatHistory(data.result);
  };

  private handleAiFeedbackGemProfile = (data: AiChatEntry) => {
    // console.error('>>> received gemProfile notification', opts);
    const gemData = convertGemProfileMessageFromGameToGemProps(data.result, this.props);
    // console.error('>>> storing gem id from sessionDump in selector', gemData);
    this.createProps = {
      ...this.props,
      gemData,
    };
  };
}
