import { pipelineApi, getEndpoint } from './base';
import { Endpoints } from './endpoints';
import { ContractAddress, GemzSignatureResponse, WalletAddress } from '@storyverseco/svs-types';

interface CreateGameSignatureRequest {
  creatorAddress: WalletAddress;
  charPassIpContractAddress?: ContractAddress; // @TODO: Should be made required
  offChainGameId: number;
}

export interface CreateGameSignatureResponse {
  signatureData: GemzSignatureResponse;
  lookupId: string;
}

const getCreateGameSignature = async (opts: CreateGameSignatureRequest): Promise<CreateGameSignatureResponse> => {
  return pipelineApi.post(Endpoints.CREATE_GAME_SIGN, {
    data: {
      ...opts,
      buyFirstGem: true,
      // @TODO: This value is derived from AI / story
      charPassIpContractAddress: '0xe1754daa9fd0d845c3a64d6779e73d085a1b43b7',
    },
  });
};

interface BuyGemSignatureRequest {
  holderAddress: WalletAddress;
  offChainGameId: number;
  // choices: WinCondition['conditions'];
}
const getBuyGemSignature = async (opts: BuyGemSignatureRequest): Promise<GemzSignatureResponse> => {
  return pipelineApi.post(Endpoints.GEM_BUY_SIGN, {
    data: {
      ...opts,
      // gameState: {
      //   conditionType: WinConditionType.CHOICES,
      //   conditions: opts.choices,
      // },
    },
  });
};

interface BuyGemConfirmRequest {
  txHash: string;
}
const confirmBuyGem = async (opts: BuyGemConfirmRequest): Promise<any> => {
  return pipelineApi.post(Endpoints.GEM_BUY_CONFIRM, {
    data: {
      ...opts,
    },
  });
};

const mintGem = async (mintData: any) => pipelineApi.post(Endpoints.GEMZ_MINT, mintData);

const getOwnedGamesActivity = async (ownerAddress: string) => {
  return pipelineApi.get(getEndpoint(Endpoints.OWNED_GAMES_ACTIVITY, { ownerAddress }));
};

const getCreatedGamesActivity = async (ownerAddress: string) => {
  return pipelineApi.get(getEndpoint(Endpoints.CREATED_GAMES_ACTIVITY, { ownerAddress }));
};

const getFreeGemGame = async () => {
  return pipelineApi.get(Endpoints.GAME_GET_FREE_GEM);
};

export const gemz = {
  getCreateGameSignature,
  getBuyGemSignature,
  confirmBuyGem,
  getOwnedGamesActivity,
  getCreatedGamesActivity,
  mint: mintGem,
  getFreeGemGame,
};
