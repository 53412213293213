import './CurrencyAnimationEffect.scss';
import { useEffect, useRef, useState } from 'react';
import TweenOne from 'rc-tween-one';
import { controller } from 'lib/Controller';
import { GemType } from '@storyverseco/svs-types';
import { useReRender } from 'hooks/useReRender';

type Point = { x: number; y: number };

function getCurrencyType(gemType: GemType) {
  if (gemType == 'craftable') return 'coin';
  return gemType;
}

const CurrencyAnimationEffect = (opts: {}) => {
  useReRender({ id: 'DesktopNavBar:BottomNavBar', listener: controller.ui.attachEventListener('on_change') });

  let { showCurrencyAnimation: show } = controller.ui;

  // get the animation type from current modal options (null | buy | sell)
  const animationType = controller.modals.current.useAnimationEffect;

  // ---------------------------------------------------
  // get gem data
  const dataItem = controller.modals.current.data?.item;
  const currentItem = dataItem ?? controller.feed.currentItem;
  const { gem, info } = currentItem;

  // get price value for either buy or sell mode
  let rawPrice = Number(info?.gemSellRawPrice || 0);
  let gemPrice = Number(info?.gemSellPrice || 0);
  if (animationType === 'buy') {
    gemPrice = Number(info?.gemBuyPrice || 0);
    rawPrice = Number(info?.gemBuyRawPrice || 0);
  }

  gemPrice = Number.isNaN(gemPrice) ? 0 : gemPrice;
  rawPrice = Number.isNaN(rawPrice) ? 0 : rawPrice;

  show = gemPrice === 0 ? false : show;
  // ---------------------------------------------------
  // get start/end positions

  const [posStart, setPosStart] = useState<Point>(null);
  const [posEnd, setPosEnd] = useState<Point>(null);
  const [posLabel, setPosLabel] = useState<Point>(null);

  function getPoint(id: string, centerX = false, centerY = false) {
    const elm = document.getElementById(id);

    if (elm) {
      const b = elm.getBoundingClientRect();
      const p = { x: b.left + (centerX ? b.width / 2 : 0), y: b.top + (centerY ? b.height / 2 : 0) };
      return p;
    }

    return null;
  }

  useEffect(() => {
    if (show) {
      // get start(center of modal) and end (currency slot) points
      const start = getPoint('modal-box', true, false);
      const end = getPoint(`topbar-currency-${getCurrencyType(gem.type)}`, true, true);

      // reverse start and end positions if we are buying
      if (start) animationType === 'sell' ? setPosStart(start) : setPosStart(end);
      if (end) animationType === 'sell' ? setPosEnd(end) : setPosEnd(start);

      // label stays always on start position
      setPosLabel(start);
    }
  }, [show]);

  if (posStart === null || posEnd === null) {
    return null;
  }

  // ---------------------------------------------------
  // render and animate

  const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <div className="currency-animation-effect">
      {items.map((item, index) => (
        <Item key={index} animationType={animationType} index={index} start={posStart} end={posEnd} gemData={gem} />
      ))}
      <TweenOne
        className="currency-label"
        style={{ transform: `translate(${posLabel.x}px, ${posLabel.y}px)` }}
        animation={{
          // note that Children plugin is being pushed in App.tsx
          Children: {
            value: Number(gemPrice || rawPrice),
            floatLength: gem.type === 'diamond' ? 3 : 0,
            formatMoney: true,
          },
          duration: 1000,
          delay: 0,
        }}
      >
        <div>+{0}</div>
      </TweenOne>
      <div className="currency-animation-point start" style={{ left: posStart.x, top: posStart.y }}></div>
      <div className="currency-animation-point end" style={{ left: posEnd.x, top: posEnd.y }}></div>
    </div>
  );
};

export default CurrencyAnimationEffect;

// render each animated currency item

const Item = (opts: { index: number; animationType: string; start: Point; end: Point; gemData: { name: string; type: GemType; img: string } }) => {
  const itemRef = useRef<HTMLDivElement | undefined>();
  const iconRef = useRef<HTMLDivElement | undefined>();

  const { index, start, end, gemData } = opts;
  // console.warn('>>> Coin start', start, 'end', end);

  const image = controller.craft.getAsset(getCurrencyType(gemData.type));

  const duration = 350 + Math.random() * 150;
  const delay = index * 100; //  + Math.random() * 50;
  const rot = (-360 + Math.random() * 360 * 2) * (Math.random() * 3);

  // if selling coins shrink, and if buying coins grow instead
  const scale = 0.25 + Math.random() * 0.75;
  const scaleStart = opts.animationType === 'sell' ? scale : scale * 0.5;
  const scaleEnd = opts.animationType === 'sell' ? scale * 0.5 : scale;

  return (
    <>
      <TweenOne
        ref={itemRef}
        className="currency-item"
        style={{ transform: `translate(${start.x}px, ${start.y}px)` }}
        animation={{
          x: end.x,
          y: end.y,
          duration: duration,
          delay: delay,
          onStart: () => {},
          onUpdate: () => {},
          onComplete: () => {
            itemRef?.current?.setAttribute('style', 'display: none;');
          },
        }}
      >
        <TweenOne
          ref={iconRef}
          className="currency-item-icon"
          style={{ backgroundImage: `url(${image}`, transform: `translate(-32px, -32px) scale(${scaleStart})`, visibility: 'hidden' }}
          animation={{
            scale: scaleEnd,
            rotate: rot,
            duration: duration,
            delay: delay,

            onStart: () => {
              (iconRef.current as HTMLDivElement).style.visibility = 'visible';
            },
          }}
        />
      </TweenOne>
    </>
  );
};
