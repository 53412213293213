import { ViewerController } from './controllers/ViewerController';
import { AssetsController } from './controllers/AssetsController';
import { ModalController } from '../features/Modals/ModalController';
import { ProfileController } from './controllers/ProfileController';
import { CraftController } from './controllers/CraftMissionsController';
import { loadCfg } from './loadCfg';
import { ConfigClient } from '@storyverseco/svs-config-client';
import { UIController } from './controllers/UIController';
import { ShareController } from './controllers/ShareController';
import { EventListener } from './EventListener';
import { PrivyController } from './controllers/auth/PrivyController';
import { UserController, UserControllerEvent } from './controllers/auth/UserController';
import { SmartAccountService } from './web3/SmartAccountService';
import { env } from 'config/env';
import { Routes, StaticRoutes, gamePages, getRouteWithParams } from 'router';
import { GemzController } from './controllers/GemzController';
import { isMobile } from './device';
import { UnsignedTransactionRequest } from '@privy-io/react-auth';
import { GameFeedController } from 'pages/gameFeed/GameFeedController';
import { FollowController } from './controllers/FollowController';
import { NotificationsController } from './controllers/NotificationsController';
import { FingerprintController } from './controllers/FingerprintController';
import { QueryParams, sessionOpts } from 'config/consts';
import { clone } from './clone';
import { HelpOpts } from 'types/types';
import { RoomListController } from './controllers/RoomListController';
import { KeyboardController } from './controllers/KeyboardController';
import { Modals } from '../features/Modals';

export enum ControllerState {
  Idle = 'ControllerState/Idle',
  Initialising = 'Controller/Initialising',
  Ready = 'Controller/Ready',
}

type AppState = 'background' | 'idle' | 'foreground';

const visibilityChangeEvents = ['pageshow', 'focus', 'blur', 'visibilitychange', 'resume'];

export class AppController extends EventListener {
  logConfig = {
    color: '#7f7bb6',
  };

  // Set class events ASAP
  events = {
    app_state_change: [],
    on_auth_change: [],
    on_navigate: [], // this should only be used by react to navigate
    on_route_change: [],
  };

  private readyResolver?: (v: void) => void;
  private readyPromise = new Promise((resolve) => {
    this.readyResolver = resolve;
  });

  private initPromise?: Promise<void>;

  private _state = ControllerState.Idle;

  private _isPWA = false;

  private _appState: AppState = 'foreground';

  private _isAuth = false;

  private _helpReferralOpts?: HelpOpts;

  // Used when we start the app loading a specific game
  private firstGame?: number;

  private pwaPromptEnabled = false;

  private isHelpingFriend = sessionOpts.needsHelp !== null;
  private isGettingHelpFromFriend = sessionOpts.offeringHelp !== null;
  private get isHelpSession() {
    return this.isHelpingFriend || this.isGettingHelpFromFriend;
  }

  get visibilityState() {
    return this._appState;
  }

  get needsInit() {
    return this._state === ControllerState.Idle;
  }

  get isReady() {
    return this._state === ControllerState.Ready;
  }

  get isPWA() {
    return this._isPWA;
  }

  get isWebMobile() {
    return !this._isPWA && isMobile();
  }

  get isAuth() {
    return this._isAuth;
  }

  get waitForReady() {
    return this.readyPromise;
  }

  get helpAFriendOpts() {
    return this._helpReferralOpts ? clone(this._helpReferralOpts) : undefined;
  }

  get hasPWAPrompt() {
    return this.pwaPromptEnabled;
  }

  public fingerprint = new FingerprintController(this);

  public privy = new PrivyController(this, {
    onLogout: () => {
      this.privy.reset();
      this.smartWallet.reset();
      this.user.reset();
      this.navigate(getRouteWithParams(Routes.GameFeed));
    },
  });

  private _isPointsFeatureEnabled = false;
  get isPointsFeatureEnabled() {
    return this._isPointsFeatureEnabled;
  }

  public viewer = new ViewerController(this);

  // must be before feed and profile
  public follow = new FollowController(this);

  public user = new UserController(this);

  public feed = new GameFeedController(this);

  public assets = new AssetsController();

  public modals = new ModalController(this);

  public profile = new ProfileController(this);

  public ui = new UIController();

  public share = new ShareController();

  public roomList = new RoomListController(this);

  private smartWallet = new SmartAccountService(env.l2Chain);

  public gemzOps = new GemzController(this);

  public currentRoute: Routes;

  public notifications = new NotificationsController(this);

  public craft = new CraftController(this);

  public keyboard = new KeyboardController(this);

  private _config?: ConfigClient;
  public get config() {
    if (!this._config) {
      throw new Error(`Error (Controller): Trying to access config before Controller.init.`);
    }
    return this._config;
  }

  constructor() {
    super();
    // No need to remove them because they are session wide
    this.addWindowEventListeners();

    this.user.addEventListener(UserControllerEvent.OnUserUpdate, () => {
      const newAuth = Boolean(this.user.me);
      this.log('onAuthChange', { newAuth, c: this._isAuth });
      if (newAuth) {
        this.fingerprint.setUserId(this.user.me.id);
      }
      if (newAuth !== this._isAuth) {
        this._isAuth = newAuth;
        this.sendEvents(['on_auth_change']);
      }
    });
  }

  private onInitComplete = () => {
    this._state = ControllerState.Ready;
    this.readyResolver();
    console.log('App Controller Ready (Timestamp): ', new Date().toLocaleTimeString(undefined, window.gzTimeFormat));

    this.viewer.viewerReady.then(() => {
      this.log('viewerReady callback', this.feed.currentItem);
      if (gamePages.includes(this.currentRoute)) {
        this.viewer.playStory(this.feed.currentItem.storyUrl);
        if (this.isHelpingFriend) {
          // this.modals.open(Modals.HelpFriend);
          const outcome = this.feed.currentItem.info?.outcome;
          if (outcome) {
            const winner = outcome.state === 'unlocked' && outcome.url;
            if (winner) {
              this.modals.open(Modals.HelpFriend, {
                data: { winner: true },
              });
            } else {
              // loser
              // clear the help wanted state
              this._helpReferralOpts.hwid = undefined;
              this.modals.open(Modals.GameOutcomeLose);
            }
          } else {
            this.modals.open(Modals.HelpFriend);
          }
        }
      }
    });

    // When privy is ready, then setup smart wallet and then initialise user
    this.privy.onUserReady.then(async () => {
      this.log('onPrivyUserReady', this.privy.eoa);
      await this.smartWallet.init(this.privy.eoa);
      this.user.init(this.privy.privyUser, this.smartWallet.address).then(() => {
        // start polling activity feed when user init is complete
        this.notifications.restart({ updateNow: true });
      });
    });

    this.log('onInitComplete');
  };

  init = async () => {
    if (this.initPromise) {
      return this.initPromise;
    }
    this._state = ControllerState.Initialising;
    this.log('init');
    // Make sure we have a fingerprint before doing anything else
    await this.fingerprint.isReady;
    this.initPromise = new Promise(async (resolve) => {
      await Promise.all([
        this.feed.loadFirstPage(this.firstGame),
        loadCfg().then((cfg) => {
          this._config = cfg;
        }),
      ]);
      this.log('feed item first load complete');
      this.onInitComplete();
      resolve();
    });
    return this.initPromise;
  };

  setIsPWA = (isPWA: boolean) => {
    this._isPWA = isPWA;
  };

  togglePWAPrompt = (enabled: boolean) => {
    this.pwaPromptEnabled = enabled;
  };

  // To be called only by useNavigation hook (react)
  setRoute = (route: StaticRoutes) => {
    if (this.currentRoute !== route) {
      this.currentRoute = route;
      this.log('setRoute', { route });
      this.sendEvents(['on_route_change']);
    }
  };

  // Sends an event to react to navigate to a page;
  navigate = (route: StaticRoutes) => {
    this.sendEvents([
      {
        name: 'on_navigate',
        params: { route },
      },
    ]);
  };
  // Not quite sure where to put this
  sendTx = async (tx: UnsignedTransactionRequest, paymaster: boolean = false) => {
    if (!this.smartWallet.isReady) {
      console.warn(`Cannot send l2 tx without smart provider.`);
      return;
    }

    console.log(`Send ${paymaster ? 'Paymaster' : 'Regular'} TX`);

    return paymaster ? this.smartWallet.sendPaymasterTx(tx) : this.smartWallet.sendTx(tx);
  };

  withdraw = async (amountEth: string, toWalletAddress: string) => {
    try {
      await this.smartWallet.withdraw(amountEth, toWalletAddress);
      this.modals.open(Modals.Error, {
        title: 'Withdraw Success',
        message: 'The money should be in your wallet any moment now',
      });
      this.user.fetchBalance('diamond');
    } catch {
      this.modals.open(Modals.Error);
    }
  };

  startSessionWithGame = (gameId: number) => {
    console.log('startSessionWithGame', gameId);
    this.firstGame = gameId;
    this.setHelpSessionOpts(gameId);
  };

  private setHelpSessionOpts = (gameId: number) => {
    if (this.isHelpSession) {
      // TODO: Need an endpoint to get the participant name and image from either HelpWantedId and HelpOfferId.
      // Then, set values in _helpReferralOpts. These will later be used to show in modal dialogs.
      // We don't want to pass these through QueryParams because they could be tampered with.
      const key = sessionOpts.needsHelp ? QueryParams.HelpWantedID : QueryParams.HelpOfferedID;
      this._helpReferralOpts = {
        [key]: sessionOpts.needsHelp || sessionOpts.offeringHelp,
        gameId,
        participantName: '',
        participantImage: '',
      };
    }
  };

  private addWindowEventListeners = () => {
    // Visibility change event listeners
    visibilityChangeEvents.forEach((evt) => {
      window.addEventListener(evt, this.handleVisibilityChange, { capture: true });
    });

    // window.addEventListener('onbeforeunload', this.onAppExit);
  };

  private handleVisibilityChange = () => {
    const computedAppState = (() => {
      if (document.visibilityState === 'hidden') return 'background';
      if (document.hasFocus()) return 'foreground';
      return 'idle';
    })();

    if (this._appState !== computedAppState) {
      this.warn(`App state has changed from '${this._appState}' to '${computedAppState}'`);
      this._appState = computedAppState;
      this.sendEvents(['app_state_change']);
      if (computedAppState === 'background') {
        // Save latest privy token whenever we lose app focus
        if (this.privy.privyUser) {
          this.privy.getToken().then((token) => {
            this.user.setPrivyToken(token);
          });
        }
      }
    }
  };
}

export const controller = new AppController();

// This gives us APP variable in the dev console, so we can inspect controller states
if (sessionOpts.localDev) {
  // @ts-ignore
  window.APP = controller;
}
