import { FollowRelationship } from '@storyverseco/svs-types';
import { FollowBtnType } from './followsTypes';
export enum FollowState {
  Idle = 'idle',
  FirstLoad = 'firstload',
  NotFollowing = 'notfollowing',
  NotFollowingProgress = 'notfollowingprogress',
  Following = 'following',
  FollowingProgress = 'followingprogress',
}

export const startingFollowStateMap = {
  [FollowRelationship.None]: FollowState.NotFollowing,
  [FollowRelationship.Follower]: FollowState.NotFollowing,
  [FollowRelationship.Following]: FollowState.Following,
  [FollowRelationship.Mutual]: FollowState.Following,
};

export const getFollowBtnTypeFromStates = (relationship: FollowRelationship, followState: FollowState): FollowBtnType => {
  if (relationship === FollowRelationship.Following) {
    if (followState === FollowState.NotFollowingProgress) {
      // optimistically show "follow" button as if they've already unfollowed
      return FollowBtnType.Follow;
    } else {
      return FollowBtnType.Unfollow;
    }
  } else if (relationship === FollowRelationship.Mutual) {
    if (followState === FollowState.NotFollowingProgress) {
      // optimistically show "follow back" button as if they've already unfollowed the mutual
      return FollowBtnType.FollowBack;
    } else {
      return FollowBtnType.Mutuals;
    }
  } else if (relationship === FollowRelationship.Follower) {
    if (followState === FollowState.FollowingProgress) {
      // optimistically show "follow back" button as if they've already followed the follower
      return FollowBtnType.Mutuals;
    } else {
      return FollowBtnType.FollowBack;
    }
  } else {
    if (followState === FollowState.FollowingProgress) {
      // optimistically show "unfollow" button as if they've already followed
      return FollowBtnType.Unfollow;
    } else {
      return FollowBtnType.Follow;
    }
  }
};
