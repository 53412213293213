import { FollowData, FollowRelationship } from '@storyverseco/svs-types';
import { FollowsProps } from '../apis/follows';
import { CachedFollows, CachedRelationship, FollowsPair, StrippedCachedFollows } from '../followsTypes';
import { localCache } from './LocalCache';
import { FeatureCache } from './FeatureCache';

const EXPIRE_DURATION = 1000 * 60 * 60 * 24; // 1 day in ms
const MAX_CACHED_FOLLOWS = 20; // i.e. max profiles
const MAX_RELATIONSHIPS = 1000; // max entries in combined relationship map

const getKeyFromProps = (followsProps: FollowsProps): string => {
  const { userId, username, walletAddress } = followsProps;
  // using a prefix prevents possible userId and username collisions
  if (userId) {
    return `id:${userId}`;
  }
  if (username) {
    return `username:${username}`;
  }
  if (walletAddress) {
    return `walletAddress:${walletAddress}`;
  }
  throw new Error('getKeyFromProps: Needs a userId, username, or walletAddress');
};

const getRelationshipKey = (sourceUserId: number | string, targetUserId: number | string): string => {
  return `${sourceUserId}:${targetUserId}`;
};

/**
 * Recombine an entry and `relationshipMap` with data related to `sessionUserId` to produce
 * the original follow data.
 * @param strippedFollows A profile's follow data (with no relationship data).
 * @param relationshipMap The overall relationship map.
 * @param sessionUserId The user ID to extract relationship data from the map and correlated follow lists.
 * @returns Original follow data.
 */
const recombobulateCachedFollows = (
  strippedFollows: StrippedCachedFollows,
  relationshipMap: Record<string, CachedRelationship>,
  sessionUserId: string,
): CachedFollows => {
  const cachedFollows: CachedFollows = {
    followers: [],
    followings: [],
    expireTime: strippedFollows.expireTime,
  };

  // followers
  for (const strippedFollower of strippedFollows.followers ?? []) {
    const relKey = getRelationshipKey(strippedFollower.followerId, sessionUserId);
    const follower: FollowData = {
      ...strippedFollower,
      relationTo: sessionUserId as unknown as number,
      relationship: relationshipMap[relKey]?.relationship ?? FollowRelationship.None,
    };
    cachedFollows.followers.push(follower);
  }

  // followings
  for (const strippedFollowing of strippedFollows.followings ?? []) {
    const relKey = getRelationshipKey(strippedFollowing.followingId, sessionUserId);
    const following: FollowData = {
      ...strippedFollowing,
      relationTo: sessionUserId as unknown as number,
      relationship: relationshipMap[relKey]?.relationship ?? FollowRelationship.None,
    };
    cachedFollows.followings.push(following);
  }

  return cachedFollows;
};

/**
 * Recombine `strippedFollowsMap` and `relationshipMap` with data related to `sessionUserId`
 * to produce the original follow data map.
 * @param strippedFollowsMap Map of stripped cached follows keyed by profile.
 * @param relationshipMap Overall relationship map.
 * @param sessionUserId The user ID to extract relationship data from the map and correlated follow lists.
 * @returns
 */
const recombobulateCachedFollowsMap = (
  strippedFollowsMap: Record<string, StrippedCachedFollows>,
  relationshipMap: Record<string, CachedRelationship>,
  sessionUserId: string,
) => {
  const strippedEntries = Object.entries(strippedFollowsMap);
  const entries = strippedEntries.map(([key, strippedCachedFollows]) => [
    key,
    recombobulateCachedFollows(strippedCachedFollows, relationshipMap, sessionUserId),
  ]);
  return Object.fromEntries(entries);
};

/**
 * Separate `cachedFollows` into separate `strippedFollows` and `relationshipMap` data.
 * @param cachedFollows the object with follow lists with expire property.
 * @returns `strippedFollows`: stripped follows data (no relationship data); `relationshipMap`: map of relationships between users and the session/relationTo IDs.
 */
const discombobulateCachedFollows = (
  cachedFollows: CachedFollows,
): {
  strippedFollows: StrippedCachedFollows;
  relationshipMap: Record<string, CachedRelationship>;
} => {
  const relationshipMap: Record<string, CachedRelationship> = {};
  const strippedFollows: StrippedCachedFollows = {
    followers: [],
    followings: [],
    expireTime: cachedFollows.expireTime,
  };

  // followers
  for (const follower of cachedFollows.followers ?? []) {
    const relKey = getRelationshipKey(follower.followerId, follower.relationTo);

    // relationship data into separate map
    relationshipMap[relKey] = {
      relationship: follower.relationship,
      expireTime: cachedFollows.expireTime,
    };

    // clone follower data
    const followerUser = {
      ...follower.followerUser,
    };
    const strippedFollower = {
      ...follower,
      followerUser,
    };

    // remove relationTo and relationship properties to be generic follow lists
    delete strippedFollower.relationTo;
    delete strippedFollower.relationship;
    strippedFollows.followers.push(strippedFollower);
  }

  // followings
  for (const following of cachedFollows.followings ?? []) {
    const relKey = getRelationshipKey(following.followingId, following.relationTo);

    // relationship data into separate map
    relationshipMap[relKey] = {
      relationship: following.relationship,
      expireTime: cachedFollows.expireTime,
    };

    // clone following data
    const followingUser = {
      ...following.followingUser,
    };
    const strippedFollowing = {
      ...following,
      followingUser,
    };

    // remove relationTo and relationship properties to be generic follow lists
    delete strippedFollowing.relationTo;
    delete strippedFollowing.relationship;
    strippedFollows.followings.push(strippedFollowing);
  }

  return {
    strippedFollows,
    relationshipMap,
  };
};

/**
 * Separate each entry of `cachedFollowsMap` and append separated `strippedFollows`
 * and `relationshipMap` into their respective maps.
 *
 * @param cachedFollowsMap Map of cached follow data, keyed by profile.
 * @returns `strippedFollowsMap`: combined follows data without relationship data;
 * `relationshipMap`: overall relationships between users.
 */
const discombobulateCachedFollowsMap = (
  cachedFollowsMap: Record<string, CachedFollows>,
): {
  strippedFollowsMap: Record<string, StrippedCachedFollows>;
  relationshipMap: Record<string, CachedRelationship>;
} => {
  const strippedFollowsMap: Record<string, StrippedCachedFollows> = {};
  let relationshipMap: Record<string, CachedRelationship> = {};
  const entries = Object.entries(cachedFollowsMap);

  // separate each cached follows entry and append separated data into their
  for (const [key, cachedFollows] of entries) {
    const { strippedFollows, relationshipMap: relMap } = discombobulateCachedFollows(cachedFollows);

    // combine relationship maps
    relationshipMap = {
      ...relationshipMap,
      ...relMap,
    };

    // add to overall stripped follow data map
    strippedFollowsMap[key] = strippedFollows;
  }

  return {
    strippedFollowsMap,
    relationshipMap,
  };
};

/**
 * Handles caching of follow data.
 *
 * Each profile's followers and following lists are technically global, but
 * the relationship data in each item of both lists are only related to the
 * session user.
 *
 * So the relationship data is extracted and is stored separately,
 * while stripping the follow data of its relationship properties, making follow
 * data safe for global usage (i.e. can be used between different session users).
 * The relationship data is stored as a combined map of each users relationship.
 *
 * Upon initializing with session user ID, the follow lists and relationship map
 * are recombined to produce the original lists with relationship data related to
 * the session user. There is a chance that two users' relationship won't exist
 * between two different session users, but should be updated once the second user
 * visits that profile.
 */
export class FollowsCache {
  private cache: Record<string, CachedFollows> = {};

  private sessionUserId: string = undefined;

  get initted() {
    return typeof this.sessionUserId !== 'undefined';
  }

  /**
   * Initialize cache with the session user ID.
   *
   * Combines stripped follow data and relationship map into the original
   * follow data with relationship data related to session user.
   * @param sessionUserId
   * @returns
   */
  async init(sessionUserId: string) {
    if (!sessionUserId) {
      throw new Error('FollowsCache.init error: missing sessionUserId');
    }

    if (this.sessionUserId === sessionUserId) {
      return;
    }
    this.sessionUserId = sessionUserId;

    // wait for local cache to be ready
    await localCache.isReady;

    // load lists and relationships from cache
    const strippedFollowsMap = localCache.get('followsCache') ?? {};
    const relationshipMap = localCache.get('relationshipCache') ?? {};

    // recombine lists and relationships into "original" follow data map
    const followsMap = recombobulateCachedFollowsMap(strippedFollowsMap, relationshipMap, sessionUserId);
    this.cache = followsMap;

    // remove any expired/extra entries
    this.validateCache();
  }

  /**
   * Set the follow list(s) for a profile (keyed by `followsProps`).
   * @param followsProps Profile's key (userId, username, or wallet address).
   * @param follows Follow list(s).
   */
  set(followsProps: FollowsProps, follows: FollowsPair) {
    if (!this.initted) {
      throw new Error('FollowsCache.set error: not yet initted');
    }
    const key = getKeyFromProps(followsProps);
    this.cache[key] ??= {
      expireTime: 0,
    };
    // only apply to lists that exist in the argument
    if (follows.followers) {
      this.cache[key].followers = follows.followers;
    }
    if (follows.followings) {
      this.cache[key].followings = follows.followings;
    }
    this.cache[key].expireTime = Date.now() + EXPIRE_DURATION;
    this.commit();
  }

  /**
   * Get the cached follow lists for a profile.
   * @param followsProps Profile's key (userId, username, or wallet address)
   * @returns The cached follow lists for a profile.
   */
  get(followsProps: FollowsProps): CachedFollows | undefined {
    if (!this.initted) {
      throw new Error('FollowsCache.get error: not yet initted');
    }
    const key = getKeyFromProps(followsProps);
    return this.cache[key];
  }

  /**
   * Clears the cache and uninits the session user for follow cache.
   */
  clear() {
    this.cache = {};
    this.sessionUserId = undefined;
  }

  /**
   * Commits the current data into cache. Mostly for internal use as it is
   * automatically called when using `set()`.
   *
   * Separates the relationship data from the follow lists and stores into separate
   * data in the cache.
   */
  commit() {
    if (!this.initted) {
      throw new Error('FollowsCache.commit error: not yet initted');
    }

    // remove any expired/extra entries before committing to cache
    this.validateCache(true);

    // separate follow lists and relationships
    const { strippedFollowsMap, relationshipMap } = discombobulateCachedFollowsMap(this.cache);

    // load from cache then append to (or replace) existing cached data
    const cachedStrippedFollowsMap = localCache.get('followsCache') ?? {};
    let cachedRelationshipMap = localCache.get('relationshipCache') ?? {};
    for (const [key, strippedFollows] of Object.entries(strippedFollowsMap)) {
      cachedStrippedFollowsMap[key] = strippedFollows;
    }
    for (const [key, relationship] of Object.entries(relationshipMap)) {
      cachedRelationshipMap[key] = relationship;
    }

    // remove any expired/extra relationship entries
    cachedRelationshipMap = this.sanitizeRelationshipCache(cachedRelationshipMap);

    // commit lists and relationship data into cache
    localCache.set('followsCache', cachedStrippedFollowsMap);
    localCache.set('relationshipCache', cachedRelationshipMap);
  }

  /**
   * Remove any relationship data that have expired, as well as limit the number of
   * relationship entries (keeping the newest entries).
   *
   * This returns the validated relationship map.
   *
   * @param relationshipCache The original relationship map.
   * @returns The updated relationship map.
   */
  private sanitizeRelationshipCache(relationshipCache: Record<string, CachedRelationship>) {
    relationshipCache = localCache.get('relationshipCache') ?? {};
    const entries = Object.entries(relationshipCache);
    let dirty = false;

    // check for expiration
    const now = Date.now();
    let validEntries = entries.filter(([_key, entry]) => now < entry.expireTime);
    if (validEntries.length !== entries.length) {
      dirty = true;
    }

    // check for max entries
    if (validEntries.length > MAX_RELATIONSHIPS) {
      // latest to earliest expire time
      validEntries.sort(([_aKey, aEntry], [_bKey, bEntry]) => bEntry.expireTime - aEntry.expireTime);
      validEntries = validEntries.slice(0, MAX_RELATIONSHIPS);
    }

    return dirty ? Object.fromEntries(validEntries) : relationshipCache;
  }

  /**
   * Remove any profiles that have expired, as well as limit the number of profiles
   * (keeping the newest profiles).
   *
   * This updates the cache in place.
   *
   * @param noCommit if true, changes will not save to cache.
   */
  private validateCache(noCommit = false) {
    const entries = Object.entries(this.cache);
    let dirty = false;

    // check for expiration
    const now = Date.now();
    let validEntries = entries.filter(([_key, entry]) => now < entry.expireTime);
    if (validEntries.length !== entries.length) {
      dirty = true;
    }

    // check for max entries
    if (validEntries.length > MAX_CACHED_FOLLOWS) {
      // latest to earliest expire time
      validEntries.sort(([_aKey, aEntry], [_bKey, bEntry]) => bEntry.expireTime - aEntry.expireTime);
      validEntries = validEntries.slice(0, MAX_CACHED_FOLLOWS);
    }

    if (dirty) {
      this.cache = Object.fromEntries(validEntries);
      if (!noCommit) {
        this.commit();
      }
    }
  }
}

export const followsCache = new FollowsCache();
