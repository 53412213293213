import { ConditionWaiter } from './ConditionWaiter';

/**
 * Waiter with an async function wrap method to debounce multiple calls while
 * the async call is in progress. Debounced calls will get the same result as
 * the first call, or the same reject if the async function rejects.
 */
export class DebounceWaiter<T> extends ConditionWaiter<T> {
  private inProgress = false;

  constructor() {
    super(undefined, () => !this.inProgress);
  }
  setInProgress(inProgress: boolean): void {
    this.inProgress = inProgress;
    if (!inProgress) {
      super.set(undefined);
    }
  }
  isInProgress(): boolean {
    return this.inProgress;
  }

  /**
   * Wraps a promise-creating no-param function where subsequent calls is
   * awaited while the first call is in progress.
   * @param fn
   * @returns
   */
  async wrap(fn: () => Promise<T>): Promise<T> {
    if (this.isInProgress()) {
      return await this.wait();
    }
    this.setInProgress(true);
    try {
      const result = await fn();
      this.set(result);
      return result;
    } catch (e) {
      this.reject(e);
      throw e; // rethrow
    }
  }

  /**
   * Wraps a function definition and returns a wrapped debounced waiter
   * function with the same signature.
   * @param fn
   * @returns
   */
  defWrap<F extends { (...args: any[]): Promise<T> }>(fn: F): F {
    return ((...args: any[]): Promise<T> => this.wrap(() => fn(...args))) as F;
  }

  override set(value: T): void {
    this.inProgress = false;
    // we trigger the resolves, then unset
    super.set(value);
    super.set(undefined);
  }

  override reject(reason: any): void {
    this.inProgress = false;
    super.reject(reason);
  }
}

/**
 * We store waiters here for debug purposes.
 */
export const wrappedWaiters: DebounceWaiter<any>[] = [];

/**
 * Wraps a function with a debounce waiter wrapper to automatically await subsequent calls
 * while the first call is in progress, and return the first call's results or rejection.
 * @param fn
 * @returns
 */
export function debounceWaiterWrap<T, F extends { (...args: any[]): Promise<T> }>(fn: F): F {
  const waiter = new DebounceWaiter<T>();
  wrappedWaiters.push(waiter);
  return waiter.defWrap(fn);
}
