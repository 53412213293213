import { images } from '../../assets/assets';
import { controller } from '../../lib/Controller';
import { UIElementKey } from '../../lib/controllers/UIController';
import './TopBar.scss';

export function TopBarSearch() {
  const { show } = controller.ui.elements[UIElementKey.TopBarSearch];

  if (!show) {
    return null;
  }

  return (
    <div className="topbar-search search">
      <div className="icon-search-white svg-shadow" style={{ backgroundImage: `url(${images.iconSearch})` }} />
    </div>
  );
}

export default TopBarSearch;
