import React from 'react';

export interface DynamicMappedComponentProps<T extends symbol | string | number, P> {
  type: T;
  components: Record<T, React.ComponentType<P>>;
}

export function DynamicMappedComponent<T extends symbol | string | number, P = undefined>(props: DynamicMappedComponentProps<T, P> & P) {
  const { type, components, ...componentProps } = props;

  const Component = components[type] as React.ComponentType<P>;

  if (!Component) {
    return undefined;
  }

  return <Component {...(componentProps as P)} />;
}
