import { User as PrivyUser } from '@privy-io/react-auth';
import OneSignal from 'react-onesignal';
import { ApplyPlayRewardCoinRequest, DepositState, GemType, MyGemzUser, WalletAddress } from '@storyverseco/svs-types';
import { AppController } from 'lib/Controller';
import { EventListener } from 'lib/EventListener';
import { api } from 'lib/apis';
import { FeatureCache } from 'lib/cache/FeatureCache';
import { l2Client } from 'lib/web3/publicClients';
import { formatEther } from 'viem';
import { UserCache } from './types';
import isEqual from 'lodash.isequal';
import { Modals } from 'features/Modals';
import { OnboardingHandler } from '../OnboardingController';
import { sessionOpts } from 'config/consts';

enum PrivyAuthState {
  Idle = '@PrivyAuthState/Idle',
  Unauth = '@PrivyAuthState/Unauth',
  Auth = '@PrivyAuthState/Auth',
}

export enum UserControllerEvent {
  OnUserUpdate = 'UserControllerEvent/OnUserUpdate',
  OnBalanceUpdate = 'UserControllerEvent/OnBalanceUpdate',
  OnHoldingsUpdate = 'UserControllerEvent/OnHoldingsUpdate',
}

const OneSignalDeferred = window.OneSignalDeferred || [];
export class UserController extends EventListener {
  private _diamondBalancePoll: NodeJS.Timeout;

  private _initPromise: Promise<void>;
  private _initResolve: () => void;

  private cache = new FeatureCache<UserCache>('userCache', () => ({
    coinBalance: 0,
    diamondBalance: 0,
    isDepositPending: false,
    portfolio: 0,
    rewards: 0,
    holdings: undefined,
  }));

  private get _diamondBalance() {
    return BigInt(this.cache.get('diamondBalance'));
  }

  public privyAuth = PrivyAuthState.Idle;

  private showClaimGemModal = false;

  private claimFailed = false;

  private _onboarding = new OnboardingHandler(this.app, this);

  get me() {
    const gemzUser = this.cache.get('gemzUser');

    if (!gemzUser) {
      return undefined;
    }

    return {
      ...gemzUser,
      coinBalance: Math.floor(Number(this.cache.get('coinBalance'))),
      diamondBalance: Number(formatEther(this._diamondBalance)),
      portfolio: this.cache.get('portfolio'),
      rewards: this.cache.get('rewards'),
      holdings: this.cache.get('holdings'),
      isDepositPending: gemzUser.attributes.depositState === DepositState.WaitingForConfirmation,
      showClaimFreeGem: this.showClaimGemModal,
      isOnboarding: this._onboarding.isOnboarding,
    };
  }

  get onboarding() {
    return {
      connectWithX: this._onboarding.connectWithX,
      submitInviteCode: this._onboarding.submitInviteCode,
      usersToFollow: this._onboarding.usersToFollow,
      twitterStatusError: this._onboarding.twitterStatusError,
      clearTwitterStatusError: this._onboarding.clearTwitterStatusError,
      confirmFollowingUsers: this._onboarding.confirmFollowingUsers,
      claimFreeGem: this._onboarding.claimFreeGem,
    };
  }

  get waitForInit(): Promise<void> {
    return this._initPromise;
  }

  constructor(private app: AppController) {
    super();
    this.loadFromCache();
    this._initPromise = new Promise<void>((resolve) => {
      this._initResolve = resolve;
    });
  }

  private loadFromCache = async () => {
    await this.cache.isReady;
    const cacheData = this.cache.all();
    if (cacheData && Object.keys(cacheData).length > 0) {
      // On first load from cache trigger all user events
      this.sendEvents(Object.keys(this.events));
      this._onboarding.start();
      this._initResolve();
      this.log('loadFromCache:userRehydrate', { cacheData });
    } else {
      this.log('loadFromCache', 'No cache found. Skipping...');
    }
  };

  private updateCache = () => {
    // when privy session expired/logout
    if (!this.me) {
      this.cache.clear();
    }
    // else {
    //   Object.keys(this.me).forEach((key) => {
    //     this.cache.set(key, this.me[key]);
    //   });
    // }
    this.cache.save();
  };

  private setUser(gemzUser?: MyGemzUser) {
    if (gemzUser !== this.cache.get('gemzUser')) {
      this.cache.set('gemzUser', gemzUser);
      this.log('setUser', { gemzUser });
      this.sendEvents([UserControllerEvent.OnUserUpdate]);
      this.updateCache();
    }
  }

  private updateMe = async (accessToken?: string) => {
    const user = await api.user.get.me(accessToken);
    this.setUser(user);
    return user;
  };

  private signUp = async (privyUser: PrivyUser, smartWalletAddress: WalletAddress) => {
    try {
      await api.user.create(privyUser, smartWalletAddress);
      // phone sign up, redirect to onboarding first step
      // if (!this.app.isWebMobile && privyUser.phone) {
      //   this.app.navigate(Routes.OnboardingTwitter);
      // }
      await this.updateMe();
      this._onboarding.start();

      if (this.app.isWebMobile) {
        // only for brand new users, show revamped, blocking, pwa install modal
        this.app.modals.open(Modals.PWA, { disableBackdrop: true });
      }
    } catch (e) {
      // @TODO: How to handle this?
      throw e;
    }
  };

  setOneSignalUser = async () => {
    if (!this.me) {
      return;
    }
    OneSignal.Notifications.addEventListener('permissionChange', this.linkOneSignalUser);
    OneSignal.Slidedown.promptPush();
  };

  linkOneSignalUser = async (permission: boolean) => {
    if (!permission || !this.me) {
      return;
    }
    const id = `${this.me.id}`;
    // try to login first
    await OneSignal.login(id);

    // add external id to user;
    // it will do nothing for existing and add id for new
    OneSignal.User.addAlias('external_id', id);
    // just unsubscribe, we're not interested in this event anymore
    OneSignalDeferred.push(function () {
      OneSignal.Notifications.addEventListener('permissionChange', this.linkOneSignalUser);
    });
  };

  private fetchCoinBalance = async () => {
    const coinBalance = await api.coins.getUserBalance();
    if (this.cache.get('coinBalance') !== coinBalance) {
      this.cache.set('coinBalance', coinBalance);
      this.sendEvents([UserControllerEvent.OnBalanceUpdate]);
      this.updateCache();
    }
  };

  private fetchDiamondBalance = async () => {
    const diamondBalance = await l2Client.getBalance(this.me.walletAddress);
    if (this._diamondBalance !== diamondBalance) {
      this.cache.set('diamondBalance', Number(diamondBalance));
      this.sendEvents([UserControllerEvent.OnBalanceUpdate]);
      this.updateCache();
    }

    // Handle deposit complete
    if (this._diamondBalance > BigInt(0) && this.me?.isDepositPending) {
      try {
        api.deposit.onDepositConfirm(this.me.walletAddress).then(() => {
          this.updateMe();
        });
      } catch {
        // There's a chance we call it twice before confirm (since it is async) and might get a "no deposit pending error"
      }
    }
  };

  private startDiamondPolling = () => {
    if (this._diamondBalancePoll) {
      clearInterval(this._diamondBalancePoll);
    }

    // 5s for when we are waiting for the first deposit, and 1 minute normally
    const delay = (this.me?.attributes.depositState === DepositState.WaitingForConfirmation ? 5 : 60) * 1000;

    this._diamondBalancePoll = setTimeout(() => {
      this.fetchDiamondBalance();
      this.startDiamondPolling();
    }, delay);
  };

  init = async (privyUser: PrivyUser, smartWalletAddress: WalletAddress) => {
    this.privyAuth = PrivyAuthState.Auth;
    try {
      await this.updateMe();
    } catch (e) {
      await this.signUp(privyUser, smartWalletAddress);
    }

    if (!sessionOpts.localDev && this.app.isPWA) {
      await this.setOneSignalUser();
    }

    this.fetchBalance();
    this.fetchHoldings();
    this.startDiamondPolling();
    this._initResolve();
  };

  refetch = () => {
    return this.updateMe();
  };

  // If not target is specified, fetch all
  fetchBalance = (target?: GemType) => {
    if (target) {
      return target === 'coin' ? this.fetchCoinBalance() : this.fetchDiamondBalance();
    } else {
      this.fetchCoinBalance();
      this.fetchDiamondBalance();
    }
  };

  reset = () => {
    if (this.me) {
      this.log('reset');
      this.setUser(undefined);
      this.privyAuth = PrivyAuthState.Unauth;
    }
  };

  // @TODO: Maybe this should belong in Game.ts
  canBuyItem = async (item: any) => {
    if (!item) {
      return false;
    }

    const { info, gem } = item;

    const rawBuyPrice = info?.gemBuyRawPrice;

    const isEthType = gem.type === 'eth' || gem.type === 'diamond' || gem.type === 'gemz';
    if (isEthType) {
      const gasPrice = await l2Client.getGasPrice();

      const cost = BigInt(rawBuyPrice) + gasPrice;

      const canBuy = this._diamondBalance >= cost;

      return canBuy;
    } else {
      return this.me.coinBalance >= Number(rawBuyPrice);
    }
  };

  fetchHoldings = async () => {
    this.log('fetchHoldings');
    const currentUser = this.me;
    const userName = currentUser?.username;
    const holdings = await api.user.getUserHoldingsByUsername(userName, true);
    if (!isEqual(holdings.items, this.me.holdings)) {
      this.cache.set('holdings', holdings.items);
      this.sendEvents([UserControllerEvent.OnHoldingsUpdate]);
      this.updateCache();
    }
    this.app.craft.unblock();
  };

  setPrivyToken = (token: string) => {
    this.cache.set('privyToken', token).save();
  };

  // This is to be used during onboarding
  fetchFromCachedToken = () => {
    const token = this.cache.get('privyToken');
    if (token) {
      return this.updateMe(token);
    }
    return Promise.resolve(this.me);
  };

  checkForCoinReward = (data: Omit<ApplyPlayRewardCoinRequest, 'userId'>) => {
    api.coins
      .rewards({
        ...data,
        userId: this.me.id,
      })
      .then((reward) => {
        if (reward > 0) {
          this.fetchBalance('coin');
        }
      });
  };
}
