import { Logger } from 'lib/BaseClass';
import { localCache } from './LocalCache';
import { CacheKey } from './LocalCacheSchema';

/**
 * Load a copy of the cache into memory to be manipulated
 * until `save()` back to cache
 */
export class FeatureCache<FeatureSchema extends Record<string, any>> extends Logger {
  logConfig = {
    enabled: false,
  };

  private data: FeatureSchema;

  private _isReady = false;

  public isReady: Promise<void>;

  // @TODO: Think better how to do the default getter
  constructor(private cacheKey: CacheKey, private defaultsGetter: (partial: Partial<FeatureSchema>) => FeatureSchema) {
    super();
    this.data = this.defaultsGetter({});
    this.isReady = new Promise(async (resolve) => {
      await localCache.isReady;
      this.update(localCache.get(this.cacheKey));
      this._isReady = true;

      this.log(cacheKey, this.data);

      resolve();
    });
  }

  private update = (partialData: Partial<FeatureSchema>) => {
    this.data = {
      ...this.data,
      ...partialData,
    };
  };

  private requireReady = (action: string) => {
    if (!this._isReady) {
      throw new Error(`Error (FeatureCache): Cannot '${action}'. LocalCache not ready.`);
    }
  };

  all = () => this.data;

  set = (key: keyof FeatureSchema | string, value: FeatureSchema[typeof key], opts?: { ttl?: number }) => {
    // @ts-ignore (we want it to be readonly except from this one setter)
    this.data[key] = value;
    return this;
  };

  get = <K extends keyof FeatureSchema | string>(key: K) => {
    return this.data[key];
  };

  remove = (key: keyof FeatureSchema) => {
    // reset the key in memory
    this.set(key, this.defaultsGetter({})[key]);
    return this;
  };

  clear = () => {
    this.data = this.defaultsGetter({});
    return this;
  };

  save = async () => {
    await this.isReady;
    this.requireReady('save');
    localCache.set(this.cacheKey, this.data);
  };
}
