import { deposit } from './deposit';
import { feed } from './feed';
import { game } from './game';
import { gemz } from './gemz';
import { multibaas } from './multibaas';
// import { notify } from './notifications';
import { onboarding } from './onboarding';
import { paymaster } from './paymaster';
import { user } from './user';
import { follows } from './follows';
// import { fingerprint } from './fingerprint';
import { coins } from './coins';
import { craft } from './craft';
import { chat } from './chat';

export const api = {
  user,
  onboarding,
  deposit,
  feed,
  gemz,
  multibaas,
  game,
  // notify,
  paymaster,
  follows,
  // fingerprint,
  coins,
  craft,
  chat,
};
