import { CSSProperties, memo, useEffect, useState } from 'react';
import { HostedApp } from '../components/HostedApp/HostedApp';
import { env } from '../config/env';
import { controller } from '../lib/Controller';
import { GameEvents } from '@storyverseco/svs-types';
import { listenToGameEvent } from '../lib/navbarSuite';
import { useReRender } from '../hooks/useReRender';
import { useClassName } from 'hooks/useClassName';
import userAgent from '@storyverseco/user-agent';
import './Game.scss';
import { sessionOpts } from 'config/consts';

const getGameUrl = () => {
  const { hostname } = window.location;

  // using local instance of the game when running the website locally
  if (sessionOpts.localViewer) {
    if (userAgent.isMobile) {
      const gamePrivy = hostname.replace('-privy.ngrok.dev', '-privy-game.ngrok.dev');
      const gameHostName = `https://${gamePrivy}?iframed=true`;
      return gameHostName;
    }
    return 'http://localhost:3002?iframed=true';
  }
  // debug: uncomment if we want to force privy game viewer url, regardless of mobile or desktop
  // if (hostname.includes('-privy.ngrok.dev')) {
  //   const gamePrivy = hostname.replace('-privy.ngrok.dev', '-privy-game.ngrok.dev');
  //   const gameHostName = `https://${gamePrivy}?iframed=true`;
  //   return gameHostName;
  // }

  return env.gameUrl;
};

const GameContainer = memo(() => {
  console.log('re-render game container');
  const gameUrl = getGameUrl();

  return <HostedApp src={gameUrl} style={{ position: 'absolute' }} />;
});

interface GameOpts {}
export const Game = ({}: GameOpts) => {
  useReRender({ id: 'Game', listener: controller.viewer.onGameVisibilityChange });
  useReRender({ id: 'Game:ui', listener: controller.ui.attachEventListener('on_change') });
  useReRender({ id: 'Game:sidebar', listener: controller.ui.elements.CommentSidebar.attachEventListener('on_change') });

  const showGame = controller.viewer.showViewer;

  // @hack: start - first-render-hack
  // there is an HTML bug where the last frame of a body in an iframe shows for the duration of a single frame when the body becomes visible
  const [repoHackComplete, setRepoHackComplete] = useState(false);

  useEffect(() => {
    if (!showGame) {
      if (repoHackComplete) {
        setRepoHackComplete(false);
      }
      return;
    }

    if (repoHackComplete) {
      return;
    }

    const twoFrames = 33;
    const timer = setTimeout(() => {
      setRepoHackComplete(true);
    }, twoFrames);

    return () => clearTimeout(timer);
  }, [showGame, repoHackComplete]);

  // const position = show ? (repoHackComplete ? 0 : 10000) : 0;
  // const divStyle = {
  //   top: '0px',
  //   position: 'absolute',
  //   zIndex: 1,
  //   width: '100%',
  //   height: '100%',
  //   left: `${position}px`,
  //   display: controller.game.showViewer ? 'block' : 'none',
  //   // top: '3000px', // in case we want to test the ap without games showing at all
  // } as CSSProperties;
  // // @hack: end

  // return (
  //   <div style={divStyle}>
  //     <GameContainer />
  //   </div>
  // );

  const position = showGame ? (repoHackComplete ? 0 : 10000) : 0;
  const divStyle = {
    left: `${position}px`,
    // top: '3000px', // in case we want to test the ap without games showing at all
  } as CSSProperties;
  // @hack: end

  const storyGameClassName = useClassName(
    'story-game',
    controller.ui.isGameFullScreen && 'full-height',
    controller.ui.isDesktopGameFeed && controller.ui.elements.CommentSidebar.visible && 'desktop-gamefeed',
    controller.ui.isDesktopAiAssistant && 'ai-assistant',
    showGame ? 'show' : 'hide',
  );

  return (
    <div id="story-game" className={storyGameClassName} style={divStyle}>
      <GameContainer />
    </div>
  );
};
