import { useEffect, useState } from 'react';
import './Toasts.scss';
import { useClassName } from 'hooks/useClassName';
import { useNavigation } from 'hooks/useNavigation';
import { suppressCancelled, waitForMsCancelable } from 'lib/wait';
import { SingleToast } from './SingleToast';
import { Routes } from 'router';
import { ActivityItem, ActivityType, FEOnlyActivityType } from '../types';
import { controller } from 'lib/Controller';
import { useReRender } from 'hooks/useReRender';
import { AppUpdateEvent, AppUpdateState, swUpdater } from 'lib/apis/swupdate';
import { WalletAddress } from '@storyverseco/svs-types';

/*
@TODO(jb): Do we want to queue toasts via redux + actions and have services/managers/etc queue
up the toasts instead of this current way?
*/

// todo: exclusively for testing purposes since toasts are very annoying. make sure this is always false.
const skipToastRendering = false;

const TOAST_SHOW_DURATION = 5000; // 5 seconds in ms
const TOAST_TRANSITION_DURATION = 500; // ms, should match the transition time in styling

const fauxUpdateAvailId = 'fe:updateavail';

const createFauxUpdateAvailItem = (): ActivityItem => ({
  activityId: fauxUpdateAvailId,
  createdAt: new Date().toISOString(),
  type: FEOnlyActivityType.UpdateAvailable,
  gameId: 0,
  gemName: '',
  offChainGameId: 0,
  participantId: '',
  participantImage: '',
  participantName: '',
  participantWallet: '' as WalletAddress,
  price: 0,
  readAt: '',
  userId: '',
  username: '',
});

const toastDurationOverrides = {
  [FEOnlyActivityType.UpdateAvailable]: 30000, // 30 seconds in ms
};

export const Toasts = () => {
  const { route } = useNavigation();
  const [show, setShow] = useState(false);
  const [dismiss, setDismiss] = useState<() => void>(undefined);
  const [currentItem, setCurrentItem] = useState<ActivityItem>(undefined);
  const [shownMap, setShownMap] = useState<Record<string, boolean>>({});
  const { state } = swUpdater;

  useReRender({ id: 'Toasts', listener: controller.notifications.attachEventListener('on_unread_change') });
  useReRender({ id: 'Toasts:swUpdater', listener: swUpdater.attachEventListener(AppUpdateEvent.StateChange) });

  const { unreadCount, unreadFeed } = controller.notifications;

  // only enabled if not in activity page
  const enabled = route !== Routes.Inbox;
  const shouldShow = enabled && show;
  const toastClassName = useClassName('toast-container', shouldShow && 'show');

  useEffect(() => {
    if (!enabled) {
      return;
    }

    // if already showing something, stop here
    if (currentItem) {
      return;
    }

    // if update is available
    if (state === AppUpdateState.UpdateAvailable && !shownMap[fauxUpdateAvailId]) {
      setCurrentItem(createFauxUpdateAvailItem());
      return;
    }

    // if empty, do nothing
    if (!unreadCount) {
      return;
    }

    const unshownFeed = unreadFeed.filter((item) => !shownMap[item.activityId]);

    // if all shown, do nothing
    if (!unshownFeed.length) {
      return;
    }

    setCurrentItem(unshownFeed[0]);
  }, [enabled, unreadFeed, shownMap, currentItem, state]);

  useEffect(() => {
    if (!currentItem) {
      return;
    }

    if (currentItem.type === ActivityType.GemUnlockedWithHelp) {
      controller.feed.loadAndGetItemById(currentItem.offChainGameId ?? currentItem.gameId).then((game) => game.refresh());
    }

    const toastDuration = toastDurationOverrides[currentItem.type] || TOAST_SHOW_DURATION;

    const { promise: delayedHidePromise, cancel: delayedHideCancel } = waitForMsCancelable(toastDuration);

    let endCancel: () => void = undefined;

    const currentDismiss = () =>
      suppressCancelled(
        Promise.resolve()
          .then(() => {
            setShow(false);
            const { promise, cancel } = waitForMsCancelable(TOAST_TRANSITION_DURATION);
            endCancel = cancel;
            return promise;
          })
          .then(() => {
            setShownMap({
              ...shownMap,
              [currentItem.activityId]: true,
            });
            setDismiss(undefined);
            setCurrentItem(undefined);
          }),
      );

    suppressCancelled(delayedHidePromise.then(currentDismiss));

    // nested arrow functions because using a function uses the function form of setting state
    setDismiss(() => () => {
      delayedHideCancel();
      currentDismiss();
    });
    setShow(true);

    return () => {
      delayedHideCancel();
      endCancel?.();
    };
  }, [currentItem, shownMap]);

  // todo: exclusively for testing purposes. skiptoastRendering should always be set to false
  if (skipToastRendering) return null;

  return <div className={toastClassName}>{currentItem && <SingleToast activity={currentItem} onClick={dismiss} onSwipeUp={dismiss} />}</div>;
};
