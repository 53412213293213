import { getAccessToken } from '@privy-io/react-auth';
import { Endpoints, FeedServiceEndpoints, MediaServiceEndpoints } from './endpoints';
import { loadCfg } from 'lib/loadCfg';
import { env } from 'config/env';
import isEqual from 'lodash.isequal';
import { sessionOpts } from 'config/consts';

interface RequestOpts {
  body?: Record<string, any>;
  isAuth?: boolean;
  isMock?: boolean;
}
let token: string;
export const getEndpoint = (endpoint: Endpoints, values: Record<string, string | number | undefined>) => {
  let endpointWithPathParams: string = endpoint;

  Object.keys(values).forEach((key) => {
    endpointWithPathParams = endpointWithPathParams.replace(`:${key}`, values[key]?.toString() || '');
  });

  return endpointWithPathParams as Endpoints;
};

export const getRequestOpt = (method: RequestInit['method'], opts?: RequestOpts) => {
  if (opts?.isMock) {
    return {};
  }

  const headers = getHeaders(opts?.isAuth);

  if (method === 'GET') {
    return opts?.isAuth ? { method, ...headers } : undefined;
  }
  if (method === 'DELETE') {
    return { method, ...headers };
  }

  if (!opts?.body) {
    throw new Error(`Error: Cannot make '${method}' request without 'body'.`);
  }

  // assume its either put or post
  return {
    method,
    ...headers,
    body: JSON.stringify(opts.body),
  };
};

const getHeaders = (isAuth: boolean = false) => {
  const headers: HeadersInit = {
    'Content-Type': 'application/json',
  };

  if (isAuth) {
    if (!token) {
      throw new Error(`Error: Cannot make auth request without 'privyToken' set.`);
    }

    headers.Authorization = `Bearer ${token}`;
  }

  return { headers };
};

export const request = async (url: string, requestOpts?: RequestInit) => {
  try {
    const response = await fetch(url, requestOpts);

    const isSuccess = response.status >= 200 && response.status < 400;

    if (!isSuccess) {
      let errorMessage = 'Unexpected error occurred.';
      try {
        const failureJson = await response.json();
        errorMessage = failureJson.error;
      } catch (e) {
        errorMessage = await response.text();
      } finally {
        throw new Error(errorMessage);
      }
    }

    return response;
  } catch (e) {
    // log.error.info(`Error (request)`, [e]);
    console.error(`Error (request)`, e.message);
    throw e;
  }
};

// Used to prevent duplicate requests. Ideally this should be better
// There's no point in refetching some of this data so often
const requestCache: Record<string, any> = {};
const requestPromiseCache: Record<string, Promise<Response>> = {};

const requestWithCache = async (url: string, requestOpts?: RequestInit) => {
  const cache = requestCache[url];
  const cachedPromise = requestPromiseCache[url];

  if (cache && cachedPromise && isEqual(cache, requestOpts)) {
    // log.api.info('requestPipeline', [`Skipping request to '${url}'. Another request already in flight`]);
    return cachedPromise.then((response) => response.clone());
  }

  requestCache[url] = requestOpts;

  const promise = request(url, requestOpts).finally(() => {
    // Remove current request from cache (either resolved or rejected)
    delete requestCache[url];
    delete requestPromiseCache[url];
  });

  requestPromiseCache[url] = promise;

  return promise;
};

// Preferably don't expose the function, use pipelineApi instead
const requestPipeline = async (endpoint: Endpoints, requestOpts?: RequestInit) => {
  const config = await loadCfg(env.env);

  // const localUrl = 'https://cai-privy-server.ngrok.dev';
  const localUrl = 'http://localhost:4000';
  const origin = sessionOpts.localServer ? localUrl : config.globals.urls.publish;

  const url = `${origin}/${endpoint}`;

  return requestWithCache(url, requestOpts);
};

// Preferably don't expose the function, use activityApi instead
const requestActivityService = async (endpoint: Endpoints, requestOpts?: RequestInit) => {
  const config = await loadCfg(env.env);

  const origin = config.globals.urls.activityService;

  const url = `${origin}/${endpoint}`;

  return requestWithCache(url, requestOpts);
};

const requestMediaService = async (endpoint: MediaServiceEndpoints, requestOpts?: RequestInit) => {
  const config = await loadCfg(env.env);

  const origin = config.globals.urls.mediaService;

  const url = `${origin}/${endpoint}`;

  return requestWithCache(url, requestOpts);
};

const requestFeedService = async (endpoint: FeedServiceEndpoints, requestOpts?: RequestInit) => {
  const config = await loadCfg(env.env);

  const origin = (config.globals.urls as any).feedService;

  const url = `${origin}/${endpoint}`;

  return requestWithCache(url, requestOpts);
};

export const requestMock = async <T>(endpoint: Endpoints, requestOpts?: RequestInit, response?: T): Promise<T> => {
  await new Promise((resolve) => setTimeout(resolve, 1000));
  return response;
};

export const pipelineApi = {
  get: async (endpoint: Endpoints, isAuth: boolean = true, rawResponse: boolean = false, accessToken?: string) => {
    // Make sure token is always up to date
    token = accessToken || (await getAccessToken());

    console.log('pipelineApi:get', { endpoint, isAuth });

    const response = await requestPipeline(endpoint, getRequestOpt('GET', { isAuth }));

    if (rawResponse) {
      return response;
    }

    const { result } = await response.json();

    return result;
  },
  post: async (endpoint: Endpoints, body: Record<string, any>, isAuth: boolean = true, accessToken?: string) => {
    // Make sure token is always up to date
    token = accessToken ? accessToken : await getAccessToken();
    console.log('pipelineApi:post', { endpoint, body, isAuth });
    const response = await requestPipeline(endpoint, getRequestOpt('POST', { body, isAuth }));
    const { result } = await response.json();

    return result;
  },
};

export const mediaServiceApi = {
  get: async (endpoint: MediaServiceEndpoints) => {
    console.log('mediaServiceApi:get', { endpoint });
    const response = await requestMediaService(endpoint, getRequestOpt('GET'));
    return response;
  },
  post: async (endpoint: MediaServiceEndpoints, body: Record<string, any>) => {
    console.log('mediaServiceApi:post', { endpoint, body });
    const response = await requestMediaService(endpoint, getRequestOpt('POST', { body }));
    return response;
  },
};

export const feedServiceApi = {
  get: async (endpoint: FeedServiceEndpoints, isAuth: boolean = false, rawResponse: boolean = false) => {
    // Make sure token is always up to date
    token = await getAccessToken();
    console.log('feedServiceApi:get', { endpoint, isAuth });

    const response = await requestFeedService(endpoint, getRequestOpt('GET', { isAuth }));

    if (rawResponse) {
      return response;
    }

    const { result } = await response.json();

    return result;
  },
  post: async <T = any>(endpoint: FeedServiceEndpoints, body: T, isAuth: boolean = false, accessToken?: string, rawResponse: boolean = false) => {
    // Make sure token is always up to date
    token = accessToken ? accessToken : await getAccessToken();
    console.log('feedServiceApi:post', { endpoint, body, isAuth });
    const response = await requestFeedService(endpoint, getRequestOpt('POST', { body, isAuth }));
    if (rawResponse) {
      return response;
    }
    const { result } = await response.json();

    return result;
  },
};

export const activityServiceApi = {
  get: async (endpoint: Endpoints, isAuth: boolean = true, rawResponse: boolean = false, accessToken?: string) => {
    // Make sure token is always up to date
    token = accessToken || (await getAccessToken());

    console.log('activityServiceApi:get', { endpoint, isAuth });

    const response = await requestActivityService(endpoint, getRequestOpt('GET', { isAuth }));

    if (rawResponse) {
      return response;
    }

    const { result } = await response.json();

    return result;
  },
  post: async (endpoint: Endpoints, body: Record<string, any>, isAuth: boolean = true, accessToken?: string) => {
    // Make sure token is always up to date
    token = accessToken ? accessToken : await getAccessToken();
    console.log('activityServiceApi:post', { endpoint, body, isAuth });
    const response = await requestActivityService(endpoint, getRequestOpt('POST', { body, isAuth }));
    const { result } = await response.json();

    return result;
  },
};
