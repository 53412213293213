import { ComponentController } from './ComponentController';
import { UIElementKey } from './UIController';
import isEqual from 'lodash.isequal';

export class UIElementController<StateSchema extends Record<string, any>> extends ComponentController {
  private _visible = false;

  private _id: UIElementKey;

  private _state: StateSchema;

  get state() {
    return this._state;
  }

  constructor(id: UIElementKey, defaultState?: StateSchema) {
    super();
    this._id = id;
    this._state = defaultState || ({} as StateSchema);
    this.events = {
      on_change: [],
    };
  }

  get id() {
    return this._id;
  }

  get visible() {
    return this._visible;
  }

  private onUpdate = () => {
    this.updateComponent();
    this.sendEvents(['on_change']);
  };

  public show = () => {
    if (!this._visible) {
      this._visible = true;
      this.onUpdate();
    }
  };

  public hide = () => {
    if (this._visible) {
      this._visible = false;
      this.onUpdate();
    }
  };

  public updateState = (partialState: Partial<StateSchema>) => {
    const newState = {
      ...this._state,
      ...partialState,
    };
    if (!isEqual(this._state, newState)) {
      this._state = newState;
      this.onUpdate();
    }
  };
}
