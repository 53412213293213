import { CacheDB } from './CacheDB';
import { CacheSchema, CacheKey, getCacheWithDefaults } from './LocalCacheSchema';

class LocalCache {
  private static readonly VERSION = 1;

  private data: CacheSchema;

  private db: CacheDB<typeof this.data> = new CacheDB('LocalCache', LocalCache.VERSION);

  private _isReady = false;

  public isReady: Promise<void>;

  constructor(private defaultsGetter: (partial: Partial<CacheSchema>) => CacheSchema) {
    this.data = this.defaultsGetter({});
    this.isReady = new Promise(async (resolve) => {
      await this.db.init();
      this.update(await this.db.getCache());
      this._isReady = true;
      resolve();
    });
  }

  private update = (partialData: Partial<CacheSchema>) => {
    this.data = {
      ...this.data,
      ...partialData,
    };
  };

  private requireReady = (action: string) => {
    if (!this._isReady) {
      throw new Error(`Error (FeatureCache): Cannot '${action}'. LocalCache not ready.`);
    }
  };

  set = (key: CacheKey, value: CacheSchema[typeof key]) => {
    this.requireReady('set');
    // @ts-ignore (we want it to be readonly except from this one setter)
    this.data[key] = value;
    // Update the async DB
    this.db.set(key as string, value);
  };

  get = <K extends CacheKey>(key: K) => {
    this.requireReady('get');
    return this.data[key];
  };

  remove = (key: CacheKey) => {
    this.requireReady('remove');
    // reset the key in memory
    this.set(key, this.defaultsGetter({})[key]);
    // remove from the db
    this.db.remove(key as string);
  };

  clear = () => {
    this.requireReady('clear');
    this.data = this.defaultsGetter({});
    this.db.clear();
  };
}

// All other cache should save to local cache
export const localCache = new LocalCache(getCacheWithDefaults);
