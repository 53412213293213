import { useEffect, useState } from 'react';
import { Fn } from '../lib/EventListener';

type Listener = (value: () => void) => () => void;

interface ReRenderOpts {
  id: string;
  listener?: Listener;
}

export const useReRender = ({ id, listener }: ReRenderOpts) => {
  const [renderCount, setRenderCount] = useState(0);

  useEffect(() => {
    if (!listener) {
      return;
    }
    const callback = () => {
      console.log(`Re-render ${id}`);
      setRenderCount(renderCount + 1);
    };
    return listener(callback);
  }, [renderCount, listener]);
};
