import { Env } from '@storyverseco/svs-types';
import cfglib from '@storyverseco/svs-config-client';
import { env } from '../config/env';

/**
 * Load config site runtime
 */
export const loadCfg = async (forceEnv: Env = env.env) => {
  const stage = forceEnv || process.env['STAGE'] || 'dev';
  const cfg = await cfglib.init(stage);
  return await cfg.addSource('saleData').loadAsync(true);
};
