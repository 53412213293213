import './TopBar.scss';
import { useCallback, useEffect, useState } from 'react';

import TweenOne from 'rc-tween-one';
import { images } from 'assets/assets';

interface Props {
  onClick: () => void;
  show: boolean;
  icon: string;
  fakeBalance: number;
  userBalance: number;
  type: 'coin' | 'point' | 'diamond';
  isPending?: boolean;
}

export function TopBarCurrency({ onClick, show, icon, fakeBalance, userBalance, type, isPending }: Props) {
  // ==================================
  // setup animations

  const [animState, setAnimState] = useState<{ value: number; animation: any }>({
    value: userBalance,
    animation: null,
  });

  const [frameGrow, setFrameGrow] = useState<{ animation: any }>({
    animation: null,
  });

  const [diamondGrow, setDiamondGrow] = useState<{ animation: any }>({
    animation: null,
  });

  const [diamondRot, setDiamondRot] = useState<number>(0);

  const onUpdateCurrency = useCallback(
    (value: number) => {
      // currency value interpolation
      setAnimState({
        value,
        animation: {
          // note that Children plugin is being pushed in App.tsx
          Children:
            type === 'coin' || type === 'point'
              ? {
                  // coins and points, have no decimals and are money-formatted
                  value: typeof value === 'number' ? fakeBalance : 0.0,
                  floatLength: 0,
                  formatMoney: true,
                }
              : {
                  // diamonds are a number with 3 decimals
                  value: typeof value === 'number' ? fakeBalance : 0.0,
                  floatLength: 3,
                  formatMoney: false,
                },
          duration: 500,
          delay: 375,

          // frame and diamond grow
          onStart: () => {
            setFrameGrow({ animation: { scale: 1.1, duration: 250, delay: 125 } });
            setDiamondGrow({ animation: { scale: 1.25, duration: 250 } });
          },

          // diamond vibrate
          onUpdate: () => {
            const d = 5;
            const r = -d + Math.random() * d * 2;
            setDiamondRot(r);
          },

          // frame and diamond reset
          onComplete: () => {
            setDiamondRot(0);
            setFrameGrow({ animation: { scale: 1, duration: 250, delay: 125 } });
            setDiamondGrow({ animation: { scale: 1, duration: 250 } });
          },
        },
      });
    },
    [fakeBalance],
  );

  useEffect(() => {
    onUpdateCurrency(fakeBalance);
  }, [fakeBalance]);

  if (!show) {
    return null;
  }

  return (
    <div className={`topbar-currency-container ${type} ${show ? 'show' : 'hide'}`} onClick={onClick}>
      <TweenOne className="topbar-currency-black" animation={frameGrow.animation}>
        <TweenOne className="currency-black-label" animation={diamondGrow.animation}>
          <div className="icon-currency-container" style={{ transform: `rotate(${diamondRot}deg)` }}>
            <div id={`topbar-currency-${type}`} className="icon-currency svg-shadow" style={{ backgroundImage: `url(${icon})` }} />
            <div className="icon-currency-plus" style={{ backgroundImage: `url(${images.iconPlusCircle})` }} />
          </div>
        </TweenOne>
        {isPending ? (
          <p>Pending Deposit</p>
        ) : (
          <TweenOne className="currency-black-label" animation={animState.animation}>
            <div>0</div>
          </TweenOne>
        )}
      </TweenOne>
    </div>
  );
}
