import { WalletAddress } from '@storyverseco/svs-types';

export interface ActivityItem {
  activityId: string;
  gemName: string;
  gameId: number;
  offChainGameId: number;
  type: string;
  price: number;
  userId: string;
  username: string;
  participantId: string;
  participantName: string;
  participantImage: string;
  participantWallet: WalletAddress;
  createdAt: string;
  readAt?: string | undefined;
  extra?: Record<string, any>;
  items?: ActivityItem[];
}

export interface ActivityFeedItem extends ActivityItem {
  unread: boolean;
}

export enum ActivityType {
  Welcome = 'welcome',
  Buy = 'buy',
  Sell = 'sell',
  Visit = 'visit',
  PriceWentUp = 'price_went_up',
  Follow = 'follow',
  FollowBack = 'follow_back',
  FollowingCreatedGem = 'following_created_gem',
  LikedGame = 'liked_game',
  CommentedGame = 'commented_game',
  GemUnlockedWithHelp = 'gem_unlocked_with_help',
  GemBoughtWithHelp = 'gem_bought_with_help',
}

export const activityTypes = Object.values(ActivityType).filter((value) => typeof value === 'string');

export enum FEOnlyActivityType {
  UpdateAvailable = 'fe_update_available',
}

// @TODO: maybe this should be elsewhere
export interface NotificationsCacheSchema {
  readActivityTimeMap?: Record<string, number>;
}
