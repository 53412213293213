import { Conditions, GameFeedItem, GameLikeData, Optional, ExtraInfo as ExtraInfoBase, GameCommentData } from '@storyverseco/svs-types';
import { getEndpoint, pipelineApi } from './base';
import { Endpoints } from './endpoints';

export interface ExtraInfo extends ExtraInfoBase {}
const getExtraInfo = async (gameId: number): Promise<ExtraInfo> => {
  return pipelineApi.get(getEndpoint(Endpoints.GAME_GET_EXTRA_INFO, { offChainGameId: gameId.toString() }));
};

const getOutcome = async (
  gameId: number,
  choices: Conditions,
  hwid?: number,
): Promise<{
  outcome: 'win' | 'lose';
  url: string;
}> => {
  console.warn('>>> website - retrieving outcome', gameId, choices);
  return pipelineApi.post(Endpoints.GAME_GET_OUTCOME, {
    data: {
      offChainGameId: gameId,
      choices,
      hwid,
    },
  });
};

const getGameByPubId = async (pubGameId: string): Promise<GameFeedItem> => {
  return pipelineApi.get(getEndpoint(Endpoints.GAME_GET_PUB, { pubGameId }), false);
};

const likeGame = async (gameId: number): Promise<string> => {
  return pipelineApi.post(getEndpoint(Endpoints.GAME_LIKE, { gameId }), {});
};

const unlikeGame = async (gameId: number): Promise<string> => {
  return pipelineApi.post(getEndpoint(Endpoints.GAME_UNLIKE, { gameId }), {});
};

const hasLikedGame = async (gameId: number): Promise<boolean> => {
  return pipelineApi.get(getEndpoint(Endpoints.GAME_LIKE, { gameId }));
};

const getAllLikes = async (gameId: number, offset?: number): Promise<GameLikeData[]> => {
  let endpoint: string = getEndpoint(Endpoints.GAME_ALL_LIKES, { gameId });
  if (offset) {
    endpoint = `${endpoint}?offset=${offset}`;
  }
  return pipelineApi.get(endpoint as Endpoints);
};

// mock comments api
// const users = [
//   {
//     id: 1036,
//     username: 'jaytherpc',
//     twitter: {
//       displayName: 'JB P!',
//       picture: 'https://pbs.twimg.com/profile_images/1487059264124325891/seGYqvnR_normal.jpg',
//     },
//     walletAddress: '0x2f2e02c77D4DEFDBf3AF3175a967f7401aFf630D' as WalletAddress,
//   },
//   {
//     id: 1055,
//     username: 'jbjayther',
//     twitter: {
//       displayName: 'JB Jayther',
//       picture: 'https://pbs.twimg.com/profile_images/1293498471815684096/9mHpQRr2.jpg',
//     },
//     walletAddress: '0x19BE0612059ba1091f540e13a2505c818fE1F911' as WalletAddress,
//   },
// ];

// let mockCommentIdPool = 7;
// const mockComments: GameCommentData[] = [
//   {
//     id: 2,
//     createdAt: '2024-02-26T10:59:06.694Z',
//     updatedAt: '2024-02-26T10:59:06.694Z',
//     message: 'Cupcake ipsum dolor sit amet muffin cheesecake cookie marzipan. Cheesecake jelly beans tootsie roll croissant jelly chupa.',
//     offChainGameId: '1809',
//     userId: users[0].id,
//     user: users[0],
//     userRelationship: FollowRelationship.None,
//     userRelationTo: users[0].id,
//     replyCount: 4,
//   },
//   {
//     id: 3,
//     createdAt: '2024-02-26T11:59:06.694Z',
//     updatedAt: '2024-02-26T11:59:06.694Z',
//     message: 'Cake bonbon',
//     offChainGameId: '1809',
//     userId: users[0].id,
//     user: users[0],
//     userRelationship: FollowRelationship.None,
//     userRelationTo: users[0].id,
//     replyToId: 2,
//     topId: 2,
//   },
//   {
//     id: 4,
//     createdAt: '2024-02-26T12:59:06.694Z',
//     updatedAt: '2024-02-26T12:59:06.694Z',
//     message: 'Oat cake candy canes jelly-o cupcake gummi bears marshmallow brownie dragée. Fruitcake cake sweet roll brownie gummies.',
//     offChainGameId: '1809',
//     userId: users[1].id,
//     user: users[1],
//     userRelationship: FollowRelationship.None,
//     userRelationTo: users[0].id,
//     replyToId: 3,
//     topId: 2,
//   },
//   {
//     id: 5,
//     createdAt: '2024-02-26T13:59:06.694Z',
//     updatedAt: '2024-02-26T13:59:06.694Z',
//     message: 'Bonbon shortbread cookie.',
//     offChainGameId: '1809',
//     userId: users[1].id,
//     user: users[1],
//     userRelationship: FollowRelationship.None,
//     userRelationTo: users[0].id,
//     replyToId: 2,
//     topId: 2,
//   },
//   {
//     id: 6,
//     createdAt: '2024-02-26T14:59:06.694Z',
//     updatedAt: '2024-02-26T14:59:06.694Z',
//     message: 'Lemon drops jujubes sweet roll jelly cake',
//     offChainGameId: '1809',
//     userId: users[0].id,
//     user: users[0],
//     userRelationship: FollowRelationship.None,
//     userRelationTo: users[0].id,
//     replyToId: 5,
//     topId: 2,
//   },
// ];

const createGameComment = async (offChainGameId: number, data: { message: string; replyToId?: number }): Promise<GameCommentData> => {
  // mock comments api
  // const refComment = 'replyToId' in data ? mockComments.find((c) => c.id === data.replyToId) : undefined;
  // const nowIso = new Date().toISOString();
  // const comment: GameCommentData = {
  //   id: mockCommentIdPool++,
  //   createdAt: nowIso,
  //   updatedAt: nowIso,
  //   message: data.message,
  //   offChainGameId: offChainGameId.toString(),
  //   userId: users[0].id,
  //   user: users[0],
  //   replyToId: data.replyToId,
  //   topId: refComment?.topId ?? data.replyToId,
  //   userRelationship: FollowRelationship.None,
  //   userRelationTo: users[0].id,
  // };
  // mockComments.push(comment);
  // await waitForMs(1000);
  // return comment;

  // real
  return pipelineApi.post(getEndpoint(Endpoints.GAME_COMMENTS, { offChainGameId }), { data });
};

const deleteGameComment = async (commentId: number): Promise<void> => {
  return pipelineApi.post(getEndpoint(Endpoints.GAME_COMMENTS_DELETE, { commentId }), {});
};

const getGameComment = async (commentId: number, isAuth = true): Promise<GameCommentData> => {
  // mock comments api
  // const comment = mockComments.find((c) => c.id === commentId);
  // await waitForMs(1000);
  // return comment;

  // real
  return pipelineApi.get(getEndpoint(Endpoints.GAME_COMMENTS_SINGLE, { commentId }), isAuth);
};

const getTopGameComments = async (offChainGameId: number, offset?: number, limit?: number, isAuth = true): Promise<GameCommentData[]> => {
  // mock comments api
  // const comments = mockComments
  //   .filter((c) => typeof c.replyToId === 'undefined')
  //   .map((c) => ({
  //     ...c,
  //     offChainGameId: offChainGameId.toString(),
  //     replyCount: mockComments.filter((r) => r.topId === c.id).length,
  //   }));
  // await waitForMs(1000);
  // return comments;

  // real
  let endpoint: string = getEndpoint(Endpoints.GAME_COMMENTS, { offChainGameId });
  const searchParams = new URLSearchParams();
  if (offset) {
    searchParams.append('offset', offset.toString());
  }
  if (limit) {
    searchParams.append('limit', limit.toString());
  }
  if (searchParams.size) {
    endpoint = `${endpoint}?${searchParams.toString()}`;
  }
  return pipelineApi.get(endpoint as Endpoints, isAuth);
};

const getRepliesFromGameComment = async (commentId: number, offset?: number, limit = 3, isAuth = true): Promise<GameCommentData[]> => {
  // mock comments api
  // offset ??= 0;
  // const comments = mockComments
  //   .filter((c) => c.topId === commentId)
  //   .slice(offset, offset + limit)
  //   .map((c) => ({
  //     ...c,
  //   }));
  // await waitForMs(1000);
  // return comments;

  // real
  let endpoint: string = getEndpoint(Endpoints.GAME_COMMENTS_REPLIES, { commentId });
  const searchParams = new URLSearchParams();
  if (offset) {
    searchParams.append('offset', offset.toString());
  }
  if (limit) {
    searchParams.append('limit', limit.toString());
  }
  if (searchParams.size) {
    endpoint = `${endpoint}?${searchParams.toString()}`;
  }
  return pipelineApi.get(endpoint as Endpoints, isAuth);
};

const linkWebGameSession = async (gameId: number, privyId: string) => {
  return pipelineApi.get(getEndpoint(Endpoints.GAME_WEB_SESSION_LINK, { gameId: gameId.toString(), privyId }));
};

const getWebGameSession = async (privyId: string): Promise<Optional<number>> => {
  console.log('getWebSession');
  const gameId = await pipelineApi.get(getEndpoint(Endpoints.GAME_WEB_SESSION_GET, { privyId }));
  return Number.isNaN(gameId) ? undefined : gameId;
};

const getFirstGameInFeed = async (): Promise<GameFeedItem> => {
  return pipelineApi.get(Endpoints.GAME_GET_FIRST_IN_FEED, false);
};

const handleInsufficientPayment = async (gameId: number): Promise<GameFeedItem> => {
  return pipelineApi.get(getEndpoint(Endpoints.GAME_HANDLE_INSUFFICIENT_PAYMENT, { gameId }));
};

const unlockWithHelp = async (gameId: number, hwid: number) => {
  return pipelineApi.post(Endpoints.GAME_UNLOCK_WITH_HELP, {
    data: {
      offChainGameId: gameId,
      hwid,
    },
  });
};

export const game = {
  get: {
    extraInfo: getExtraInfo,
    outcome: getOutcome,
    pubGame: getGameByPubId,
    firstInFeed: getFirstGameInFeed,
    allLikes: getAllLikes,
    liked: hasLikedGame,
    singleComment: getGameComment,
    comments: getTopGameComments,
    replies: getRepliesFromGameComment,
  },
  like: likeGame,
  unlike: unlikeGame,
  comments: {
    create: createGameComment,
    delete: deleteGameComment,
  },
  linkWebGameSession,
  getWebGameSession,
  handleInsufficientPayment,
  unlockWithHelp,
};
