export type CancelableWaiter<T = void> = {
  promise: Promise<T>;
  cancel: () => void;
};

export const cancelledErrorName = 'PromiseCancelled';

export const cancelSymbol = Symbol('CancelSymbol');
export type CancelSymbol = typeof cancelSymbol;

export class PromiseCancelledError extends Error {
  constructor() {
    super('Promise manually cancelled');
    this.name = cancelledErrorName;
  }

  static isError(obj: any): obj is PromiseCancelledError {
    return obj?.name === cancelledErrorName;
  }
}

export function waitForMs(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function waitForSeconds(seconds: number): Promise<void> {
  return waitForMs(seconds * 1000);
}

export function waitForMsCancelable(ms: number): CancelableWaiter {
  let timeout: NodeJS.Timeout;
  let promiseReject: (reason: any) => void;
  const promise = new Promise<void>((resolve, reject) => {
    promiseReject = reject;
    timeout = setTimeout(resolve, ms);
  }).then(() => {
    timeout = null;
    promiseReject = null;
  });
  return {
    promise,
    cancel() {
      if (!timeout) {
        return;
      }
      clearTimeout(timeout);
      timeout = null;
      promiseReject(new PromiseCancelledError());
      promiseReject = null;
    },
  };
}

export function waitForSecondsCancelable(seconds: number): CancelableWaiter {
  return waitForMsCancelable(seconds * 1000);
}

export function cancelizePromise<T>(promise: Promise<T>): CancelableWaiter<T> {
  let cancel: () => void;
  const cancelablePromise = new Promise<T>((resolve, reject) => {
    promise.then((value) => resolve(value));
    cancel = () => reject(new PromiseCancelledError());
  });

  return {
    promise: cancelablePromise,
    cancel,
  };
}

export function cancelizePromiseWithSymbol<T>(promise: Promise<T>): CancelableWaiter<T | CancelSymbol> {
  let cancel: () => void;
  const cancelablePromise = new Promise<T | CancelSymbol>((resolve) => {
    promise.then((value) => resolve(value));
    cancel = () => resolve(cancelSymbol);
  });

  return {
    promise: cancelablePromise,
    cancel,
  };
}

export function suppressCancelled<T>(promise: Promise<T>): Promise<T> {
  return promise.catch((e: any) => {
    if (!PromiseCancelledError.isError(e)) {
      throw e;
    }
    return undefined;
  });
}

function defaultExpectedCondition<T>(value: T): boolean {
  return !!value;
}

export type WaitForExpectedValueOptions<T> = {
  condition?: (value: T) => boolean;
  retryOnError?: boolean;
  maxRetries?: number;
  retryDelay?: number;
  retryCallback?: (reason: any, retries: number, maxRetries: number) => void;
};

function createDefaultExpectedOptions<T>(): WaitForExpectedValueOptions<T> {
  return {
    condition: defaultExpectedCondition,
    retryOnError: true,
    maxRetries: 5,
    retryDelay: 1000,
  };
}

function rejectDelay<T>(delayMs: number, tries: number, maxRetries: number, retryCallback?: WaitForExpectedValueOptions<T>['retryCallback']) {
  return (reason: any) =>
    new Promise((resolve, reject) => {
      retryCallback?.(reason, tries, maxRetries);
      setTimeout(() => {
        reject(reason);
      }, delayMs);
    });
}

export function waitForExpectedValue<T>(fn: () => Promise<T>, options?: WaitForExpectedValueOptions<T>): CancelableWaiter<T> {
  const opts = {
    ...createDefaultExpectedOptions(),
    ...options,
  };
  let cancelled = false;
  let waitCancel: () => void = null;
  const cancel = () => {
    cancelled = true;
    waitCancel?.();
  };
  const promise = new Promise<T>(async (resolve, reject) => {
    let lastError: any;
    for (let i = 0; i < opts.maxRetries; i += 1) {
      if (cancelled) {
        reject(new PromiseCancelledError());
        return;
      }
      try {
        const value = await fn();
        // success, exit
        if (opts.condition(value)) {
          resolve(value);
          return;
        }

        // condition is false
        lastError = new Error('Condition failed');
        opts.retryCallback?.(lastError, i, opts.maxRetries);
      } catch (e) {
        lastError = e;
        // if not retrying on error, reject and exit
        if (!opts.retryOnError) {
          reject(e);
          return;
        }

        opts.retryCallback?.(e, i, opts.maxRetries);
      }

      const { promise: delayPromise, cancel: delayCancel } = waitForMsCancelable(opts.retryDelay);
      const suppressedDelayPromise = suppressCancelled(delayPromise);
      waitCancel = delayCancel;
      await suppressedDelayPromise;
      waitCancel = null;
    }

    if (!lastError) {
      lastError = new Error('Unknown error (No more retries)');
    }

    reject(lastError);
    return;
  });
  return {
    promise,
    cancel,
  };
}
