// general
import svsLogo from './logo-svs.svg';
import ethersLogo from './logo-ether.svg';
import ethersColorLogo from './logo-ether-color.svg';
import iconShareApple from './share-apple.svg';
import iconInstall from './icon-install.svg';
import boxedPlus from './boxed-plus.svg';
import pwaInstallLogo from './logo-gemz-pwa-install.png';
import gemzHorizontalLogo from './logo-gold-horizontal.svg';
import twitterXLogoSvg from './logo-twitter-x.svg';
// modals
import gemBgCreate from './_gemz/modals/gem-bg-create.svg';
import gemBgLocked from './_gemz/modals/gem-bg-locked.svg';
import gemBgStars from './_gemz/modals/gem-bg-stars.svg';
import gemBgPoints from './_gemz/modals/gem-bg-points.svg';
import gemIconCreate from './_gemz/modals/gem-icon-create.svg';
import gemIconLocked from './_gemz/modals/gem-icon-locked.svg';
// import avatarsPlus from './_gemz/modals/avatars-plus.svg';

import iconHandshake from './_gemz/modals/icon-handshake.svg';
import iconGraph from './_gemz/modals/icon-graph.svg';
import iconLink from './_gemz/modals/icon-link.svg';
import iconSwitchOn from './_gemz/modals/icon-switch-on.svg';
import iconSwitchOff from './_gemz/modals/icon-switch-off.svg';

import iconBackArrow from './_gemz/modals/icon-back-arrow.svg';
// pwa
import iconPwaGemz from './_gemz/pwa/icon-gemz.svg';
import iconPwaShare from './_gemz/pwa/icon-share.svg';
import iconPwaAdd from './_gemz/pwa/icon-add.svg';
// onboarding
import iconOnboardingChest from './_gemz/onboarding/chest.svg';
import iconOnboardingX from './_gemz/onboarding/icon-x.svg';
// top bar
import iconBack from './_gemz/topbar/back.svg';
import iconClose from './_gemz/topbar/close.svg';
import iconSearch from './_gemz/topbar/search.svg';
import iconPlusCircle from './_gemz/topbar/icon-plus-circle.svg';
// currencies
import iconCoin from './_gemz/topbar/currencies/icon-coin.svg';
import iconDiamond from './_gemz/topbar/currencies/icon-ethereum.svg';
import iconGemz from './_gemz/topbar/currencies/icon-gemz.svg';
import iconPoints from './_gemz/topbar/currencies/icon-points.svg';
import iconCrafting from './_gemz/topbar/currencies/icon-crafting.svg';
// bottom bar
import iconHomeOn from './_gemz/bottombar/home_active.svg';
import iconHomeOff from './_gemz/bottombar/home_inactive.svg';
import iconInboxOn from './_gemz/bottombar/activity_active.svg';
import iconInboxOff from './_gemz/bottombar/activity_inactive.svg';
import iconCreateOn from './_gemz/bottombar/newgame_active.svg';
import iconCreateOff from './_gemz/bottombar/newgame_inactive.svg';
import iconLeaderboardOn from './_gemz/bottombar/leaderboard_active.svg';
import iconLeaderboardOff from './_gemz/bottombar/leaderboard_inactive.svg';
import iconProfileOn from './_gemz/bottombar/profile_active.svg';
import iconProfileOff from './_gemz/bottombar/profile_inactive.svg';
import iconChatOn from './_gemz/bottombar/chat_active.svg';
import iconChatOff from './_gemz/bottombar/chat_inactive.svg';
// sidebar
import iconHeart from './_gemz/sidebar/heart.svg';
import iconShare from './_gemz/sidebar/share.svg';
import iconPlus from './_gemz/sidebar/plus.svg';
import iconComment from './_gemz/sidebar/comment.svg';
// create
import iconEdit from './_gemz/create/edit.svg';
import iconRefresh from './_gemz/create/refresh.svg';
// ai assistant
import iconAiGem from './_gemz/create/ai-assistant/icon-gem.svg';
import iconAiSend from './_gemz/create/ai-assistant/icon-send.svg';
// leaderboard
import iconCopy from './_gemz/leaderboard/copy.svg';
import iconCopyConsumed from './_gemz/leaderboard/copy-consumed.svg';
// profile
import iconProfileGemBgBlue from './_gemz/profile/icon-bg-blue.svg';
import iconArrowDown from './_gemz/profile/arrow-down-circle.svg';
import iconFavorites from './_gemz/profile/favorites.svg';
import mainPreview from './preview.png';
import iconThreeDots from './_gemz/profile/three-dots.svg';
import iconProfilePlay from './_gemz/profile/icon-play.svg';
import iconTabGames from './_gemz/profile/tabs/icon-tab-games.svg';
import iconTabHolding from './_gemz/profile/tabs/icon-tab-holding.svg';
import iconTabLikes from './_gemz/profile/tabs/icon-tab-likes.svg';

//account
import iconDeposit from './_gemz/account/deposit.svg';
import iconWithdraw from './_gemz/account/withdraw.svg';
import iconWallet from './_gemz/account/wallet.svg';
import iconAccountAccount from './_gemz/account/icon-account.svg';
import iconAccountWallet from './_gemz/account/icon-wallet.svg';
import iconAccountCharReward from './_gemz/account/icon-char-reward.svg';
import iconAccountLogout from './_gemz/account/icon-logout.svg';

// desktop
import desktopLogo from './_gemz/desktop/gemz_logo.svg';
import desktopSearch from './_gemz/desktop/search.svg';
import desktopArrowDown from './_gemz/desktop/arrow_down.svg';
import desktopExpand from './_gemz/desktop/expand.svg';
import desktopClose from './_gemz/desktop/close.svg';

// desktop-settings
import desktopSettingsDiamond from './_gemz/desktop/settings/diamond.svg';
import desktopSettingsHelp from './_gemz/desktop/settings/help.svg';
import desktopSettingsLeaderboard from './_gemz/desktop/settings/leaderboard.svg';
import desktopSettingsSettings from './_gemz/desktop/settings/settings.svg';
import desktopSettingsSignout from './_gemz/desktop/settings/signout.svg';
import desktopSettingsUser from './_gemz/desktop/settings/user.svg';

import privyChest from './_gemz/privy/chest.svg';

export const images = {
  twitterLogo: require('./logo-twitter.png'),
  twitterXLogoSvg,
  svsLogo,
  ethersLogo,
  ethersColorLogo,
  iconShareApple,
  iconInstall,
  boxedPlus,
  pwaInstallLogo,
  gemzHorizontalLogo,
  placeholder: require('./placeholder.png'),
  placeholder2: require('./placeholder2.png'),
  defaultUser: require('./avatar.png'),
  //modals
  gemBgCreate,
  gemBgStars,
  gemBgLocked,
  gemBgPoints,
  gemIconCreate,
  gemIconLocked,
  // avatarsPlus,

  iconHandshake,
  iconGraph,
  iconLink,
  iconSwitchOn,
  iconSwitchOff,

  iconBackArrow,
  // pwa
  iconPwaGemz,
  iconPwaShare,
  iconPwaAdd,
  // onboarding
  iconOnboardingChest,
  iconOnboardingX,
  // top bar
  iconBack,
  iconClose,
  iconSearch,
  iconPlusCircle,
  // currencies
  iconCoin,
  iconPoints,
  iconDiamond,
  iconGemz,
  iconCrafting,

  // bottom bar
  iconHomeOn,
  iconHomeOff,
  iconInboxOn,
  iconInboxOff,
  iconCreateOn,
  iconCreateOff,
  iconLeaderboardOn,
  iconLeaderboardOff,
  iconProfileOn,
  iconProfileOff,
  iconChatOn,
  iconChatOff,
  // sidebar
  iconHeart,
  iconShare,
  iconPlus,
  iconComment,
  // create
  iconEdit,
  iconRefresh,
  // ai assistant
  iconAiGem,
  iconAiSend,
  //leaderboard
  iconCopy,
  iconCopyConsumed,
  // profile
  iconProfileGemBgBlue,
  iconArrowDown,
  iconFavorites,
  iconThreeDots,
  iconProfilePlay,
  iconTabGames,
  iconTabHolding,
  iconTabLikes,
  // account
  iconDeposit,
  iconWithdraw,
  iconWallet,
  iconAccountAccount,
  iconAccountWallet,
  iconAccountCharReward,
  iconAccountLogout,
  // end game modals
  gemAssetMockup: require('./_gemz/_mockups/gem-goldenbanana.png'),
  // shared stuff
  mainPreview,
  // desktop
  desktopLogo,
  desktopSearch,
  desktopArrowDown,
  desktopExpand,
  desktopClose,
  // desktop-settings
  desktopSettingsDiamond,
  desktopSettingsHelp,
  desktopSettingsLeaderboard,
  desktopSettingsSettings,
  desktopSettingsSignout,
  desktopSettingsUser,
  // others
  privyChest,
};
