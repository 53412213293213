import { AppEvents, GameEvents } from '@storyverseco/svs-types';
import { SvsBridge } from '@storyverseco/svs-navbar';
import { env } from '../config/env';

export const navbar = new SvsBridge({
  persistStoryServerPrefix: env.persistStoryServerPrefix,
  serviceProxyUrl: env.serviceProxyUrl,
  plotsBaseURI: env.plotsBaseURI,
  persistPlotServerPrefix: env.persistPlotServerPrefix,
  foundersPassContract: env.foundersPassContract,
  templateApiPrefix: env.templateApiPrefix,
  assetsApiPrefix: env.assetsApiPrefix,
  chainIds: env.chainIds,
  walletConnectProjectId: env.walletConnectId,
  walletConnectConnectorVersion: env.walletConnectorVersion,
  pipelineBaseUrl: env.pipelineBaseUrl,
  authBaseUrl: env.authBaseUrl,
  mediaServiceBaseUrl: env.mediaServiceBaseUrl,
  mbaasBaseUrl: env.mbaasBaseUrl,
  mbaasApiKey: env.mbaasApiKey,
});

// Let's try to hide as much as we can the navbar export

export const sendGameEvent = (evt: AppEvents, opts?: any) => {
  navbar.sendClientEvent(evt as unknown as string, opts);
};

export const listenToGameEvent = (evt: GameEvents, listener: (opts?: any) => void) => {
  navbar.on(evt as unknown as string, listener);
  return () => {
    navbar.off(evt as unknown as string, listener);
  };
};
