import { Logger } from './BaseClass';

export type Fn<T> = (param?: any) => T;

export interface EventTrigger {
  name: string;
  params?: Record<string, any>;
}

export class EventListener extends Logger {
  protected events: Record<string, Fn<void>[]> = {};

  /**
   *
   * @param eventName
   * @param callback
   * @returns removeEventListener function
   */
  addEventListener = (eventName: string, callback: Fn<void>) => {
    if (!this.events[eventName]) {
      this.events[eventName] = [];
    }
    this.events[eventName].push(callback);
    return () => {
      this.events[eventName] = this.events[eventName].filter((cb) => cb !== callback);
    };
  };

  // Just sugar sytanx to make it easier to attach to components re-render hook
  attachEventListener = (eventName: string) => (callback: Fn<void>) => {
    return this.addEventListener(eventName, callback);
  };

  /**
   * Pass only event name when no params are required. Otherwise use EventTrigger
   */
  protected sendEvents = (events: EventTrigger[] | string[]) => {
    const normalisedList = (typeof events[0] === 'string' ? events.map((evt) => ({ name: evt })) : events) as EventTrigger[];

    normalisedList.forEach(({ name, params }) => {
      if (this.events[name]) {
        this.events[name].forEach((cb) => {
          cb(params);
        });
      }
    });
  };
}
