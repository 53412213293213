export const getErrorMsg = (from: string, msg: string) => `Error (${from}): ${msg}`;

export const customError = (from: string, msg: string) => {
  return new Error(getErrorMsg(from, msg));
};

export const waitFor = (timeMs: number) => new Promise((resolve) => setTimeout(resolve, timeMs));

/**
 * Checks if ONLY null or undefined. Excludes falsy values.
 * @param a anything
 */
export const isNullOrUndefined = (a: any): boolean => typeof a === 'undefined' || a === null;

/**
 * Cast both to numbers and check if equals. Nulls won't be considered equals.
 * @param a
 * @param b
 * @returns
 */
export const isSameNums = (a: any, b: any): boolean => a !== null && b !== null && Number(a) === Number(b);

/**
 * Removes `@` prefix if it has one. Undefined username returns undefined.
 * @param username
 * @returns
 */
export const removeAtUsername = (username: string | undefined): string | undefined => username?.replace(/^@+/, '');

/**
 * Prefixes the `@` symbol if it doesn't already have one. Also collapses multiple `@` prefixes into one.
 * Undefined username returns undefined.
 * @param username
 * @returns
 */
export const addAtUsername = (username: string | undefined): string | undefined => username?.replace(/^@*(.+)$/, '@$1');

/**
 * Collapse any new lines into a single space.
 * @param str
 * @returns
 */
export const removeNewLines = (str: string) => str.replace(/(\r?\n)+/g, ' ');

/**
 * Collapse whitespaces into a single space
 * @param str
 * @returns
 */
export const collapseWhiteSpace = (str: string) => str.replace(/\s+/g, ' ');
