export enum GameLikeState {
  Idle = 'GameLikeState/Idle',
  Unliked = 'GameLikeState/Unliked',
  Unliking = 'GameLikeState/Unliking',
  Liked = 'GameLikeState/Liked',
  Liking = 'GameLikeState/Liking',
}

export const inProgressGameLikeStates = [GameLikeState.Idle, GameLikeState.Unliking, GameLikeState.Liking];

export const likedStates = [GameLikeState.Liked, GameLikeState.Liking];

export const allGameLikeStates = Object.values(GameLikeState).filter((g) => typeof g === 'string');
