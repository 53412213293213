import { Modals } from 'features/Modals';
import { ComponentController } from '../../lib/controllers/ComponentController';
import { waitFor } from 'lib/utils';
import { AppController } from 'lib/Controller';
import { Game } from 'lib/Game';

export interface ModalOpts {
  title?: string;
  subtitle?: string;
  disableBackdrop?: boolean;
  message?: string;
  btnText?: string;
  onBackdropClick?: () => void;
  onClose?: () => void;
  data?: Record<string, any>;
  onBtnClick?: () => void;
  // extend as needed
  useConfettiEffect?: boolean;
  useAnimationEffect?: null | 'buy' | 'sell';
  previousModal?: Modals;
}

enum ModalState {
  Closed = 'Closed',
  AnimatingIn = 'AnimatingIn',
  Open = 'Open',
  AnimatingOut = 'AnimatingOut',
}

const ANIMATION_TIME_OPEN = 350;

const MAX_RETRIES = 10;

export class ModalController extends ComponentController {
  private lazyCache: Modals[] = [];

  private currentModal?: Modals;

  private currentOpts?: ModalOpts;

  private state = ModalState.Closed;

  private get loaded() {
    return this.lazyCache.includes(this.currentModal);
  }

  get active() {
    return this.state === ModalState.Open;
  }

  get show() {
    return this.state === ModalState.AnimatingIn || this.state === ModalState.Open;
  }

  get current() {
    const [mode, type] = this.currentModal?.split('/') || [];
    return {
      id: this.currentModal,
      ...this.currentOpts,
      mode,
      type,
    };
  }

  constructor(private app: AppController) {
    super();
  }

  private waitForLazyLoad = async (id: Modals, tries = 0) => {
    this.log('waitForLazyLoad', id, this.loaded);
    if (this.loaded) {
      return Promise.resolve();
    }
    await waitFor(100 + 100 * tries);
    if (tries >= MAX_RETRIES) {
      this.log('waitForLazyLoad', 'max retries reached', { id });
      return Promise.reject();
    }
    return this.waitForLazyLoad(id, ++tries);
  };

  onLazyModalLoaded = (modal: Modals) => {
    this.log('onLazyModalLoaded', { modal });
    this.lazyCache.push(modal);
  };

  close = async () => {
    this.log('close');
    this.state = ModalState.AnimatingOut;
    this.updateComponent();
    await waitFor(ANIMATION_TIME_OPEN);
    this.app.feed.carousel.enableScroll();
    const onClose = this.currentOpts?.onClose;
    // Only clear the current modal after animating otherwise the content from the modal disappears before animation
    this.currentOpts = undefined;
    this.currentModal = undefined;
    this.state = ModalState.Closed;
    this.updateComponent();
    if (onClose) {
      onClose();
    }
  };

  open = async (modal: Modals, opts?: ModalOpts) => {
    this.log('open: try', { modal, opts });
    if (this.currentModal && modal === this.currentModal) {
      return;
    }

    if (this.show) {
      this.log('open', 'close currently showing modal');
      await this.close();
    }
    // Skip a frame so the component has time to update
    // This ensure opening a modal with another open or just after to not skip component update
    await waitFor(0);

    this.app.feed.carousel.disableScroll();
    this.log('open', { modal, opts });
    this.currentModal = modal;
    this.updateComponent();
    try {
      await this.waitForLazyLoad(modal);
      this.currentOpts = opts;
      this.state = ModalState.AnimatingIn;
      this.updateComponent();
      await waitFor(ANIMATION_TIME_OPEN);
      this.state = ModalState.Open;
      this.updateComponent();
    } catch (e) {
      this.log('open', 'failed to load modal', { modal });
      this.close();
    }
  };

  setOpts = (opts: ModalOpts, isUpdate = true) => {
    if (isUpdate) {
      this.currentOpts = {
        ...this.currentOpts,
        ...opts,
      };
    } else {
      this.currentOpts = opts;
    }
  };
}
