export const SECOND_TO_MS = 1000;
export const MINUTE_TO_SECONDS = 60;
export const HOUR_TO_MINUTES = 60;
export const DAY_TO_HOURS = 24;
export const WEEK_TO_DAYS = 7;
export const MONTH_TO_DAYS = 30;

export const secondsToMs = (seconds: number) => seconds * SECOND_TO_MS;
export const minutesToMs = (minutes: number) => minutes * MINUTE_TO_SECONDS * SECOND_TO_MS;
export const hoursToMs = (hours: number) => hours * HOUR_TO_MINUTES * MINUTE_TO_SECONDS * SECOND_TO_MS;
export const daysToMs = (days: number) => days * DAY_TO_HOURS * HOUR_TO_MINUTES * MINUTE_TO_SECONDS * SECOND_TO_MS;
export const weeksToMs = (weeks: number) => weeks * WEEK_TO_DAYS * DAY_TO_HOURS * HOUR_TO_MINUTES * MINUTE_TO_SECONDS * SECOND_TO_MS;
export const monthsToMs = (months: number) => months * MONTH_TO_DAYS * WEEK_TO_DAYS * DAY_TO_HOURS * HOUR_TO_MINUTES * MINUTE_TO_SECONDS * SECOND_TO_MS;

export const msToSeconds = (ms: number) => ms / SECOND_TO_MS;
export const msToMinutes = (ms: number) => ms / (MINUTE_TO_SECONDS * SECOND_TO_MS);
export const msToHours = (ms: number) => ms / (HOUR_TO_MINUTES * MINUTE_TO_SECONDS * SECOND_TO_MS);
export const msToDays = (ms: number) => ms / (DAY_TO_HOURS * HOUR_TO_MINUTES * MINUTE_TO_SECONDS * SECOND_TO_MS);
export const msToWeeks = (ms: number) => ms / (WEEK_TO_DAYS * DAY_TO_HOURS * HOUR_TO_MINUTES * MINUTE_TO_SECONDS * SECOND_TO_MS);
export const msToMonths = (ms: number) => ms / (MONTH_TO_DAYS * WEEK_TO_DAYS * DAY_TO_HOURS * HOUR_TO_MINUTES * MINUTE_TO_SECONDS * SECOND_TO_MS);

export const humanizeOrder: { convert: (ms: number) => number; unit: string; shortUnit: string }[] = [
  { convert: msToMonths, unit: 'month', shortUnit: 'mo' },
  { convert: msToWeeks, unit: 'week', shortUnit: 'w' },
  { convert: msToDays, unit: 'day', shortUnit: 'd' },
  { convert: msToHours, unit: 'hour', shortUnit: 'h' },
  { convert: msToMinutes, unit: 'minute', shortUnit: 'm' },
  { convert: msToSeconds, unit: 'second', shortUnit: 's' },
];

/**
 * Convert milliseconds to something like "5 days" or "5d".
 * @param ms
 * @param useShortUnit
 * @returns
 */
export const humanizeTimestamp = (ms: number, useShortUnit = false): string => {
  let output: string;
  for (const { convert, unit, shortUnit } of humanizeOrder) {
    const num = convert(ms);
    const normalized = Math.max(0, Math.floor(num));
    if (useShortUnit) {
      output = `${normalized}${shortUnit}`;
    } else {
      output = `${normalized} ${unit}${normalized === 1 ? '' : 's'}`;
    }
    if (num >= 1) {
      break;
    }
  }

  return output;
};
