import { ComponentController } from './ComponentController';
import { api } from '../apis';
import { LoadState } from '../loadState';
import { debounceWaiterWrap } from '../DebounceWaiter';
import { AppController } from '../Controller';
import { SingleRoomController, SingleRoomEvent } from './SingleRoomController';
import { ConditionWaiter } from '../ConditionWaiter';
import { isSameNums } from '../utils';
import { ChatMessageData, ChatRoomData } from '@storyverseco/svs-types';
import { RoomProps } from '../apis/chat';
import { secondsToMs } from '../time';
import { MessagesUpdater } from '../chatTypes';

export enum RoomListEvent {
  ListStateChange = 'RoomListEvent/RoomListStateChange',
  ListChange = 'RoomListEvent/RoomListChange',
  ListMessageChange = 'RoomListEvent/RoomListMessageChange',
  ActiveRoomChange = 'RoomListEvent/ActiveRoomChange',
}

interface RoomStruct {
  room?: ChatRoomData;
  controller?: SingleRoomController;
}

interface RoomMapping {
  roomIdMap: Record<number, RoomStruct>;
  creatorIdMap: Record<number, RoomStruct>;
  creatorUsernameMap: Record<number, RoomStruct>;
}

export class RoomListController extends ComponentController {
  private _rooms: ChatRoomData[] = [];
  private _activeController: SingleRoomController = undefined;
  private roomMapping: RoomMapping = {
    roomIdMap: {},
    creatorIdMap: {},
    creatorUsernameMap: {},
  };

  private listStateWaiter = new ConditionWaiter(
    LoadState.Idle,
    (state) => state === LoadState.Loaded,
    (state) => (state === LoadState.Errored ? new Error('List load failed') : undefined),
  );

  private messageUpdater: MessagesUpdater;

  get listState() {
    return this.listStateWaiter.get();
  }

  get rooms() {
    if (this.listState === LoadState.Idle) {
      this.loadRooms().catch((e) => {
        console.error('RoomListController.rooms error:', e);
      });
    }
    return this._rooms;
  }

  get activeSingleRoomController(): SingleRoomController | undefined {
    return this._activeController;
  }

  constructor(private app: AppController) {
    super();
    this.messageUpdater = new PollingMessagesUpdater(app, this.onNewMessages);
  }

  // TODO pagination
  loadRooms = debounceWaiterWrap(async (force = false): Promise<ChatRoomData[]> => {
    if (!force && this.listState === LoadState.Loaded) {
      return this._rooms;
    }

    this.setListState(LoadState.Loading);

    try {
      const rooms = await api.chat.get.allRooms();
      this._rooms = rooms;
      rooms.forEach(this.putRoom);
      // preload room controllers
      rooms.forEach((room) => this.getRoomController({ roomId: room.id }));
      this.sortRooms();
      this.setListState(LoadState.Loaded);
      this.sendEvents([RoomListEvent.ListChange]);
      return rooms;
    } catch (e) {
      console.error('RoomListController.loadRooms error:', e);
      this.setListState(LoadState.Errored);
      throw e;
    }
  });

  loadSingleRoom = async (roomProps: RoomProps, force = false): Promise<ChatRoomData> => {
    const existingRoom = this.getRoomByProp(roomProps);
    if (!force && existingRoom) {
      return existingRoom;
    }

    try {
      const room = await api.chat.get.room(roomProps);
      this.putRoom(room);
      return room;
    } catch (e) {
      console.error('RoomListController.loadSingleRoom error:', e);
      throw e;
    }
  };

  /**
   * Get room controller for this id or username.
   *
   * This allows getting a room controller, even if the room has not yet been loaded.
   * If the room has not already been loaded, it will be loaded and update the controller
   * and dispatch relevant events.
   * @param roomProps
   * @returns
   */
  getRoomController = (roomProps: RoomProps): SingleRoomController => {
    let roomController = this.getControllerByProp(roomProps);

    const room = this.getRoomByProp(roomProps);
    if (!roomController) {
      roomController = new SingleRoomController(this.app, room);

      // bubble up message list changes to room list
      roomController.addEventListener(SingleRoomEvent.MessageListChange, () => {
        this.sortRooms();
        this.sendEvents([RoomListEvent.ListMessageChange]);
      });

      // set/unset active room controller
      roomController.addEventListener(SingleRoomEvent.VisibilityChange, () => {
        if (roomController.visible) {
          this.setActiveSingleRoomController(roomController);
        } else if (!roomController.visible && roomController === this.activeSingleRoomController) {
          // only unset if the active room controller is this controller, to avoid accidentally unsetting
          // a different room controller that's actually active
          this.setActiveSingleRoomController(undefined);
        }
      });

      this.putControllerByProp(roomProps, roomController);
      if (!room) {
        // asynchronously load the controller's room
        this.loadSingleRoom(roomProps)
          .then((nextRoom) => {
            roomController.setRoom(nextRoom);
            this.putControllerByProp(roomProps, roomController); // update internal mappings
          })
          .catch((e) => {
            console.error('RoomListController.getRoomController error:', e);
            roomController.setRoomLoadState(LoadState.Errored);
          });
      }
    }

    if (room && roomController.room !== room) {
      // update room if changed
      roomController.setRoom(room);
    }
    return roomController;
  };

  protected override onVisibilityChange = (): void => {
    this.maybeStartOrStopChecking();
    if (this.isVisible) {
      this.loadRooms(true).catch((e) => {
        console.error('RoomListController.onVisibilityChange loadRooms error', e);
      });
    }
  };

  private maybeStartOrStopChecking = (): void => {
    this.listStateWaiter
      .wait()
      .then(() => {
        if (this.isVisible) {
          this.messageUpdater.startCheckingForMessages();
        } else {
          this.messageUpdater.stopCheckingForMessages();
        }
      })
      .catch((e) => {
        console.error('RoomListController.maybeStartOrStopChecking error:', e);
      });
  };

  private setListState = (state: LoadState, forceUpdate = false): void => {
    if (!forceUpdate && this.listStateWaiter.get() === state) {
      return;
    }
    this.listStateWaiter.set(state);
    this.sendEvents([RoomListEvent.ListStateChange]);
    this.updateComponent();
  };

  private getRoomByProp = (roomProps: RoomProps): ChatRoomData | undefined => {
    if (roomProps.roomId) {
      return this.roomMapping.roomIdMap[roomProps.roomId]?.room;
    }
    if (roomProps.creatorId) {
      return this.roomMapping.creatorIdMap[roomProps.creatorId]?.room;
    }
    if (roomProps.creatorUsername) {
      return this.roomMapping.creatorUsernameMap[roomProps.creatorUsername.toLowerCase()]?.room;
    }

    throw new Error('RoomListController.getRoomByProp error: requires roomId, creatorId or creatorUsername');
  };

  private putRoom = (room: ChatRoomData): void => {
    if (!room) {
      return;
    }
    this.roomMapping.roomIdMap[room.id] ??= {};
    this.roomMapping.roomIdMap[room.id].room = room;
    this.roomMapping.creatorIdMap[room.creatorId] ??= {};
    this.roomMapping.creatorIdMap[room.creatorId].room = room;
    if (room.creator?.username) {
      const username = room.creator.username.toLowerCase();
      this.roomMapping.creatorUsernameMap[username] ??= {};
      this.roomMapping.creatorUsernameMap[username].room = room;
    }
  };

  private getControllerByProp = (roomProps: RoomProps): SingleRoomController | undefined => {
    if (roomProps.roomId) {
      return this.roomMapping.roomIdMap[roomProps.roomId]?.controller;
    }
    if (roomProps.creatorId) {
      return this.roomMapping.creatorIdMap[roomProps.creatorId]?.controller;
    }
    if (roomProps.creatorUsername) {
      return this.roomMapping.creatorUsernameMap[roomProps.creatorUsername.toLowerCase()]?.controller;
    }

    throw new Error('RoomListController.getControllerByProp error: requires roomId, creatorId, or creatorUsername');
  };

  private putControllerByProp = (roomProps: RoomProps, controller: SingleRoomController): void => {
    const roomId = controller.room?.id ?? roomProps.roomId;
    const creatorId = controller.room?.creatorId ?? roomProps.creatorId;
    const creatorUsername = controller.room?.creator?.username ?? roomProps.creatorUsername;
    if (roomId) {
      this.roomMapping.roomIdMap[roomId] ??= {};
      this.roomMapping.roomIdMap[roomId].controller = controller;
    }
    if (creatorId) {
      this.roomMapping.creatorIdMap[creatorId] ??= {};
      this.roomMapping.creatorIdMap[creatorId].controller = controller;
    }
    if (creatorUsername) {
      const username = creatorUsername.toLowerCase();
      this.roomMapping.creatorUsernameMap[username] ??= {};
      this.roomMapping.creatorUsernameMap[username].controller = controller;
    }
  };

  private sortRooms = (): void => {
    const rooms = this._rooms.slice();
    const me = this.app.user.me;

    rooms.sort((a, b) => {
      if (isSameNums(a.creator.id, me.id)) {
        return -1;
      }
      if (isSameNums(b.creator.id, me.id)) {
        return 1;
      }
      const aRoomCtrl = this.getRoomController({ roomId: a.id });
      const bRoomCtrl = this.getRoomController({ roomId: b.id });
      const aMessage = aRoomCtrl.latestMessage;
      const bMessage = bRoomCtrl.latestMessage;
      if (!aMessage?.createdAt && !bMessage?.createdAt) {
        return 0;
      }
      if (!aMessage?.createdAt) {
        return 1;
      }
      if (!bMessage?.createdAt) {
        return -1;
      }

      const aTime = new Date(aMessage.createdAt).getTime();
      const bTime = new Date(bMessage.createdAt).getTime();
      return bTime - aTime;
    });

    this._rooms = rooms;
    this.updateComponent();
    this.sendEvents([RoomListEvent.ListChange]);
  };

  private setActiveSingleRoomController = (singleRoomController: SingleRoomController | undefined): void => {
    console.log('setActiveSingleRoomController', { singleRoomController });
    if (this._activeController !== singleRoomController) {
      this._activeController = singleRoomController;
      this.updateComponent();
      this.sendEvents([RoomListEvent.ActiveRoomChange]);
    }
  };

  private onNewMessages = (messages: ChatMessageData[]): void => {
    // we cannot use Object.groupBy because samsung internet browser doesn't support it, and
    const roomMessagesMap = messages.reduce((map, message) => {
      map[message.roomId] ??= [];
      map[message.roomId].push(message);
      return map;
    }, {} as Record<number, ChatMessageData[]>);

    for (const [roomIdStr, messages] of Object.entries(roomMessagesMap)) {
      const roomCtrl = this.getControllerByProp({ roomId: Number(roomIdStr) });
      roomCtrl?.appendMessages(messages);
    }
  };
}

class PollingMessagesUpdater implements MessagesUpdater {
  private intervalId: ReturnType<typeof setInterval> = undefined;

  private intervalTime = secondsToMs(5); // every 5 seconds

  private checkingEnabled = false;

  private initDateIso = new Date().toISOString();

  private latestMessageIso: string = undefined;

  private pageSize = 15;

  constructor(private app: AppController, private onNewMessages: (message: ChatMessageData[]) => void) {}

  startCheckingForMessages = (checkNow?: boolean): void => {
    if (!this.app.isAuth) {
      // need to be logged in
      throw new Error('PollingSingleRoomController.startCheckingForMessages: not logged in');
    }
    if (this.intervalId !== undefined) {
      // already started
      return;
    }
    if (this.checkingEnabled) {
      // already started
      return;
    }

    this.checkingEnabled = true;
    this.intervalId = setInterval(this.poll, this.intervalTime);
    if (checkNow) {
      this.poll();
    }
  };
  stopCheckingForMessages = (): void => {
    this.checkingEnabled = false;
    if (this.intervalId === undefined) {
      return;
    }

    clearInterval(this.intervalId);
    this.intervalId = undefined;
  };

  private poll = async (): Promise<void> => {
    if (!this.app.isAuth) {
      throw new Error('PollingMessagesUpdater.startCheckingForMessages: not logged in');
    }

    let nextMessages: ChatMessageData[];
    const limit = this.pageSize;
    let newMessages: ChatMessageData[] = [];

    try {
      const after = this.latestMessageIso ?? this.initDateIso ?? new Date().toISOString();
      do {
        nextMessages = [];
        const offset = newMessages.length;

        nextMessages = await api.chat.get.allMessages({ offset, limit, after });

        newMessages = newMessages.concat(nextMessages);
      } while (nextMessages.length >= limit);

      if (newMessages.length) {
        this.onNewMessages(newMessages);

        // get the latest message iso from this list
        // this should already be ordered, so just get the last message
        this.latestMessageIso = newMessages[newMessages.length - 1].createdAt;
      }
    } catch (e) {
      console.error('PollingMessagesUpdater.poll error:', e);
    }
  };
}
