import { images } from 'assets/assets';
import { useNavigation } from 'hooks/useNavigation';
import { TopBarCurrency } from './TopBarCurrency';
import { Routes } from 'router';
import { useReRender } from 'hooks/useReRender';
import { controller } from 'lib/Controller';

export function TopBarCoinCurrency() {
  const { navigate } = useNavigation();

  const { visible, setComponent, state } = controller.ui.elements.TopBarCoinCurrency;

  const { coinBalance = 0 } = controller.user.me || {};

  useReRender({ id: 'TopBarCoinCurrency', listener: setComponent });

  const onClick = () => {
    // don' allow interaction/navigation if any modal is open
    if (controller.modals.current?.id === undefined) return;
    navigate(Routes.Account);
  };

  return (
    <TopBarCurrency type="coin" onClick={onClick} show={visible} icon={images.iconCoin} fakeBalance={state.displayBalance} userBalance={Number(coinBalance)} />
  );
}
