import { ComponentType, useCallback, useEffect, useMemo, useState } from 'react';
import { images } from 'assets/assets';
import { getHighResTwitterPic } from 'lib/twitterUtils';
import { useNavigation } from 'hooks/useNavigation';
import { ellipsisize } from 'lib/textFormats';
import { useSimplifyNames } from 'hooks/useSimplifyNames';
import { ActivityItem, ActivityType, FEOnlyActivityType, activityTypes } from '../types';
import { controller } from 'lib/Controller';
import { Routes, getRouteWithParams } from 'router';
import { DynamicMappedComponent } from 'components/DynamicMappedComponent';
import { formatEther } from 'viem';
import { swUpdater } from 'lib/apis/swupdate';
import { QueryParams } from 'config/consts';
import { addAtUsername } from 'lib/utils';
import { useHumanizedAgo } from 'hooks/useHumanizedAgo';

const SWIPE_DELTA_X_LIMIT = 20;
const SWIPE_DELTA_Y_THRESHOLD = -15;

interface ToastProps {
  activity: ActivityItem;
}

export const SingleToast = ({ activity, onClick, onSwipeUp }: { activity: ActivityItem; onClick?: () => void; onSwipeUp?: () => void }) => {
  const { type, gameId, offChainGameId } = activity;
  const { navigate } = useNavigation();
  const [toastDiv, setToastDiv] = useState<HTMLDivElement>(undefined);

  const { markSingleItemAsRead } = controller.notifications;

  const onNotificationClick = useCallback(() => {
    onClick?.();

    // only valid activity types
    if (activityTypes.includes(activity.type as ActivityType)) {
      markSingleItemAsRead(activity.activityId);
    }

    if (type === ActivityType.PriceWentUp) {
      return navigate(getRouteWithParams(Routes.GameFeed, { gameId: offChainGameId ?? gameId }));
    }
    if (type === ActivityType.FollowingCreatedGem) {
      return navigate(getRouteWithParams(Routes.GameFeed, { gameId: offChainGameId ?? gameId }));
    }
    if (type === ActivityType.Welcome) {
      return navigate(getRouteWithParams(Routes.GameFeed));
    }
    if (type === ActivityType.CommentedGame) {
      const commentId = activity.extra?.commentId ? Number(activity.extra?.commentId) : undefined;
      let searchParams: URLSearchParams = undefined;
      if (commentId) {
        searchParams = new URLSearchParams();
        searchParams.append(QueryParams.CommentId, commentId.toString());
      }
      return navigate(getRouteWithParams(Routes.GameFeed, { gameId: offChainGameId ?? gameId }, searchParams));
    }
    if (type === FEOnlyActivityType.UpdateAvailable) {
      swUpdater.applyNewUpdate();
      return;
    }
    // TODO(jb): go to a "list of grouped activities" page
    if (activity.items && activity.items.length > 1) {
      // do nothing
      return;
    }
    const { participantName } = activity;
    controller.profile.load(participantName);
    navigate(getRouteWithParams(Routes.ProfileAt, { username: addAtUsername(participantName) }));
  }, [activity, onClick]);

  useEffect(() => {
    if (!toastDiv) {
      return;
    }

    let startX: number;
    let startY: number;
    let pointerId: number;
    let down = false;
    let triggered = false;

    const maybeSwipe = (e: PointerEvent, threshold: number) => {
      if (triggered) {
        return;
      }

      const deltaX = e.screenX - startX;
      const deltaY = e.screenY - startY;
      if (deltaY < threshold && Math.abs(deltaX) <= SWIPE_DELTA_X_LIMIT) {
        triggered = true;
        onSwipeUp?.();
      }
    };

    const onPointerDown = (e: PointerEvent) => {
      if (down) {
        return;
      }

      down = true;
      pointerId = e.pointerId;
      startX = e.screenX;
      startY = e.screenY;
    };

    const onPointerMove = (e: PointerEvent) => {
      if (!down) {
        return;
      }
      if (pointerId !== e.pointerId) {
        return;
      }
      maybeSwipe(e, SWIPE_DELTA_Y_THRESHOLD);
    };

    const onPointerUp = (e: PointerEvent) => {
      if (!down) {
        return;
      }
      if (pointerId !== e.pointerId) {
        return;
      }
      down = false;
      maybeSwipe(e, SWIPE_DELTA_Y_THRESHOLD);
    };

    const onPointerOut = (e: PointerEvent) => {
      if (!down) {
        return;
      }
      if (pointerId !== e.pointerId) {
        return;
      }
      down = false;
      maybeSwipe(e, 0); // if leaving element or screen, check only if the general direction was up
    };

    toastDiv.addEventListener('pointerdown', onPointerDown, false);
    toastDiv.addEventListener('pointermove', onPointerMove, false);
    toastDiv.addEventListener('pointerup', onPointerUp, false);
    toastDiv.addEventListener('pointerout', onPointerOut, false);

    return () => {
      toastDiv.removeEventListener('pointerdown', onPointerDown, false);
      toastDiv.removeEventListener('pointermove', onPointerMove, false);
      toastDiv.removeEventListener('pointerup', onPointerUp, false);
      toastDiv.removeEventListener('pointerout', onPointerOut, false);
    };
  }, [toastDiv, onSwipeUp]);

  return (
    <div className="toast" onClick={onNotificationClick} ref={setToastDiv}>
      <div className="toast-images">
        <PicSection activity={activity} />
      </div>
      <div className="toast-info">
        <DynamicMappedComponent type={type as ActivityType & FEOnlyActivityType} components={components} activity={activity} />
      </div>
    </div>
  );
};

const imageOverrides = {
  [ActivityType.Welcome]: images.iconAiGem,
  [FEOnlyActivityType.UpdateAvailable]: images.iconAiGem,
};

const PicSection = ({ activity }: { activity: ActivityItem }) => {
  const pics: string[] = useMemo(() => {
    if (activity.items && activity.items.length > 1) {
      // max 3 pics
      return activity.items.slice(0, 3).map((item) => imageOverrides[item.type] || getHighResTwitterPic(item.participantImage) || images.defaultUser);
    }
    return [imageOverrides[activity.type] || getHighResTwitterPic(activity.participantImage) || images.defaultUser];
  }, [activity]);

  return (
    <>
      {pics.map((pic, index) => (
        <div key={index} className="toast-round-picture" style={{ backgroundImage: `url(${pic})` }}></div>
      ))}
    </>
  );
};

const WelcomeToast = (_: ToastProps) => {
  return (
    <div className="description">
      <span className="gray">Welcome to </span>
      <span className="white">Gemz. </span>
      <span className="gray">Play games. Unlock gemz. Be legendary.</span>
    </div>
  );
};

// someone bought or sold your gem
const TradeToast = ({ activity }: ToastProps) => {
  const { gemName, type, price } = activity;
  const namesStr = useSimplifyNames(activity);
  const shortGemName = useMemo(() => {
    return ellipsisize(gemName, 4, 50);
  }, [gemName]);
  return (
    <div className="description">
      <span className="white">{namesStr}</span>
      <span className="gray"> {type === ActivityType.Buy ? 'bought' : 'sold'} your </span>
      <span className="white">{shortGemName}.</span>
      {price && <span className="gray"> You got {formatEther(BigInt(price))}ETH</span>}
    </div>
  );
};

// someone visited your profile
const VisitToast = ({ activity }: ToastProps) => {
  const namesStr = useSimplifyNames(activity);
  return (
    <div className="description">
      <span className="white">{namesStr}</span>
      <span className="gray"> viewed your profile </span>
    </div>
  );
};

// price of a gem you own went up
const PriceWentUpToast = ({ activity }: ToastProps) => {
  const { participantName, gemName, price } = activity;
  const shortGemName = useMemo(() => {
    return ellipsisize(gemName, 4, 50);
  }, [gemName]);
  return (
    <div className="description">
      <span className="white">
        {participantName}'s {shortGemName}
      </span>
      <span className="gray"> price went up! It is {formatEther(BigInt(price))}ETH now!</span>
    </div>
  );
};

// someone followed you
const FollowToast = ({ activity }: ToastProps) => {
  const namesStr = useSimplifyNames(activity);
  return (
    <div className="description">
      <span className="white">{namesStr}</span>
      <span className="gray"> followed you </span>
    </div>
  );
};

// someone followed you
const FollowBackToast = ({ activity }: ToastProps) => {
  const namesStr = useSimplifyNames(activity);
  return (
    <div className="description">
      <span className="white">{namesStr}</span>
      <span className="gray"> followed back </span>
    </div>
  );
};

// someone followed you
const FollowingCreatedGemToast = ({ activity }: ToastProps) => {
  const { participantName, gemName } = activity;
  const shortGemName = useMemo(() => {
    return ellipsisize(gemName, 4, 50);
  }, [gemName]);
  return (
    <div className="description">
      <span className="white">{participantName}</span>
      <span className="gray"> created a new gem called "{shortGemName}" </span>
    </div>
  );
};

// someone liked your game
const LikedGameToast = ({ activity }: ToastProps) => {
  const { gemName } = activity;
  const namesStr = useSimplifyNames(activity);
  const shortGemName = useMemo(() => {
    return ellipsisize(gemName, 4, 50);
  }, [gemName]);
  return (
    <div className="description">
      <span className="white">{namesStr}</span>
      <span className="gray"> liked your game "{shortGemName}" </span>
    </div>
  );
};

// someone commented on your game
const CommentedGameToast = ({ activity }: ToastProps) => {
  const { gemName, extra } = activity;
  const namesStr = useSimplifyNames(activity);
  const message = extra?.message;
  const replied = Boolean(extra?.replyToId);
  return (
    <div className="description">
      <span className="white">{namesStr}</span>
      <span className="gray">
        {!message && ` commented on your game "${gemName}".`}
        {message && replied && ` replied to your comment: "${ellipsisize(message, 15, 80)}"`}
        {message && !replied && ` commented on your game "${gemName}": "${ellipsisize(message, 15, 80)}"`}
      </span>
    </div>
  );
};

const GemUnlockedWithHelpNotification = ({ activity }: ToastProps) => {
  const { participantName, gemName } = activity;
  const timestamp = useHumanizedAgo(activity.createdAt);
  useEffect(() => {
    controller.feed.loadAndGetItemById(activity.offChainGameId ?? activity.gameId);
  }, []);
  return (
    <div className="description">
      <div className="timestamp">
        <span className="gray">{timestamp}</span>
      </div>
      <div className="info">
        <span className="white">{participantName}</span>
        <span className="gray"> has unlocked "{gemName}" for you</span>
      </div>
    </div>
  );
};

const GemBoughtWithHelpNotification = ({ activity }: ToastProps) => {
  const { participantName, gemName } = activity;
  const timestamp = useHumanizedAgo(activity.createdAt);
  return (
    <div className="description">
      <div className="timestamp">
        <span className="gray">{timestamp}</span>
      </div>
      <div className="info">
        <span className="white">{participantName}</span>
        <span className="gray"> has bought "{gemName}" with your help</span>
      </div>
    </div>
  );
};

// Frontend only

// Update Available
const UpdateAvailableToast = ({}: ToastProps) => {
  return (
    <div className="description">
      <span className="white">Update available. Tap to apply new update!</span>
    </div>
  );
};

const components: Record<ActivityType & FEOnlyActivityType, ComponentType<ToastProps>> = {
  [ActivityType.Welcome]: WelcomeToast,
  [ActivityType.Buy]: TradeToast,
  [ActivityType.Sell]: TradeToast,
  [ActivityType.Visit]: VisitToast,
  [ActivityType.PriceWentUp]: PriceWentUpToast,
  [ActivityType.Follow]: FollowToast,
  [ActivityType.FollowBack]: FollowBackToast,
  [ActivityType.FollowingCreatedGem]: FollowingCreatedGemToast,
  [ActivityType.LikedGame]: LikedGameToast,
  [ActivityType.CommentedGame]: CommentedGameToast,
  [FEOnlyActivityType.UpdateAvailable]: UpdateAvailableToast,
  [ActivityType.GemUnlockedWithHelp]: GemUnlockedWithHelpNotification,
  [ActivityType.GemBoughtWithHelp]: GemBoughtWithHelpNotification,
};
